import useSWR, { mutate } from 'swr';
import { db } from '../db/db';
import { Order, OrderToDisplay } from '../types/order.types';
import {
    PerformanceRating,
    PerformanceReport,
    RatingKey,
    ReceivedItemUpdateDto,
    ReceivedLineItem,
    ReceivedOrder,
} from '../types/receivedOrder.types';
import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';
import {
    QueueItem,
    RequestType,
    useSyncStatus,
} from '../context/SyncStatusContext';
import { useMemo } from 'react';
import { CreateReceivalCommand } from '../apiClient/generated';
import { adjustOrderToDisplay } from '../components/utils/adjustOrderToDisplay';
import { deepClone } from '../utils/deepClone';

dataFlowEventHub.on('receivedOrderChanged', (orderId) =>
    mutate([SWRCacheKeys.receivedOrder, orderId], undefined, {
        revalidate: true,
    }),
);

const fetchDbData = (orderId: number) =>
    db.receivedOrders.where('orderId').equals(orderId).first();

export const useReceivedOrder = (orderId?: ReceivedOrder['orderId']) => {
    const {
        data,
        isValidating: isReceivedOrderValidating,
        mutate,
    } = useSWR(
        orderId ? [SWRCacheKeys.receivedOrder, orderId] : null,
        ([, orderId]) => fetchDbData(orderId),
        swrDefaultConfig,
    );

    /**
     * This method mutates our cached order, but doesn't trigger revalidation, thus our performence doesn't depened on dexie/indexedDb.
     */
    const optimisticUpdate = async (receivedOrderClone: ReceivedOrder) => {
        mutate(receivedOrderClone, { revalidate: false });
        await db.receivedOrders.put(receivedOrderClone);
    };

    const upsertReceivedOrder = async (update: ReceivedOrder) => {
        await optimisticUpdate(update);
    };

    const updatePerformanceReportRating = async (
        fieldName: RatingKey,
        rating: PerformanceRating,
    ) => {
        const performanceReport = deepClone({
            ...(data?.performanceReport ?? {}),
        });

        if (!performanceReport.ratings) {
            performanceReport.ratings = {};
        }

        if (performanceReport.ratings[fieldName]) {
            performanceReport.ratings[fieldName]!.rating = rating;
        } else {
            performanceReport.ratings[fieldName] = {
                rating,
            };
        }

        await updatePerformanceReport(performanceReport);
    };

    const updatePerformanceReportComment = async (
        fieldName: RatingKey,
        comment: string,
    ) => {
        const performanceReport = deepClone({
            ...(data?.performanceReport ?? {}),
        });

        if (!performanceReport.ratings) {
            performanceReport.ratings = {};
        }

        if (performanceReport.ratings[fieldName]) {
            performanceReport.ratings[fieldName]!.comment = comment;
            await updatePerformanceReport(performanceReport);
        }
    };

    const updatePerformanceReport = async (
        update: Partial<PerformanceReport>,
    ) => {
        if (!data) {
            return;
        }

        await upsertReceivedOrder({
            ...deepClone(data),
            performanceReport: {
                ...deepClone(data?.performanceReport ?? {}),
                ...deepClone(update),
            },
        });
    };

    const bulkUpsertReceivedLineItems = async (
        updates: ReceivedItemUpdateDto[],
    ) => {
        if (!data) {
            return;
        }

        const receivedOrderClone = deepClone(data);

        for (const update of deepClone(updates)) {
            const {
                itemNumber: itemNumberToUpdate,
                receivedLineItem: updatedReceivedLineItem,
            } = update;

            const currentReceivedLineItem: ReceivedLineItem = deepClone(
                data.receivedLineItems[itemNumberToUpdate],
            );

            receivedOrderClone.receivedLineItems[itemNumberToUpdate] = {
                isReceived:
                    updatedReceivedLineItem.isReceived ??
                    currentReceivedLineItem?.isReceived,
                itemNumber: itemNumberToUpdate,
                receivedQuantity:
                    updatedReceivedLineItem?.receivedQuantity ??
                    currentReceivedLineItem?.receivedQuantity,
                comment:
                    updatedReceivedLineItem?.comment ??
                    currentReceivedLineItem?.comment,
                isFromOrder:
                    updatedReceivedLineItem?.isFromOrder ??
                    currentReceivedLineItem?.isFromOrder,
            };
        }

        await optimisticUpdate(receivedOrderClone);
    };

    const updateReceivedItemQuantity = async (
        itemNumber: string,
        receivedQuantity: number,
    ) => {
        if (!data) {
            return;
        }
        const receivedOrderClone = deepClone(data);
        receivedOrderClone.receivedLineItems[itemNumber].receivedQuantity =
            receivedQuantity;
        receivedOrderClone.receivedLineItems[itemNumber].isReceived =
            receivedQuantity === 0
                ? false
                : receivedOrderClone.receivedLineItems[itemNumber].isReceived;
        await optimisticUpdate(receivedOrderClone);
    };

    const toggleReceivedItemIsReceived = async (itemNumber: string) => {
        if (!data) {
            return;
        }
        const receivedOrderClone = deepClone(data);
        receivedOrderClone.receivedLineItems[itemNumber].isReceived =
            !receivedOrderClone.receivedLineItems[itemNumber].isReceived;
        await optimisticUpdate(receivedOrderClone);
    };

    const updateReceivedItemComment = async (
        itemNumber: string,
        comment: string,
    ) => {
        if (!data) {
            return;
        }
        const receivedOrderClone = deepClone(data);
        receivedOrderClone.receivedLineItems[itemNumber].comment = comment;
        await optimisticUpdate(receivedOrderClone);
    };

    const updateOrderDeliveryDate = async (date: Date) => {
        if (!data) {
            return;
        }

        const receivedOrderCopy = {
            ...deepClone(data),
            rfqUpdates: {
                deliveryDate: date,
            },
        };

        await optimisticUpdate(receivedOrderCopy);
    };

    const { queueStatus } = useSyncStatus();
    const isReceivalQueued = useMemo<boolean>(() => {
        const isOrderReceivalQueued = (item: QueueItem) =>
            item.requestType === RequestType.receival &&
            (item.request as CreateReceivalCommand).receival.orderId ===
                orderId;

        const offlineReceival = queueStatus.offlineQueue.find(
            isOrderReceivalQueued,
        );
        const onlineReceival = queueStatus.onlineQueue.find(
            isOrderReceivalQueued,
        );
        const receivalInProgress = queueStatus.requestInProgress
            ? isOrderReceivalQueued(queueStatus.requestInProgress) !== undefined
            : false;

        return (
            Boolean(offlineReceival) ||
            Boolean(onlineReceival) ||
            receivalInProgress
        );
    }, [queueStatus, orderId]);

    return {
        data,
        isReceivedOrderValidating,
        isReceivalQueued,
        bulkUpsertReceivedLineItems,
        upsertReceivedOrder,
        updateOrderDeliveryDate,
        updatePerformanceReportRating,
        updatePerformanceReportComment,
        updatePerformanceReport,
        updateReceivedItemQuantity,
        toggleReceivedItemIsReceived,
        updateReceivedItemComment,
    };
};

export const deleteReceivedOrder = async (orderId: number) => {
    await db.receivedOrders.delete(orderId);
    dataFlowEventHub.emit('receivedOrderChanged', orderId);
    dataFlowEventHub.emit('receivedOrdersChanged');
};

export const createReceivedOrderFromApiOrder = async (order: Order) => {
    // Create a pre-filled receival from any information that is on the order
    const orderToDisplay = adjustOrderToDisplay(order);

    const receivedLineItems =
        getLineItemsFromOrderToPutToReceived(orderToDisplay);

    for (const item of order.receival?.items ?? []) {
        receivedLineItems[item.itemNumber] = {
            ...item,
            isFromOrder: false,
            isReceived: true,
            receivedQuantity: item.quantity,
        };
    }

    await db.receivedOrders.put({
        orderId: order.orderId,
        receivedLineItems,
        rfqUpdates: { deliveryDate: orderToDisplay.rfq.deliveryDate },
    });

    dataFlowEventHub.emit('receivedOrderChanged', order.orderId);
    dataFlowEventHub.emit('receivedOrdersChanged');
};

export const getLineItemsFromOrderToPutToReceived = (
    orderToDisplay: OrderToDisplay,
): ReceivedOrder['receivedLineItems'] => {
    const receivedLineItems: ReceivedOrder['receivedLineItems'] = {};

    for (const lineItem of orderToDisplay.rfq.lineItems) {
        receivedLineItems[lineItem.itemNumber] = {
            itemNumber: lineItem.itemNumber,
            isReceived: false,
            receivedQuantity: lineItem.quantity,
            comment: lineItem.comment,
            isFromOrder: true,
        };
    }

    return receivedLineItems;
};
