import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { ActionToConfirm } from '../AppSettingsModal/AppSettingsModal';
import ConfirmationStep from '../AppSettingsModal/ConfirmationStep';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import styles from './UnhandledErrorModal.module.css';

const ContactKeyValue = ({
    title,
    value,
}: {
    title: string;
    value: string | React.ReactElement;
}) => {
    return (
        <div className={styles.contactRow}>
            <p className={styles.contactKey}>{title}</p>
            <p className={styles.contactValue}>{value}</p>
        </div>
    );
};

interface Props {
    isOpen?: boolean;
    clearingCacheAvailable?: boolean;
    refreshingAvailable?: boolean;
    onClose?: () => void;
}

const UnhandledErrorModal: React.FC<Props> = ({
    clearingCacheAvailable = true,
    isOpen = true,
    refreshingAvailable = true,
    onClose,
}) => {
    const {
        data: {
            seaStarContact,
            seaStarEmail,
            seaStarOfficeHours,
            seaStarPhone,
        },
    } = useVesselMetadata();
    const { reload } = useRouter();
    const isOnline = window.navigator.onLine;
    const [confirmationStep, setConfirmationStep] =
        useState<ActionToConfirm | null>(null);

    if (confirmationStep) {
        return (
            <ConfirmationStep
                confirmationStep={confirmationStep}
                setConfirmationStep={setConfirmationStep}
                outsideApp
            />
        );
    }

    return (
        <Modal isOpen={isOpen} noHeader>
            <ModalHeader title="Error" subTitle="Something went wrong" />
            <div className={`${modalStyles.modalContent} ${styles.container}`}>
                <p className={styles.description}>
                    Please try again or call your Garrets contact:
                </p>
                <div className={styles.contactContainer}>
                    <ContactKeyValue
                        title="Name"
                        value={seaStarContact ?? ''}
                    />
                    <ContactKeyValue title="Phone" value={seaStarPhone ?? ''} />
                    <ContactKeyValue title="Email" value={seaStarEmail ?? ''} />
                    <ContactKeyValue
                        title="Office hours"
                        value={seaStarOfficeHours ?? ''}
                    />
                </div>
                {clearingCacheAvailable && (
                    <>
                        <p className={styles.clearCacheDescription}>
                            If this error persists, you can try to clear
                            application cookies:
                        </p>
                        <div className={styles.clearCacheAction}>
                            {isOnline ? (
                                <>
                                    <Button
                                        text="Clear Cookies"
                                        onClick={() =>
                                            setConfirmationStep(
                                                ActionToConfirm.clearCookies,
                                            )
                                        }
                                        smallRounded
                                    />
                                    <p className={styles.clearCacheDescription}>
                                        If issues still exist try to clear
                                        cache:
                                    </p>
                                    <Button
                                        text="Clear Cache"
                                        secondary
                                        onClick={() =>
                                            setConfirmationStep(
                                                ActionToConfirm.clearCache,
                                            )
                                        }
                                        smallRounded
                                    />
                                </>
                            ) : (
                                <span>
                                    You are offline. Reconnect to clear cache or
                                    cookies.
                                </span>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    {refreshingAvailable && (
                        <Button text="Refresh App" onClick={reload} primary />
                    )}
                    {onClose && (
                        <Button text="Close" onClick={onClose} primary />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default UnhandledErrorModal;
