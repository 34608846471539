import { useEditableOrders } from '../../hooks/useEditableOrders';
import { useManningReports } from '../../hooks/useManningReports';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import { useStocktakingReports } from '../../hooks/useStocktakingReports';
import { ItemStatus } from '../../types/itemStatuses.types';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import SettingRow from './SettingRow';
import styles from './AppSettingsModal.module.css';

export const CacheContentsSummary: React.FC<{}> = () => {
    const { data: orderDrafts, isLoading: isEditableOrdersLoading } =
        useEditableOrders();
    const { manningReports, isLoading: isManningReportsLoading } =
        useManningReports();
    const manningReportDrafts = (manningReports ?? []).filter(
        (report) => report.state === ItemStatus.draft,
    );
    const { stocktakingReports, isLoading: isStocktakingReportsLoading } =
        useStocktakingReports();
    const stocktakingReportDrafts = (stocktakingReports ?? []).filter(
        (report) => report.state === ItemStatus.draft,
    );
    const {
        pendingCashPurchaseOrder,
        isLoading: isPendingCashPurchaseOrderLoading,
    } = usePendingCashPurchaseOrder();

    const isLoading =
        isEditableOrdersLoading ||
        isManningReportsLoading ||
        isStocktakingReportsLoading ||
        isPendingCashPurchaseOrderLoading;

    const hasDrafts = Boolean(
        orderDrafts?.length ||
            manningReportDrafts?.length ||
            stocktakingReportDrafts?.length ||
            pendingCashPurchaseOrder,
    );

    return isLoading ? (
        <div>
            <LoadingSpinner size={28} delayed />
        </div>
    ) : hasDrafts ? (
        <>
            <p>
                Any drafts on your device will be deleted (see below). Your
                order history, submitted reports, etc. will still be available
                in your account.
            </p>
            <div className={styles.section}>
                <p className={styles.sectionTitle}>Data to be deleted</p>
                {Boolean(orderDrafts?.length) && (
                    <SettingRow
                        name="⚠️ Order drafts"
                        value={`${orderDrafts?.length}`}
                    />
                )}
                {Boolean(manningReportDrafts?.length) && (
                    <SettingRow
                        name="⚠️ Manning report drafts"
                        value={`${manningReportDrafts?.length}`}
                    />
                )}
                {Boolean(stocktakingReportDrafts?.length) && (
                    <SettingRow
                        name="⚠️ Stocktaking report drafts"
                        value={`${stocktakingReportDrafts?.length}`}
                    />
                )}
                {Boolean(pendingCashPurchaseOrder) && (
                    <SettingRow name="⚠️ Cash purchase order draft" value="1" />
                )}
            </div>
        </>
    ) : (
        <></>
    );
};
