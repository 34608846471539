import { OrderToDisplay, Product } from '../../types/order.types';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { RFQChangelog } from '../../hooks/useChangelog';
import ProductsList from '../ProductsList/ProductsList';
import { ProductRowChangelogStatus } from '../ProductsList/SingleProductRow';
import { isUnavailableProduct } from '../utils/getProductsForOrderSummaryScreen';

export const OrderSummaryProductsTab: React.FC<{
    changelog?: RFQChangelog;
    products: Product[];
    orderToDisplay: OrderToDisplay;
}> = ({ changelog, products, orderToDisplay }) => {
    const { isLocked } = parseOrderStatus(orderToDisplay);
    return (
        <ProductsList
            compact
            products={products}
            orderToDisplay={orderToDisplay}
            getChangelogStatus={(product) => {
                // @todo extract that as a util or such
                if (orderToDisplay.isSending || isLocked) {
                    return ProductRowChangelogStatus.locked;
                }

                const change = changelog?.changes?.find(
                    (change) => change.itemNumber === product.itemNumber,
                );

                if (change?.status === 'removed') {
                    return ProductRowChangelogStatus.removed;
                } else if (change?.status === 'updated') {
                    return ProductRowChangelogStatus.updated;
                } else if (isUnavailableProduct(product)) {
                    return ProductRowChangelogStatus.unavailable;
                }

                return ProductRowChangelogStatus.default;
            }}
        />
    );
};
