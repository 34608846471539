import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.css';
import styles from './ApiErrorModal.module.css';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import Link from 'next/link';
import { GarretsContact } from './GarretsContact';

export enum ItemType {
    order = 'order',
    claim = 'claim report',
    condemn = 'condemn reports',
}

interface Props {
    isOpen: boolean;
    itemType: ItemType;
    onClose: () => void;
    onConfirm: () => void;
    onLinkToReportsPage?: () => void;
}

const getItemTypeCopyText = (itemType: ItemType) => {
    switch (itemType) {
        case ItemType.claim:
            'Claims page';
            break;
        case ItemType.condemn:
            'Condemn page';
            break;
        case ItemType.order:
            'Orders page';
            break;
        default:
            return '';
    }
};

const ApiDowntimeErrorModal = ({
    isOpen,
    onClose,
    onConfirm,
    itemType,
    onLinkToReportsPage,
}: Props) => {
    const itemTypeCopyText = getItemTypeCopyText(itemType);
    return (
        <Modal isOpen={isOpen} onClose={onClose} noHeader>
            <ModalHeader
                title="Info"
                subTitle="It looks like you are currently offline"
            />
            <div className={`${modalStyles.modalContent} ${styles.container}`}>
                <p
                    className={`${styles.description} ${styles.descriptionCentered}`}
                >
                    {`There was a problem connecting to the server. Your ${itemType} is
                    stored in Gateway, and you can still review it on the `}
                    {itemType === ItemType.order ? (
                        <Link href="/order?tab=history">
                            <a className={styles.blueText}>
                                {itemTypeCopyText}
                            </a>
                        </Link>
                    ) : (
                        <span
                            onClick={onLinkToReportsPage}
                            className={styles.blueText}
                        >
                            {itemTypeCopyText}
                        </span>
                    )}
                    . Gateway will automatically retry sending it when a
                    connection to the server is re-established.
                </p>
                <GarretsContact customMessage="If you encounter any issues, please call your Garrets contact:" />
            </div>
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="OK" onClick={onConfirm} primary />
                </div>
            </div>
        </Modal>
    );
};

export default ApiDowntimeErrorModal;
