import styles from './StarRating.module.css';
import StarIcon from '../../public/gfx/uiIcons/starRating.svg';
import React, { useState } from 'react';
import { PerformanceRating, RatingKey } from '../../types/receivedOrder.types';
import { useReceivedOrder } from '../../hooks/useReceivedOrder';
import useOrderType from '../../hooks/useOrderType';

export enum Rating {
    veryBad = 'Very bad',
    bad = 'Bad',
    okay = 'Okay',
    good = 'Good',
    excellent = 'Excellent',
}

export interface Star {
    rateNumber: PerformanceRating;
    rateText?: Rating;
}

interface StarRatingProps {
    setSelected: React.Dispatch<
        React.SetStateAction<PerformanceRating | undefined>
    >;
    selected: PerformanceRating | undefined;
    smaller?: boolean;
    ratingKey: RatingKey;
    onContinue?: () => void;
    setAutomaticNextPage?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Create state in overriding component to have access to selected rate.
 * Ex. const [selectedRate, setSelectedRate] = useState<Star>()
 * <StarRating
 *  selected={selectedRate}
 *  setSelected={setSelectedRate} />
 * **/
const StarRating: React.FC<StarRatingProps> = ({
    setSelected,
    selected,
    smaller,
    ratingKey,
    onContinue,
    setAutomaticNextPage,
}) => {
    const { orderIdFromQuery } = useOrderType();
    const { updatePerformanceReportRating } = useReceivedOrder(
        Number(orderIdFromQuery),
    );

    const [hovered, setHovered] = useState<PerformanceRating>();
    const stars: Star[] = [
        { rateNumber: 1, rateText: Rating.veryBad },
        { rateNumber: 2, rateText: Rating.bad },
        { rateNumber: 3, rateText: Rating.okay },
        { rateNumber: 4, rateText: Rating.good },
        { rateNumber: 5, rateText: Rating.excellent },
    ];
    const renderImage = (isSelected: boolean, isHovered: boolean) => {
        const fill = `${isHovered || isSelected ? '#276ef1' : '#f4f5f6'}`;
        const width = `${!smaller ? '68px' : '39px'}`;
        const height = `${!smaller ? '64px' : '36px'}`;

        return <StarIcon fill={fill} width={width} height={height} />;
    };

    const handleSelect = async (selectedItem: PerformanceRating) => {
        setSelected(selectedItem);
        await updatePerformanceReportRating(ratingKey, selectedItem);
        if (selectedItem > 2) {
            setAutomaticNextPage ? setAutomaticNextPage(true) : '';
            if (onContinue) {
                setTimeout(() => {
                    onContinue();
                }, 500);
            }
        }
    };

    const handleHover = (selectedItem: PerformanceRating | undefined) => {
        setHovered(selectedItem);
    };

    return (
        <div className={styles.starsContainer}>
            {stars.map((star, key) => (
                <button
                    className={[
                        styles.starComponent,
                        smaller && styles.active,
                    ].join(' ')}
                    onClick={() => handleSelect(star.rateNumber)}
                    onMouseEnter={() => handleHover(star.rateNumber)}
                    onMouseLeave={() => handleHover(undefined)}
                    key={key}
                    aria-label={`Rate ${star.rateNumber} stars (${star.rateText})`}
                >
                    {renderImage(
                        star.rateNumber <= Number(selected),
                        star.rateNumber <= Number(hovered),
                    )}
                    <p
                        className={[
                            styles.text,
                            smaller && styles.smallerComponentText,
                            star.rateNumber === Number(hovered) &&
                                styles.blueText,
                            star.rateNumber === Number(selected) &&
                                styles.blueText,
                            !selected && !hovered && styles.darkGreyText,
                            star.rateNumber < Number(hovered) &&
                                styles.darkGreyText,
                            star.rateNumber === Number(hovered) &&
                            star.rateNumber < Number(selected)
                                ? styles.blueText
                                : '',
                            star.rateNumber < Number(selected) && !hovered
                                ? styles.darkGreyText
                                : '',
                        ].join(' ')}
                    >
                        {star.rateText}
                    </p>
                </button>
            ))}
        </div>
    );
};

export default StarRating;
