import Input from '../../Input/Input';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import PercentageIcon from '../../../public/gfx/uiIcons/percentage.svg';
import Decimal from 'decimal.js';

export const CashDiscountInput = ({
    setCashDiscountInputError,
    cashDiscountInputError,
    naked,
}: {
    setCashDiscountInputError: (isError: boolean) => void;
    cashDiscountInputError: boolean;
    naked?: boolean;
}) => {
    const { pendingCashPurchaseOrder, updatePendingCashPurchaseOrder } =
        usePendingCashPurchaseOrder();

    const handleChangeCashDiscountInput = async (
        updatedValue: string | undefined,
    ) => {
        if (typeof updatedValue === 'string' && updatedValue.length > 5) {
            return;
        }

        if (!pendingCashPurchaseOrder) {
            return;
        }

        if (!updatedValue) {
            await updatePendingCashPurchaseOrder({
                ...pendingCashPurchaseOrder,
                cashDiscount: undefined,
            });
            return;
        }

        const parsedValue = Number(
            updatedValue.replace(',', '.').replace('-', ''),
        );

        setCashDiscountInputError(parsedValue > 100);

        await updatePendingCashPurchaseOrder({
            ...pendingCashPurchaseOrder,
            cashDiscount: Number((parsedValue / 100).toFixed(4)),
        });
    };

    return (
        <Input
            label={naked ? undefined : 'Cash discount'}
            optionalLabel={naked ? undefined : ' (optional)'}
            value={
                pendingCashPurchaseOrder?.cashDiscount ||
                pendingCashPurchaseOrder?.cashDiscount === 0
                    ? new Decimal(
                          (pendingCashPurchaseOrder?.cashDiscount ?? 0) * 100,
                      )
                          .toDecimalPlaces(2)
                          .toString()
                    : undefined
            }
            onChange={(e) => handleChangeCashDiscountInput(e.target.value)}
            error={
                cashDiscountInputError
                    ? 'Discount value cannot be higher than 100%'
                    : undefined
            }
            icon={<PercentageIcon width="16" height="16" />}
            placeholder="Type number..."
            name="cashDiscount"
            strongLabel
            numberInput
        />
    );
};
