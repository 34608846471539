import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

interface Props {
    text: string;
    onClick?: (() => void) | ((e: MouseEvent) => void);
    smallRounded?: boolean;
    rounded?: boolean;
    light?: boolean;
    whiteCta?: boolean;
    blackCta?: boolean;
    redCta?: boolean;
    blueCta?: boolean;
    greyCta?: boolean;
    primary?: boolean;
    secondary?: boolean;
    black?: boolean;
    red?: boolean;
    textButton?: boolean;
    thin?: boolean;
    lightBlue?: boolean;
    noWrap?: boolean;
    capitalize?: boolean;
    icon?: React.ReactElement;
    leftIcon?: React.ReactElement;
    transparent?: boolean;
    transparentWhite?: boolean;
    transparentBlack?: boolean;
    smallText?: boolean;
    smaller?: boolean;
    smallPaddings?: boolean;
    circular?: boolean;
}

export const Button: React.FC<
    Props & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
    text,
    onClick,
    smallRounded,
    light,
    whiteCta,
    blackCta,
    blueCta,
    greyCta,
    primary,
    secondary,
    black,
    textButton,
    thin,
    lightBlue,
    noWrap,
    capitalize,
    redCta,
    icon,
    leftIcon,
    transparent,
    transparentWhite,
    transparentBlack,
    smallText,
    smaller,
    smallPaddings,
    circular,
    red,
    ...buttonProps
}) => (
    <button
        className={[
            styles.button,
            textButton ? styles.text : '',
            smallRounded ? styles.smallRounded : '',
            thin ? styles.thin : '',
            noWrap ? styles.noWrap : '',
            light ? styles.light : '',
            lightBlue ? styles.lightBlue : '',
            whiteCta ? styles.whiteCta : '',
            blackCta ? styles.blackCta : '',
            redCta ? styles.redCta : '',
            blueCta ? styles.blueCta : '',
            red ? styles.redButton : '',
            primary ? styles.primaryButton : '',
            secondary ? styles.secondaryButton : '',
            black ? styles.blackSquaredButton : '',
            greyCta ? styles.greyCta : '',
            icon || leftIcon ? styles.buttonWithIcon : '',
            transparent || transparentWhite || transparentBlack
                ? styles.transparent
                : '',
            transparentWhite ? styles.transparentWhite : '',
            transparentBlack ? styles.transparentBlack : '',
            smaller ? styles.smaller : '',
            smallText ? styles.smallText : '',
            capitalize ? styles.capitalize : '',
            smallPaddings ? styles.smallPaddings : '',
            circular ? styles.circular : '',
        ].join(' ')}
        onClick={onClick ? (e) => onClick(e) : undefined}
        {...buttonProps}
    >
        {leftIcon}
        {text}
        {icon}
    </button>
);

export default Button;
