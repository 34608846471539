import styles from './ModalHeader.module.css';
import { ReactElement } from 'react';

type stringOrElement = string | ReactElement;

interface HeaderProps {
    title?: stringOrElement;
    subTitle: stringOrElement;
    description?: stringOrElement;
}

export const ModalHeader: React.FC<HeaderProps> = ({
    title = '',
    subTitle,
    description,
}) => {
    return (
        <div className={styles.container}>
            {typeof title === 'string' ? (
                <p className={styles.title}>{title}</p>
            ) : (
                <div className={styles.title}>{title}</div>
            )}
            <h3 className={styles.subTitle}>{subTitle}</h3>
            {typeof description === 'string' ? (
                <p className={styles.description}>{description}</p>
            ) : (
                <div className={styles.description}>{description}</div>
            )}
        </div>
    );
};
