import { AssortmentDto } from '../apiClient/generated';

export enum OrderTypes {
    provision = 'Provision',
    bonded = 'Bonded',
    nonFood = 'Nonfood',
    cash = 'Cash',
}

const orderTypesArray = Object.values(OrderTypes);

const orderTypeNameMap: Record<OrderTypes, string> = {
    [OrderTypes.provision]: 'Provision',
    [OrderTypes.bonded]: 'Bonded',
    [OrderTypes.nonFood]: 'Non-food',
    [OrderTypes.cash]: 'Cash',
};

export const getOrderType = (type: string) => {
    const typeLowercase = type.toLocaleLowerCase();
    const orderType = orderTypesArray.find(
        (item) => item.toLocaleLowerCase() === typeLowercase,
    );
    if (!orderType) {
        throw new Error(`Unsupported order type ${type}`);
    }

    return orderType;
};

export const getAssortment = (assortments: AssortmentDto, type: OrderTypes) => {
    switch (type) {
        case OrderTypes.provision:
            return assortments.provision;
        case OrderTypes.bonded:
            return assortments.bonded;
        case OrderTypes.nonFood:
            return assortments.nonFood;
        default:
            throw new Error(`Unsupported order type ${type}`);
    }
};

export const getOrderTypeName = (type: OrderTypes): string => {
    const name = orderTypeNameMap[type];
    if (!name) {
        throw new Error(`Unsupported order type ${type}`);
    }

    return name;
};

export const doesOrderTypeHaveBudget = (type: OrderTypes): boolean => {
    return type === OrderTypes.provision;
};