import styles from './OptionButton.module.css';

interface Props {
    onClick: () => void;
    isSelected?: boolean;
}

export const OptionButton = ({
    children,
    onClick,
    isSelected,
}: React.PropsWithChildren<Props>) => {
    return (
        <button
            className={[
                styles.container,
                isSelected ? styles.selected : '',
            ].join(' ')}
            type="button"
            onClick={() => {
                onClick();
            }}
        >
            {children}
        </button>
    );
};