import { useRouter } from 'next/router';
import {
    AnnouncementTypes,
    useFeatureAnnouncements,
} from '../../hooks/useFeatureAnnouncements';
import { MultipleOrdersIntroModal } from '../IntroModals/MultipleOrdersIntroModal';

export const FeatureAnnouncements = () => {
    const {
        data: announcements,
        isValidating,
        upsertOne,
    } = useFeatureAnnouncements();
    const { push } = useRouter();

    const dismissed = (announcementId: AnnouncementTypes) =>
        announcements?.some(
            (announcement) =>
                announcement.id === announcementId && announcement.dismissed,
        );

    if (isValidating && !announcements?.length) {
        return null;
    }

    return (
        <MultipleOrdersIntroModal
            isOpen={!dismissed(AnnouncementTypes.multipleOrdersV1)}
            onClick={async () => {
                await upsertOne({
                    id: AnnouncementTypes.multipleOrdersV1,
                    dismissed: true,
                });
                push('/order');
            }}
            onClose={async () => {
                await upsertOne({
                    id: AnnouncementTypes.multipleOrdersV1,
                    dismissed: true,
                });
            }}
        />
    );
};
