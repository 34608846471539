import {
    OrderToDisplay,
    OrderToDisplayInOrdersList,
} from '../../types/order.types';
import React from 'react';
import { OrderStatus } from '../../apiClient/generated';
import styles from './OrdersOverview.module.css';
import Provision from '../../public/gfx/menuIcons/provisionBold.svg';
import Nonfood from '../../public/gfx/menuIcons/nonfoodBold.svg';
import Bonded from '../../public/gfx/menuIcons/bondedBold.svg';
import Draft from '../../public/gfx/menuIcons/draftIcon.svg';
import CashPurchase from '../../public/gfx/menuIcons/cashPurchaseIcon.svg';
import { OrderTypes } from '../../context/OrderTypes';

interface Props {
    orderToDisplay: OrderToDisplay | OrderToDisplayInOrdersList;
}

export const OrderIcon: React.FC<Props> = ({ orderToDisplay }) => {
    const isOffline = orderToDisplay.isSending;

    const stroke = `${
        orderToDisplay.status === OrderStatus.OrderForReview && !isOffline
            ? '#FF902A'
            : '#7a828d'
    }`;

    const iconAttributes = `${styles.icon} ${
        orderToDisplay.status === OrderStatus.OrderForReview && !isOffline
            ? styles.iconOrange
            : ''
    }`;

    const getIconToDisplay = () => {
        if (
            orderToDisplay.draftName &&
            orderToDisplay.status === OrderStatus.Blank &&
            !orderToDisplay.isSending
        ) {
            return <Draft width="20" height="20" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.nonFood) {
            return <Nonfood width="17" height="17" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.bonded) {
            return <Bonded width="15" height="18" stroke={stroke} />;
        } else if (orderToDisplay?.type === OrderTypes.provision) {
            return <Provision width="17" height="19" stroke={stroke} />;
        } else if (orderToDisplay.type === OrderTypes.cash) {
            return <CashPurchase width="17" height="18" stroke={stroke} />;
        }
    };

    return <div className={iconAttributes}>{getIconToDisplay()}</div>;
};
