import { ModalHeader } from '../../ModalHeader/ModalHeader';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import modalStyles from '../../Modal/Modal.module.css';
import Button from '../../Button/Button';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import { useRouter } from 'next/router';
import styles from './Receival.module.css';
import CountryFlag from '../../Flags/CountryFlag';
import {
    portCodeToCountryDisplayName,
    portCountryCodeFromFullCode,
} from '../../../db/utils/ports';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { getOrderTotalFromCompletedOrder } from '../../utils/orderSummary';
import { useProducts } from '../../../hooks/useProducts';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { apiClient } from '../../../apiClient/apiClient';
import { OrderToDisplay, Product } from '../../../types/order.types';
import StepsProgressBar from '../../StepsProgressBar/StepsProgressBar';
import { OrderTypes } from '../../../context/OrderTypes';
import { useFeatureFlags } from '../../../context/useFeatureFlags';
import { ToastType, useToast } from '../../../context/ToastContext';
import { RatingsSummary } from './RatingsSummary/RatingsSummary';
import { SupplierExperienceSummary } from './SupplierExperienceSummary/SupplierExperienceSummary';
import useOrderType from '../../../hooks/useOrderType';
import {
    getReceivedProductsTotalValue,
    getReceivedProductsWithoutPricesQuantity,
    mapReceivalToRequest,
} from './utils';
import { dataFlowEventHub } from '../../../events/dataFlowEvents';
import { getOrderPath } from '../../utils/orderPathsGenerator';
import { useVesselMetadata } from '../../../hooks/useVesselMetadata';
import {
    ClaimPhotosSubmissionResult,
    ClaimSubmissionResult,
    submitClaimDataPart,
    submitClaimPhotosPart,
} from '../../ClaimOrCondemnPreparationModal/utils/submitClaim';
import { useClaimsInReceival } from '../../../hooks/useClaimsInReceival';
import { IExtendedItemIssueDto } from '../../../db/overrideTypes';
import { IncomingItemIssueDto } from '../../../apiClient/generated';
import { db } from '../../../db/db';
import ApiDowntimeErrorModal, {
    ItemType,
} from '../../ErrorComponents/ApiDowntimeErrorModal';
import { useClaimsInReceivalFlow } from '../../../context/ClaimsInReceivalFlowContext';

interface Props {
    orderToDisplay: OrderToDisplay;
    onBack: () => void;
    steps: number;
    isPerformanceReportPartOfTheFlow: boolean;
}

export const ReceivalSummary: React.FC<Props> = ({
    orderToDisplay,
    onBack,
    steps,
    isPerformanceReportPartOfTheFlow,
}) => {
    const { showToast } = useToast();
    const { push } = useRouter();
    const { featureFlags } = useFeatureFlags();
    const { activeOrderType } = useOrderType();
    const { data: receivedOrder } = useReceivedOrder(orderToDisplay.orderId);
    const [processing, setProcessing] = useState(false);
    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const orderTotal = useMemo(
        () =>
            orderToDisplay.rfq
                ? getOrderTotalFromCompletedOrder(
                      orderToDisplay.rfq,
                      vesselCurrency,
                  )
                : 0,
        [orderToDisplay],
    );

    const { data: allProducts } = useProducts();
    const productsByItemNumber = useMemo(() => {
        const productsList: Record<string, Product> = {};
        for (const product of allProducts) {
            productsList[product.itemNumber] = product;
        }

        return productsList;
    }, [allProducts]);

    const { claimsInReceival } = useClaimsInReceival(
        Number(orderToDisplay.orderId),
    );

    const { closeClaimsInReceivalModal } = useClaimsInReceivalFlow();

    const sendClaimsInReceival = async () => {
        if (!claimsInReceival) {
            return;
        }

        const mapIExtendedItemIssueDtoToIncomingItemIssueDto = (
            claimInReceival: IExtendedItemIssueDto,
        ): IncomingItemIssueDto | undefined => {
            const {
                productItemNumber,
                clientId,
                itemIssueProblem,
                itemIssueType,
                affectedAmount,
                numberOfPhotos,
                localId,
            } = claimInReceival;

            if (
                !productItemNumber ||
                clientId === undefined ||
                itemIssueProblem === undefined ||
                itemIssueType === undefined ||
                numberOfPhotos === undefined ||
                localId === undefined ||
                affectedAmount === undefined
            ) {
                return;
            }

            return {
                ...claimInReceival,
                productItemNumber,
                clientId,
                itemIssueProblem, // todo: there is a problem with itemIssueProblem mapping it is enum or string depends on the issue
                itemIssueType,
                affectedAmount,
                numberOfPhotos,
                localId,
            };
        };

        const removeClaimsInReceivalFromDb = async (
            claimInReceival: IExtendedItemIssueDto,
        ) => {
            await db.claimsInReceival
                .where({
                    localId: claimInReceival.localId,
                })
                .delete();
        };

        const putOfflineClaimInDb = async (
            claimInReceival: IExtendedItemIssueDto,
        ) => {
            await db.claims.put({
                ...claimInReceival,
                isOffline: true,
                itemName: claimInReceival.productItemNumber
                    ? productsByItemNumber[claimInReceival.productItemNumber]
                          .itemName
                    : 'unknown',
            });
        };

        const results = await Promise.all(
            claimsInReceival.map(async (claimInReceival) => {
                const parsedClaim =
                    mapIExtendedItemIssueDtoToIncomingItemIssueDto(
                        claimInReceival,
                    );

                if (!parsedClaim || claimInReceival.clientId === undefined) {
                    return;
                }

                const result = await submitClaimDataPart(parsedClaim);

                if (result === ClaimSubmissionResult.success) {
                    let claimPhotosSubmissionResult:
                        | ClaimPhotosSubmissionResult
                        | undefined = undefined;

                    if (claimInReceival.localPhotos) {
                        claimPhotosSubmissionResult =
                            await submitClaimPhotosPart(
                                claimInReceival.clientId,
                                claimInReceival.localId,
                                claimInReceival.localPhotos,
                            );
                    }

                    if (
                        claimPhotosSubmissionResult ===
                        ClaimPhotosSubmissionResult.success
                    ) {
                        removeClaimsInReceivalFromDb(claimInReceival);
                    }
                    return ClaimSubmissionResult.success;
                } else if (result === ClaimSubmissionResult.apiOffline) {
                    await putOfflineClaimInDb(claimInReceival);
                    await submitClaimPhotosPart(
                        claimInReceival.clientId,
                        claimInReceival.localId,
                        claimInReceival.localPhotos,
                    );
                    removeClaimsInReceivalFromDb(claimInReceival);
                    return ClaimSubmissionResult.apiOffline;
                } else {
                    removeClaimsInReceivalFromDb(claimInReceival);
                    return ClaimSubmissionResult.requestFailed;
                }
            }),
        );

        return results;
    };

    const [isClaimOfflineModalOpen, setIsClaimOfflineModalOpen] =
        useState(false);

    const sendReceival = async () => {
        if (!receivedOrder) {
            return;
        }

        setProcessing(true);

        try {
            try {
                await sendClaimsInReceival();
            } catch (err) {
                console.error(err);
            }
            await apiClient.createReceival(mapReceivalToRequest(receivedOrder));

            dataFlowEventHub.emit('receivalSubmitted');
            setProcessing(false);
            showToast({
                type: ToastType.success,
                text: 'Receival completed',
            });

            push(getOrderPath(orderToDisplay));
        } catch (error) {
            closeClaimsInReceivalModal();
            setIsClaimOfflineModalOpen(true);
        }
    };

    const step =
        activeOrderType === OrderTypes.provision &&
        Boolean(featureFlags?.performanceReport)
            ? 10
            : 3;

    if (isClaimOfflineModalOpen) {
        return (
            <ApiDowntimeErrorModal
                isOpen={isClaimOfflineModalOpen}
                itemType={ItemType.order}
                onLinkToReportsPage={() => {
                    setIsClaimOfflineModalOpen(false);
                    closeClaimsInReceivalModal();
                    push('/order?tab=history');
                }}
                onClose={() => {
                    setIsClaimOfflineModalOpen(false);
                    closeClaimsInReceivalModal();
                    push('/order?tab=history');
                }}
                onConfirm={() => {
                    setIsClaimOfflineModalOpen(false);
                    closeClaimsInReceivalModal();
                    push('/order?tab=history');
                }}
            />
        );
    }

    return (
        <>
            <ModalHeader
                subTitle="Summary"
                title={
                    <div className={styles.stepsProgressBar}>
                        <StepsProgressBar
                            currentStep={step}
                            totalStepsLength={steps}
                            parentRemounts
                        />
                    </div>
                }
                description="We're happy to hear that you have received your order. If you encountered an issue, our team will take a look and be in touch soon."
            />
            <DetailsDataTable>
                <DetailsDataRow
                    title="Order details"
                    uppercaseTitle
                    boldTitle
                    withoutSeparator
                />
                <DetailsDataRow
                    title="Delivery port"
                    value={
                        <p className={styles.detailsText}>
                            <span className={styles.cell}>
                                <CountryFlag
                                    portCode={
                                        orderToDisplay.rfq.deliveryPort
                                            ?.portCode
                                    }
                                />
                                &nbsp;
                                {orderToDisplay.rfq.deliveryPort?.portName}
                                {', '}
                                {orderToDisplay.rfq.deliveryPort?.portCode
                                    ? portCodeToCountryDisplayName(
                                          portCountryCodeFromFullCode(
                                              orderToDisplay.rfq.deliveryPort
                                                  ?.portCode,
                                          ),
                                      )
                                    : ''}
                            </span>
                        </p>
                    }
                />
                <DetailsDataRow
                    title="Date delivered"
                    boldValue
                    value={moment(
                        receivedOrder?.rfqUpdates.deliveryDate,
                    ).format('DD.MM.YYYY')}
                />
                <DetailsDataRow
                    title="Supplier"
                    boldValue
                    value={orderToDisplay?.rfq?.supplier?.name ?? 'unknown'}
                />
                <DetailsDataRow
                    title="Products ordered"
                    boldValue
                    value={orderToDisplay?.rfq?.lineItems.length}
                />
                <DetailsDataRow
                    title="Order total"
                    boldValue
                    value={orderTotal}
                />
                <DetailsDataRow
                    title="Received total"
                    boldValue
                    value={getReceivedProductsTotalValue(
                        receivedOrder?.receivedLineItems,
                        orderToDisplay.rfq.lineItems,
                        vesselCurrency,
                    )}
                />
                <DetailsDataRow
                    title="Received items without a price (excl. from received total)"
                    boldValue
                    value={getReceivedProductsWithoutPricesQuantity(
                        receivedOrder?.receivedLineItems,
                        orderToDisplay.rfq.lineItems,
                    )}
                />
            </DetailsDataTable>
            {Boolean(claimsInReceival?.length) && (
                <DetailsDataTable>
                    <DetailsDataRow
                        title="Claimed items"
                        uppercaseTitle
                        boldTitle
                        withoutSeparator
                        lessSpacing
                    />
                    <DetailsDataRow
                        title="These items have been added to a claim"
                        value="Qty affected"
                        lessSpacing
                    />
                    {claimsInReceival?.map((claim) => {
                        const product =
                            productsByItemNumber[claim.productItemNumber ?? ''];
                        return (
                            <DetailsDataRow
                                key={product.itemNumber}
                                title={product?.itemName}
                                value={
                                    <div className={styles.redText}>
                                        {claim.affectedAmount}{' '}
                                        {product.unitOfMeasure}
                                    </div>
                                }
                            />
                        );
                    })}
                </DetailsDataTable>
            )}
            {Boolean(receivedOrder?.remarks?.length) && (
                <DetailsDataTable lessSpacing>
                    <DetailsDataRow
                        title="Comment"
                        uppercaseTitle
                        boldTitle
                        withoutSeparator
                    />
                    <DetailsDataRow
                        value={
                            <em className={styles.remarks}>
                                {receivedOrder?.remarks}
                            </em>
                        }
                    />
                </DetailsDataTable>
            )}
            {isPerformanceReportPartOfTheFlow && (
                <>
                    <RatingsSummary orderToDisplay={orderToDisplay} />
                    <SupplierExperienceSummary
                        orderToDisplay={orderToDisplay}
                    />
                </>
            )}

            <div className={styles.bottomSpacer}></div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Back"
                        onClick={onBack}
                        secondary
                        disabled={processing}
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text={processing ? 'Sending...' : 'Confirm'}
                        onClick={sendReceival}
                        primary
                        disabled={processing}
                    />
                </div>
            </div>
        </>
    );
};
