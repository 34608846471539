// This enum holds statuses from our different entities
export enum DisplayedStatus {
    notSent = 'NOT SENT',
    sentToGarrets = 'SENT TO GARRETS',
    completed = 'COMPLETED',
    awaitingAction = 'AWAITING ACTION',
    unknownStatus = 'UNKNOWN STATUS',
    orderForConfirmation = 'ORDER CONFIRMED', // "it means, that Garrets and the supplier has confirmed the order will be delivered" "So with a status 5, the vessel user is able to start receival (and the order will change value to 6 on the web app)"
    receival = 'RECEIVING',
    unhandledStatus = 'UNHANDLED STATUS',
    cancelledByVessel = 'CANCELLED BY VESSEL',
    cancelledByGarrets = 'CANCELLED',
    sendingOffline = 'OFFLINE SENDING',
    sendingOnline = 'SENDING',
    draft = 'DRAFT',
    submitted = 'SUBMITTED',
    pending = 'PENDING',
    updatedByGarrets = 'COMPLETED (updated by Garrets)',
}
