import React, { useMemo } from 'react';
import styles from './ProductsList.module.css';
import ProductCategoryRow from './ProductCategoryRow';
import { TableHead, TableHeadReceived } from './TableHead';
import { OrderToDisplay, Product } from '../../types/order.types';
import ProductDetailsModal from './ProductDetailsModal';
import FiltersModal from '../FiltersModal/FiltersModal';
import { ProductRowChangelogStatus } from './SingleProductRow';
import { getProductsSortedByCategories } from './utils/productsListParser';
import useFilters from '../../hooks/useFilters';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { Portal } from '@radix-ui/react-portal';

export interface ProductCategory<T> {
    categoryName: string;
    categoryNumber: number;
    products: T[];
}

interface Props {
    orderToDisplay: OrderToDisplay;
    products: Product[];
    compact?: boolean;
    getChangelogStatus?: (product: Product) => ProductRowChangelogStatus;
    withIcons?: boolean;
    expandAllCategories?: boolean;
}

const ProductsList: React.FC<Props> = ({
    compact,
    products,
    getChangelogStatus,
    withIcons,
    orderToDisplay,
    expandAllCategories,
}) => {
    const {
        filters,
        filtersModalIsOpen,
        handleCloseFiltersModal,
        applyFilters,
        handleOpenFiltersModal,
        filterFn,
    } = useFilters();

    const filteredProducts = useMemo(
        () => products.filter(filterFn),
        [products, filterFn],
    );

    const productsSortedByCategories = useMemo(
        () => getProductsSortedByCategories(filteredProducts),
        [filteredProducts],
    );

    const { isReceived } = parseOrderStatus(orderToDisplay);
    const tableHead = isReceived ? (
        <TableHeadReceived
            handleOpenFiltersModal={handleOpenFiltersModal}
            filters={filters}
            compact={compact}
        />
    ) : (
        <TableHead
            handleOpenFiltersModal={handleOpenFiltersModal}
            filters={filters}
            compact={compact}
        />
    );

    return (
        <>
            <ProductDetailsModal />
            <Portal>
                <FiltersModal
                    isOpen={filtersModalIsOpen}
                    onClose={handleCloseFiltersModal}
                    applyFilters={applyFilters}
                    appliedFilters={filters}
                    products={products}
                />
            </Portal>
            <div className={styles.container}>
                {tableHead}
                {productsSortedByCategories.map(
                    (category: ProductCategory<Product>, index) => (
                        <ProductCategoryRow
                            key={category.categoryName}
                            orderToDisplay={orderToDisplay}
                            withIcon={withIcons}
                            category={category}
                            compact={compact}
                            getChangelogStatus={getChangelogStatus}
                            withLegend={index === 0}
                            expandAllCategories={expandAllCategories}
                        />
                    ),
                )}
                <div className={styles.spacer} />
            </div>
        </>
    );
};

export default ProductsList;
