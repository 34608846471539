import styles from './OrdersOverviewTabs.module.css';
import { OrdersOverviewList } from '../OrdersOverviewList';
import React, { useEffect } from 'react';
import { useOrders } from '../../../hooks/useOrders';
import { OrderStatus } from '../../../apiClient/generated';
import { EmptyState } from '../../ListEmptyState/EmptyState';
import { OrderToDisplayInOrdersList } from '../../../types/order.types';
import EmptyStateTickIcon from '../../../public/gfx/emptyStateTick.svg';
import Button from '../../Button/Button';
import { useEditableOrders } from '../../../hooks/useEditableOrders';
import { CreateNewOrderButtons } from '../CreateNewOrderButton/CreateNewOrderButtons';
import { useCashPurchaseOrders } from '../../../hooks/useCashPurchaseOrders';
import { adjustOrderToDisplayInOrdersList } from '../../utils/adjustOrderToDisplayInOrdersList';
import { adjustEditableOrderToDisplayInOrdersList } from '../../utils/adjustEditableOrderToDisplayInOrdersList';
import { adjustCashPurchaseOrderToDisplayInOrdersList } from '../../utils/adjustCashPurchaseOrderToDisplayInOrdersList';
import { getOrderById, sortOrdersToDisplayInListByDate } from '../utils';
import { useFeatureFlags } from '../../../context/useFeatureFlags';
import { useSendingCashPurchaseOrders } from '../../../hooks/useSendingCashPurchaseOrders';
import { adjustSendingCashPurchaseOrderToDisplayInOrderList } from '../../utils/adjustSendingCashPurchaseOrderToDisplayInOrderList';
import { adjustAwaitingActionOrderToDisplayInOrdersList } from '../../../db/editableOrders';

export enum OverviewTabType {
    overview = 'overview',
    drafts = 'drafts',
    history = 'history',
}

interface Props {
    tabType: OverviewTabType;
    setActiveTab?: React.Dispatch<string>;
}

export const OverviewTab: React.FC<Props> = ({ setActiveTab, tabType }) => {
    const { data: orders, isValidatingOrders } = useOrders();
    const { data: editableOrders, isEditableOrderValidating } =
        useEditableOrders();
    const { sendingCashPurchaseOrders, revalidateSendingCashPurchaseOrders } =
        useSendingCashPurchaseOrders();
    const { cashPurchaseOrders } = useCashPurchaseOrders();
    const { featureFlags } = useFeatureFlags();

    const ordersToDisplay = orders?.map(adjustOrderToDisplayInOrdersList) ?? [];
    const editableOrdersToDisplay =
        editableOrders?.map(adjustEditableOrderToDisplayInOrdersList) ?? [];
    const cashPurchaseOrdersToDisplay = featureFlags?.cashPurchase
        ? cashPurchaseOrders?.map(
              adjustCashPurchaseOrderToDisplayInOrdersList,
          ) ?? []
        : [];
    const sendingCashPurchaseOrdersToDisplay = featureFlags?.cashPurchase
        ? sendingCashPurchaseOrders?.map(
              adjustSendingCashPurchaseOrderToDisplayInOrderList,
          ) ?? []
        : [];

    const awaitingActionOrders =
        orders?.filter((order) => {
            return order.status === OrderStatus.OrderForReview;
        }) ?? [];

    const editableVersionsOfAwaitingActionOrders = editableOrders?.filter(
        (order) =>
            !order.isSending && order.status === OrderStatus.OrderForReview,
    );

    const awaitingActionOrdersToDisplay =
        editableVersionsOfAwaitingActionOrders
            ?.map((editableOrder) => {
                if (!editableOrder?.orderId) {
                    return adjustEditableOrderToDisplayInOrdersList(
                        editableOrder,
                    );
                } else {
                    const order = getOrderById(
                        editableOrder?.orderId,
                        awaitingActionOrders,
                    );
                    if (!order)
                        return adjustEditableOrderToDisplayInOrdersList(
                            editableOrder,
                        );
                    return adjustAwaitingActionOrderToDisplayInOrdersList(
                        order,
                        editableOrder,
                    );
                }
            })
            .filter((order) => !order.isSending) ?? [];

    const draftOrders: OrderToDisplayInOrdersList[] =
        sortOrdersToDisplayInListByDate(
            editableOrdersToDisplay
                ?.filter((order) => {
                    return order.status === OrderStatus.Blank;
                })
                .filter((order) => {
                    return order.isSending !== true;
                }),
        ) ?? [];

    const historyOrders = ordersToDisplay.filter((order) => {
        return order.status !== OrderStatus.OrderForReview;
    });

    const concatenatedHistoryOrdersToDisplay = sortOrdersToDisplayInListByDate([
        ...awaitingActionOrdersToDisplay,
        ...historyOrders,
        ...cashPurchaseOrdersToDisplay,
        ...sendingCashPurchaseOrdersToDisplay,
    ]);

    const sendingOrders = editableOrdersToDisplay?.filter((order) => {
        return order.isSending;
    });

    const getOrdersListToDisplay = (tabType: OverviewTabType) => {
        switch (tabType) {
            case OverviewTabType.overview:
                return awaitingActionOrdersToDisplay;
            case OverviewTabType.history:
                return sendingOrders?.concat(
                    concatenatedHistoryOrdersToDisplay,
                );
            case OverviewTabType.drafts:
                return draftOrders;
        }
    };

    const getHeaderContent = (tabType: OverviewTabType) => {
        switch (tabType) {
            case OverviewTabType.overview:
                return 'Orders Awaiting Action';
            case OverviewTabType.history:
                return 'Orders';
            case OverviewTabType.drafts:
                return 'Drafts';
        }
    };

    const getEmptyState = (tabType: OverviewTabType) => {
        const displayBorder = !(getOrdersListToDisplay(tabType).length > 0);
        switch (tabType) {
            case OverviewTabType.overview:
                return (
                    <div
                        className={[displayBorder ? styles.border : ''].join(
                            ' ',
                        )}
                    >
                        <EmptyState
                            title={
                                <div
                                    className={styles.emptyStateTitleContainer}
                                >
                                    <EmptyStateTickIcon
                                        width="26"
                                        height="26"
                                    />
                                    <p>
                                        All clear! You have no orders awaiting
                                        action.
                                    </p>
                                </div>
                            }
                            lessSpace
                        />
                    </div>
                );
            case OverviewTabType.history:
                return (
                    <div
                        className={[displayBorder ? styles.border : ''].join(
                            ' ',
                        )}
                    >
                        <EmptyState title="You have no orders." lessSpace />
                    </div>
                );
            case OverviewTabType.drafts:
                return (
                    <div
                        className={[displayBorder ? styles.border : ''].join(
                            ' ',
                        )}
                    >
                        <EmptyState
                            title="You have no draft orders."
                            lessSpace
                        />
                    </div>
                );
        }
    };

    const orderListContainerStyles = [
        styles.ordersListContainer,
        tabType === OverviewTabType.overview ? styles.withMargin : '',
    ].join(' ');

    useEffect(() => {
        revalidateSendingCashPurchaseOrders();
    }, [cashPurchaseOrders]);

    return (
        <div
            className={
                tabType === OverviewTabType.overview
                    ? styles.backgroundContainer
                    : ''
            }
        >
            {tabType === OverviewTabType.overview ? (
                <CreateNewOrderButtons />
            ) : (
                <div className={styles.greyGap}></div>
            )}
            <div className={orderListContainerStyles}>
                <div className={styles.orderListHeader}>
                    <h2>{getHeaderContent(tabType)}</h2>
                </div>
                <OrdersOverviewList
                    orders={getOrdersListToDisplay(tabType) ?? []}
                    isValidating={
                        isValidatingOrders && isEditableOrderValidating
                    }
                    emptyState={getEmptyState(tabType)}
                    showHeader={getOrdersListToDisplay(tabType).length > 0}
                />
            </div>
            {tabType === OverviewTabType.overview && (
                <>
                    <div className={orderListContainerStyles}>
                        <div className={styles.orderListHeader}>
                            <h2>Recent Drafts</h2>
                            {draftOrders.length > 0 && (
                                <Button
                                    onClick={() =>
                                        setActiveTab
                                            ? setActiveTab(
                                                  OverviewTabType.drafts,
                                              )
                                            : {}
                                    }
                                    text="See all"
                                    textButton
                                ></Button>
                            )}
                        </div>
                        <OrdersOverviewList
                            orders={draftOrders.slice(0, 4)}
                            isValidating={isEditableOrderValidating}
                            emptyState={
                                <div
                                    className={[
                                        !(draftOrders.length > 0)
                                            ? styles.border
                                            : '',
                                    ].join(' ')}
                                >
                                    <EmptyState
                                        title="You have no draft orders."
                                        lessSpace
                                    />
                                </div>
                            }
                            showHeader={draftOrders.length > 0}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
