import styles from './CommentButton.module.css';
import React from 'react';
import CommentBubbleIcon from '../../public/gfx/uiIcons/CommentBubbleIcon.svg';
import { ProductRowChangelogStatus } from '../ProductsList/SingleProductRow';

interface Props {
    onClick: () => void;
    isActive: boolean;
    hasComment: boolean;
    withBackground?: boolean;
    status?: ProductRowChangelogStatus;
    disabled?: boolean;
}

export const CommentButton: React.FC<Props> = ({
    onClick,
    withBackground,
    isActive,
    hasComment,
    status,
}) => {
    const isInLineItemRemovedOrLocked =
        status === ProductRowChangelogStatus.removed ||
        status === ProductRowChangelogStatus.locked;

    const isButtonDisabled = () => {
        if (
            isActive ||
            (isInLineItemRemovedOrLocked && hasComment) ||
            (!isInLineItemRemovedOrLocked && isActive)
        ) {
            return false;
        }
        return true;
    };

    const buttonDisabled = isButtonDisabled();

    return (
        <div
            className={[
                styles.commentButton,
                withBackground ? styles.withBackground : '',
                buttonDisabled ? styles.disablePointerCursor : '',
            ].join(' ')}
        >
            <button
                className={[
                    styles.commentButton,
                    hasComment ? styles.hasComment : '',
                ].join(' ')}
                onClick={onClick}
                disabled={buttonDisabled}
                data-testid={`${
                    hasComment ? 'commentButtonWithComment' : 'commentButton'
                }`}
            >
                <CommentBubbleIcon width={15} height={14} />
            </button>
        </div>
    );
};

export default CommentButton;
