import styles from './OrderSummaryDetailsTab.module.css';
import Input from '../Input/Input';
import { OrderToDisplay, RFQ } from '../../types/order.types';
import { db } from '../../db/db';
import { SetStateAction, useRef } from 'react';
import Autocomplete from '../Input/Autocomplete';
import { UseComboboxStateChange } from 'downshift';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import useOrderType from '../../hooks/useOrderType';
import { currencyFormatter } from '../utils/numberFormat';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import { ValidationFields } from '../../hooks/useOrderValidation';
import { OrderPort, OrderStatus } from '../../apiClient/generated';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import parsePort from '../../db/utils/ports';
import { updateEditableOrder } from '../../db/editableOrders';
import { OrderTypes } from '../../context/OrderTypes';
import { usePorts } from '../../hooks/usePorts';
import { MissingDeliveryPortHelper } from '../MissingDeliveryPortHelper/MissingDeliveryPortHelper';
import moment from 'moment';
import { useOrder } from '../../hooks/useOrder';
import { getLatestRfq } from '../../db/utils/getLatestRfq';

export enum RfqFormFields {
    deliveryPort = 'deliveryPort',
    agentCity = 'agentCity',
    agentEmail = 'agentEmail',
    agentName = 'agentName',
    agentPhone = 'agentPhone',
    coveringDays = 'coveringDays',
    manning = 'manning',
    deliveryDate = 'deliveryDate',
    nextPort = 'nextPort',
    departureDate = 'departureDate',
    lineItems = 'lineItems',
}

interface OrderSummaryDetailsTabProps {
    validationErrors?: ValidationFields;
    setValidationErrors?: (value: SetStateAction<ValidationFields>) => void;
    orderToDisplay: OrderToDisplay;
}

const OrderSummaryDetailsTab: React.FC<OrderSummaryDetailsTabProps> = ({
    validationErrors,
    setValidationErrors,
    orderToDisplay,
}) => {
    const { data: vesselMetadata } = useVesselMetadata();

    const ports = usePorts();

    const { activeOrderType, isDraft } = useOrderType();

    const isProvision = activeOrderType === OrderTypes.provision;

    const { data: rawOrder } = useOrder(orderToDisplay.orderId);

    const { isLocked, isReceived } = parseOrderStatus(orderToDisplay);

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const pastDeliveryDate =
        rawOrder &&
        moment().isAfter(moment(getLatestRfq(rawOrder)?.deliveryDate), 'day');

    const deliveryDateDisabled =
        (isLocked || orderToDisplay?.status === OrderStatus.OrderForReview) &&
        !(
            pastDeliveryDate &&
            orderToDisplay.status === OrderStatus.OrderForReview
        );

    const deliverDateLabel = isReceived
        ? 'Delivery date'
        : 'Estimated delivery date';

    const rfqFormValues = useRef(orderToDisplay.rfq);

    const setFormValues = async (update: (values: RFQ) => RFQ) => {
        rfqFormValues.current = update(rfqFormValues.current);
        await updateOrder();
    };

    const updateOrder = async () => {
        await updateEditableOrder(orderToDisplay, {
            rfq: { ...rfqFormValues.current },
        });
    };

    const handleAutocompleteChange = async (
        { selectedItem }: UseComboboxStateChange<string>,
        field: RfqFormFields,
    ) => {
        if (!selectedItem) {
            return;
        }

        let value: string | OrderPort = selectedItem;

        if (
            [RfqFormFields.deliveryPort, RfqFormFields.nextPort].includes(field)
        ) {
            const selectedPortDetails = ports?.find(
                ({ portNameWithCodeAndCountry }) =>
                    portNameWithCodeAndCountry === value,
            );

            const { portName, portCode } = selectedPortDetails || {};

            if (portName && portCode) {
                value = {
                    portName,
                    portCode,
                };
            }
        }

        setFormValues((values) => ({
            ...values,
            [field]: value,
        }));

        setValidationErrors?.((errors) => ({
            ...errors,
            deliveryPortDoesntMatch: false,
        }));
    };

    const handleChangeDeliveryDateTime = async (date: Date | null) => {
        if (!date) {
            return;
        }

        setFormValues((values) => ({
            ...values,
            deliveryDate: date,
        }));

        await updateEditableOrder(orderToDisplay, {
            rfq: {
                ...orderToDisplay.rfq,
                deliveryDate: date,
            },
        });

        setValidationErrors?.((errors) => ({
            ...errors,
            deliveryDateIsEmpty: false,
            deliveryDateIsTooEarly: false,
        }));
    };

    const handleChangeDepartureDateTime = async (date: Date | null) => {
        if (!date) {
            return;
        }
        setFormValues((values) => ({
            ...values,
            departureDate: date,
        }));

        await updateEditableOrder(orderToDisplay, {
            rfq: {
                ...orderToDisplay.rfq,
                departureDate: date,
            },
        });

        setValidationErrors?.((errors) => ({
            ...errors,
            departureDateIsEmpty: false,
            departureDateIsTooEarly: false,
            departureDateIsEalierThanDeliveryDate: false,
        }));
    };

    const getPortDisplayName = (
        type: RfqFormFields.deliveryPort | RfqFormFields.nextPort,
    ) => {
        const portDetails =
            rfqFormValues.current[type] || orderToDisplay.rfq[type];

        const { portName, portCode } = portDetails || {};
        const { portNameWithCodeAndCountry } = parsePort(
            portName ?? '',
            portCode ?? '',
        );

        return portNameWithCodeAndCountry;
    };

    const onDeliveryPortInputClear = async () => {
        await updateEditableOrder(orderToDisplay, {
            rfq: {
                ...orderToDisplay.rfq,
                deliveryPort: {
                    portCode: '',
                    portName: '',
                },
            },
        });

        setFormValues((values) => ({
            ...values,
            deliveryPort: {
                portCode: '',
                portName: '',
            },
        }));

        setValidationErrors?.((errors) => ({
            ...errors,
            deliveryPortDoesntMatch: true,
        }));
    };

    const getDepartureDateErrorMessage = () => {
        const {
            departureDateIsEmpty,
            departureDateIsTooEarly,
            departureDateIsEalierThanDeliveryDate,
        } = validationErrors ?? {};

        if (departureDateIsEmpty) {
            return 'Estimated date of departure is required';
        }
        if (departureDateIsTooEarly) {
            return 'Estimated date of departure is too early';
        }
        if (departureDateIsEalierThanDeliveryDate) {
            return 'Estimated date of departure cannot be earlier than delivery date';
        }
        return undefined;
    };

    return (
        <form
            onSubmit={(e) => e.preventDefault()}
            className={styles.detailsForm}
        >
            <fieldset className={styles.inputsWrapper}>
                <legend>
                    <h3 className={styles.sectionHeading}>
                        Delivery information
                    </h3>
                </legend>
                <Autocomplete
                    helper={
                        orderToDisplay.isSending ?? !isDraft ? (
                            <></>
                        ) : (
                            <MissingDeliveryPortHelper />
                        )
                    }
                    label="Delivery Port"
                    placeholder="Type to search ports"
                    items={
                        ports?.map(
                            ({ portNameWithCodeAndCountry }) =>
                                portNameWithCodeAndCountry,
                        ) || []
                    }
                    selectedItem={getPortDisplayName(
                        RfqFormFields.deliveryPort,
                    )}
                    handleSelectedItemChange={(changes) => {
                        handleAutocompleteChange(
                            changes,
                            RfqFormFields.deliveryPort,
                        );
                        setValidationErrors?.((errors) => ({
                            ...errors,
                            deliveryPortIsEmpty: false,
                        }));
                    }}
                    onBlur={updateOrder}
                    error={
                        validationErrors?.deliveryPortIsEmpty
                            ? 'Delivery port is required'
                            : validationErrors?.deliveryPortDoesntMatch
                            ? 'Select a delivery port from the list'
                            : undefined
                    }
                    onInputClear={onDeliveryPortInputClear}
                    name={RfqFormFields.deliveryPort}
                    disabled={orderToDisplay.isSending || !isDraft}
                />
                <Autocomplete
                    label="Next Port"
                    placeholder={isLocked ? 'N/A' : 'Type to search ports'}
                    items={
                        ports?.map(
                            ({ portNameWithCodeAndCountry }) =>
                                portNameWithCodeAndCountry,
                        ) || []
                    }
                    selectedItem={getPortDisplayName(RfqFormFields.nextPort)}
                    handleSelectedItemChange={(changes) => {
                        handleAutocompleteChange(
                            changes,
                            RfqFormFields.nextPort,
                        );
                    }}
                    handleInputValueChange={async (changes) => {
                        // Next port can hold custom text content
                        const { inputValue } = changes;
                        setFormValues((values) => ({
                            ...values,
                            [RfqFormFields.nextPort]: {
                                portName: inputValue ?? '',
                                portCode: '',
                                portNameWithCode: inputValue ?? '',
                                portNameWithCodeAndCountry: inputValue ?? '',
                            },
                        }));
                    }}
                    onBlur={updateOrder}
                    disabled={orderToDisplay.isSending || isLocked}
                    name={RfqFormFields.nextPort}
                />
                <CustomDatePicker
                    selectedDate={orderToDisplay.rfq.deliveryDate}
                    setSelectedDate={handleChangeDeliveryDateTime}
                    label={deliverDateLabel}
                    error={
                        validationErrors?.deliveryDateIsEmpty
                            ? 'Estimated delivery date is required'
                            : validationErrors?.deliveryDateIsTooEarly
                            ? 'Estimated delivery date is too early'
                            : undefined
                    }
                    name={RfqFormFields.deliveryDate}
                    disabled={orderToDisplay.isSending || deliveryDateDisabled}
                    futureDates
                />
                <CustomDatePicker
                    selectedDate={orderToDisplay.rfq.departureDate}
                    setSelectedDate={handleChangeDepartureDateTime}
                    label="Estimated date of departure"
                    error={getDepartureDateErrorMessage()}
                    name={RfqFormFields.deliveryDate}
                    disabled={orderToDisplay.isSending || isLocked}
                    futureDates
                />
            </fieldset>
            <fieldset className={styles.inputsWrapper}>
                <legend>
                    <h3 className={styles.sectionHeading}>Agent information</h3>
                </legend>
                <Input
                    label="Agent Name"
                    placeholder="e.g. Jane Doe"
                    defaultValue={orderToDisplay.rfq.agent?.name}
                    onChangeValue={(name) => {
                        setFormValues((values) => ({
                            ...values,
                            agent: {
                                ...(orderToDisplay.rfq.agent ?? {}),
                                ...(rfqFormValues.current.agent ?? {}),
                                name: name ?? '',
                            },
                        }));
                    }}
                    onBlur={updateOrder}
                    disabled={orderToDisplay.isSending || isLocked}
                    name={RfqFormFields.agentName}
                />
                <Input
                    label="Agent City"
                    placeholder="e.g. Copenhagen"
                    defaultValue={orderToDisplay.rfq.agent?.city}
                    onChangeValue={(city) => {
                        setFormValues((values) => ({
                            ...values,
                            agent: {
                                ...(orderToDisplay.rfq.agent ?? {}),
                                ...(rfqFormValues.current.agent ?? {}),
                                city: city ?? '',
                            },
                        }));
                    }}
                    onBlur={updateOrder}
                    disabled={orderToDisplay.isSending || isLocked}
                    name={RfqFormFields.agentCity}
                />
                <Input
                    label="Agent Phone"
                    placeholder="e.g. +1 2345 6789"
                    type="phone"
                    defaultValue={orderToDisplay.rfq.agent?.phone}
                    onChangeValue={(phone) => {
                        setFormValues((values) => ({
                            ...values,
                            agent: {
                                ...(orderToDisplay.rfq.agent ?? {}),
                                ...(rfqFormValues.current.agent ?? {}),
                                phone: phone ?? '',
                            },
                        }));
                    }}
                    onBlur={updateOrder}
                    disabled={orderToDisplay.isSending || isLocked}
                    name={RfqFormFields.agentPhone}
                />
                <Input
                    label="Agent Email"
                    placeholder="e.g. agent@example.org"
                    type="email"
                    defaultValue={orderToDisplay.rfq.agent?.email}
                    onChangeValue={(email) => {
                        setFormValues((values) => ({
                            ...values,
                            agent: {
                                ...(orderToDisplay.rfq.agent ?? {}),
                                ...(rfqFormValues.current.agent ?? {}),
                                email: email ?? '',
                            },
                        }));
                        setValidationErrors?.((errors) => ({
                            ...errors,
                            agentEmailIsInvalid: false,
                        }));
                    }}
                    onBlur={updateOrder}
                    disabled={orderToDisplay.isSending || isLocked}
                    error={
                        validationErrors?.agentEmailIsInvalid
                            ? 'This email address looks invalid'
                            : undefined
                    }
                    name={RfqFormFields.agentEmail}
                />
            </fieldset>
            <fieldset className={styles.inputsWrapper}>
                <legend>
                    <h3 className={styles.sectionHeading}>
                        Supplier information
                    </h3>
                </legend>
                {orderToDisplay.rfq.supplier ? (
                    <>
                        <Input
                            label="Supplier Name"
                            value={orderToDisplay.rfq.supplier?.name ?? 'N/A'}
                            disabled
                        />
                        <Input
                            label="Supplier City"
                            value={orderToDisplay.rfq.supplier?.city ?? 'N/A'}
                            disabled
                        />
                        <Input
                            label="Supplier Phone"
                            type="phone"
                            value={orderToDisplay.rfq.supplier?.phone ?? 'N/A'}
                            disabled
                        />
                        <Input
                            label="Supplier Email"
                            type="email"
                            value={orderToDisplay.rfq.supplier?.email ?? 'N/A'}
                            disabled
                        />
                    </>
                ) : (
                    <p className={styles.supplierNote}>
                        <em>
                            This information will be provided after your order
                            has been submitted and reviewed
                        </em>
                    </p>
                )}
            </fieldset>
            {isProvision && (
                <fieldset
                    className={`${styles.inputsWrapper} ${styles.narrow}`}
                >
                    <legend>
                        <h3 className={styles.sectionHeading}>
                            Budget information
                        </h3>
                    </legend>
                    <Input
                        label="Covering Days"
                        value={orderToDisplay.rfq.coveringDays ?? 1}
                        min="1"
                        onChangeValue={async (coveringDays) => {
                            setFormValues((values) => ({
                                ...values,
                                coveringDays: Number(coveringDays),
                            }));

                            await updateEditableOrder(orderToDisplay, {
                                rfq: {
                                    ...orderToDisplay.rfq,
                                    coveringDays: Number(coveringDays),
                                },
                            });

                            setValidationErrors?.((errors) => ({
                                ...errors,
                                coveringDaysIsNull: false,
                            }));
                        }}
                        type="number"
                        error={
                            validationErrors?.coveringDaysIsNull
                                ? 'Covering days has to be greater than 0'
                                : undefined
                        }
                        name={RfqFormFields.coveringDays}
                        disabled={orderToDisplay.isSending || isLocked}
                    />
                    <Input
                        label="Manning"
                        value={
                            orderToDisplay.rfq.manning ?? db.defaults.manning
                        }
                        min="1"
                        onChangeValue={async (manning) => {
                            setFormValues((values) => ({
                                ...values,
                                manning: Number(manning),
                            }));

                            await updateEditableOrder(orderToDisplay, {
                                rfq: {
                                    ...orderToDisplay.rfq,
                                    manning: Number(manning),
                                },
                            });

                            setValidationErrors?.((errors) => ({
                                ...errors,
                                manningIsNull: false,
                            }));
                        }}
                        type="number"
                        error={
                            validationErrors?.manningIsNull
                                ? 'Manning has to be greater than 0'
                                : undefined
                        }
                        name={RfqFormFields.manning}
                        disabled={orderToDisplay.isSending || isLocked}
                    />
                    <Input
                        label="Daily Rate"
                        value={`${currencyFormatter(
                            vesselMetadata?.dailyBudgetRateInVesselCurrency ??
                                0,
                            vesselCurrency,
                        )}`}
                        type="text"
                        disabled
                    />
                </fieldset>
            )}
        </form>
    );
};

export default OrderSummaryDetailsTab;
