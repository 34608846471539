import { useMemo } from 'react';
import styles from './ChatBubble.module.css';
import ChatMessageHeader from './ChatMessageHeader';

interface ChatBubbleProps {
    position?: 'start' | 'end';
    sender?: {
        avatarUrl?: string;
        displayName?: string;
    };
    createdAt?: Date;
    message: string;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
    position = 'start',
    sender,
    createdAt,
    message,
}) => {
    const messageParagraphs = useMemo(() => {
        if (message?.length) {
            return message.split(/\n/g);
        }

        return [];
    }, [message]);

    return (
        <figure className={styles.bubbleWrapper}>
            <figcaption
                className={`${styles.senderInfo} ${
                    position === 'end' ? styles.end : ''
                }`}
            >
                <ChatMessageHeader
                    displayName={sender?.displayName}
                    avatarUrl={sender?.avatarUrl}
                    createdAt={createdAt}
                />
            </figcaption>
            <blockquote className={styles.messageContent}>
                {messageParagraphs.map((paragraphContent, index) => (
                    <p key={index}>{paragraphContent}</p>
                ))}
            </blockquote>
        </figure>
    );
};

export default ChatBubble;
