import { useLiveQuery } from 'dexie-react-hooks';
import React, { useState } from 'react';
import { useSearch } from '../../context/SearchContext';
import BackButton from '../BackButton/BackButton';
import productListStyles from '../ProductsList/ProductsList.module.css';
import styles from './SearchResults.module.css';
import ArrowIcon from '../../public/gfx/uiIcons/collapsedArrow.svg';
import ProductDetailsModal from '../ProductsList/ProductDetailsModal';
import OpenFiltersButton from '../FiltersModal/OpenFiltersButton';
import FiltersModal from '../FiltersModal/FiltersModal';
import useHiddenProducts from '../../hooks/useHiddenProducts';
import { getAvailableProducts } from '../../db/products';
import { useProductDetails } from '../../context/ProductDetailsContext';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import { useProductSearch } from '../../hooks/useProductSearch';
import useSWR from 'swr';
import { SWRCacheKeys } from '../../events/dataFlowEvents';
import useOrderType from '../../hooks/useOrderType';
import { useSWRCachedResult } from '../../hooks/useSWRCachedResult';

interface SearchResultsProps {
    searchQuery: string;
}

const SearchResults: React.FC<SearchResultsProps> = ({ searchQuery }) => {
    const { handleChangeQuery } = useSearch();
    const { orderIdFromQuery } = useOrderType();
    const { data: editableOrder } = useEditableOrder(orderIdFromQuery);
    const { hiddenProducts } = useHiddenProducts(editableOrder);

    const availableProducts = useLiveQuery(async () => {
        if (!editableOrder) return;

        return await getAvailableProducts(editableOrder.type, hiddenProducts);
    });

    const { search } = useProductSearch(availableProducts);

    const { data } = useSWR(
        searchQuery ? [SWRCacheKeys.searchProducts, searchQuery] : null,
        ([, searchQuery]) => search(searchQuery),
    );
    const products = useSWRCachedResult(data);

    const handleGoBack = () => {
        handleChangeQuery('');
    };

    const { openModal } = useProductDetails();

    const [filters, setFilters] = useState<number[]>([]);
    const hasActiveFilters = filters.length > 0;

    const applyFilters = (localFilters: number[], silently?: boolean) => {
        setFilters(localFilters);
        if (!silently) {
            handleCloseFiltersModal();
        }
    };

    const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false);

    const handleOpenFiltersModal = () => {
        setFiltersModalIsOpen(true);
    };

    const handleCloseFiltersModal = () => {
        setFiltersModalIsOpen(false);
    };

    return (
        <>
            <ProductDetailsModal />
            <FiltersModal
                isOpen={filtersModalIsOpen}
                onClose={handleCloseFiltersModal}
                applyFilters={applyFilters}
                appliedFilters={filters}
                products={products ?? []}
            />
            <div>
                <div className={styles.searchResultsHeader}>
                    <BackButton onGoBack={handleGoBack} />
                    <p className="pageHeading">
                        Search Results:{' '}
                        <span className={styles.querySpan}>{searchQuery}</span>
                    </p>
                </div>
                <div className={productListStyles.container}>
                    <div
                        className={[
                            productListStyles.tableHead,
                            styles.searchResultsTableHead,
                        ].join(' ')}
                    >
                        <p>Item</p>
                        <p>Type</p>
                        <p>
                            <OpenFiltersButton
                                hasActiveFilters={hasActiveFilters}
                                handleOpenFiltersModal={handleOpenFiltersModal}
                            />
                        </p>
                    </div>
                    {products
                        ?.filter((item) =>
                            hasActiveFilters
                                ? filters.includes(item.categoryLevel3.number)
                                : item,
                        )
                        .map((product) => (
                            <div
                                className={[
                                    productListStyles.productCategoryRow,
                                    styles.resultRow,
                                ].join(' ')}
                                onClick={() => {
                                    if (!editableOrder) return;
                                    openModal(
                                        editableOrder,
                                        product.itemNumber,
                                        false,
                                    );
                                }}
                                key={product.itemNumber}
                            >
                                <div
                                    className={
                                        productListStyles.singleProductCell
                                    }
                                >
                                    <p className={productListStyles.primary}>
                                        {product.itemName}
                                    </p>
                                </div>
                                <div
                                    className={
                                        productListStyles.singleProductCell
                                    }
                                >
                                    <p
                                        className={
                                            productListStyles.secondaryBig
                                        }
                                    >
                                        Product
                                    </p>
                                </div>
                                <div>
                                    <ArrowIcon
                                        className="transitionRegular rotated180"
                                        width="24"
                                        height="24"
                                        stroke="#000"
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default SearchResults;
