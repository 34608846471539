import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import {
    fetchAndUpdateCondemnReports,
    getCondemnReportByReportPeriod,
} from '../db/condemnReports';

export const useCondemnReports = () => {
    const { data: condemnReports, mutate: revalidateCondemnReports } = useSWR(
        SWRCacheKeys.condemnReports,
        () => fetchAndUpdateCondemnReports(),
    );

    return {
        condemnReports,
        revalidateCondemnReports,
    };
};

export const useCondemnReport = (reportPeriod: string) => {
    const isReportPeriodValid = (reportPeriod: string) => {
        return !isNaN(Date.parse(reportPeriod)) && reportPeriod.length === 7;
    };

    const { data: condemnReport } = useSWR(
        reportPeriod ? [SWRCacheKeys.condemnReport, reportPeriod] : null,
        ([, reportPeriod]) => {
            if (!isReportPeriodValid(reportPeriod)) {
                console.error('invalid report period');
                return undefined;
            }
            return getCondemnReportByReportPeriod(reportPeriod);
        },
        swrDefaultConfig,
    );

    return {
        condemnReport,
    };
};
