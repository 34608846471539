import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import Layout from '../../../../../components/Layout/Layout';
import OrderPageTopbar from '../../../../../components/OrderPageTopbar/OrderPageTopbar';
import OrderSummary from '../../../../../components/OrderSummary/OrderSummary';
import OrderSummaryCardsSection from '../../../../../components/OrderSummaryCardsSection/OrderSummaryCardsSection';
import OrderSummaryPopups from '../../../../../components/OrderSummaryPopups/OrderSummaryPopups';
import {
    getProductsListIncludingItemsRemovedDuringRfqFlow,
    isUnavailableProduct,
} from '../../../../../components/utils/getProductsForOrderSummaryScreen';
import { getOrderPath } from '../../../../../components/utils/orderPathsGenerator';
import { calculateSummary } from '../../../../../components/utils/orderSummary';
import {
    OrderSubmissionResult,
    prepareOrderForSubmit,
    submitOrder,
} from '../../../../../components/utils/submitOrder';
import { doesOrderTypeHaveBudget } from '../../../../../context/OrderTypes';
import {
    getEditableOrderById,
    resetEditableOrderById,
    updateLineItemQuantity,
} from '../../../../../db/editableOrders';
import useChangelog from '../../../../../hooks/useChangelog';
import useOrderType from '../../../../../hooks/useOrderType';
import { useOrderValidation } from '../../../../../hooks/useOrderValidation';
import usePopupsInSummaryPage from '../../../../../hooks/usePopupsInSummaryPage';
import { useProducts } from '../../../../../hooks/useProducts';
import { useVesselMetadata } from '../../../../../hooks/useVesselMetadata';
import { useSyncStatus } from '../../../../../context/SyncStatusContext';
import { fetchAndUpdateProducts } from '../../../../../db/products';
import { Product } from '../../../../../types/order.types';
import { duplicateOrder } from '../../../../../db/utils/duplicateOrder';
import { OverviewTabType } from '../../../../../components/OrdersOverviewComponents/OrdersOverviewTabs/OverviewTab';
import { UnsavedDraftModalMetadata } from '../../../../../components/UnsavedDraftConfirmationModal/UnsavedDraftConfirmationModal';
import { ToastType, useToast } from '../../../../../context/ToastContext';
import { useOrderToDisplay } from '../../../../../hooks/useOrderToDisplay';
import { useOrderByLocalOrderIdFromQuery } from '../../../../../hooks/useOrderByLocalOrderId';
import { GoToEditModeModal } from '../../../../../components/OrderSummaryPopups/GoToEditModeModal/GoToEditModeModal';

export enum Tab {
    details = 'details',
    products = 'products',
    remarks = 'remarks',
}

const OrderSummaryPage: NextPage = () => {
    const { push, query } = useRouter();
    const { data: vesselMetadata } = useVesselMetadata();

    const { putFailedRequestModalMetadata } = useSyncStatus();

    const {
        validate,
        validationErrors,
        setValidationErrors,
        focusFirstValidationError,
        revalidateNoLineItemsError,
    } = useOrderValidation();

    const {
        popupsState,
        popupsHandlers,
        popupsHandlers: {
            closeConfirmSubmitModal,
            openApiDowntimeErrorModal,
            closeSummaryHalfStepModal,
            openBudgetExceededAlertModal,
            openConfirmSubmitModal,
            closeConfirmDuplicateModal,
            openUnavailableProductsModal,
            closeUnavailableProductsModal,
            openUnsavedDraftModal,
            closeUnsavedDraftModal,
            openUnhandledErrorModal,
        },
    } = usePopupsInSummaryPage();

    const [activeTab, setActiveTab] = useState<Tab>(Tab.details);

    const [isSubmittingInProgress, setIsSubmittingInProgress] = useState(false);

    const [unsavedDraftModalMetadata, setUnsavedDraftModalMetadata] =
        useState<UnsavedDraftModalMetadata>({
            isOpen: false,
        });

    const { orderIdFromQuery, activeOrderType } = useOrderType();

    const { data: products } = useProducts();

    const { order: orderWithChangelog, isOrderValidating } =
        useOrderByLocalOrderIdFromQuery();

    const { showToast } = useToast();

    const changelog = useChangelog(orderWithChangelog, products); // @todo performance

    const {
        orderToDisplay,
        changeEditableOrderItemStatus,
        saveEditableOrderDraft,
    } = useOrderToDisplay();

    const isSsPoNumberAvailableForOrder =
        orderToDisplay?.isSsPoNumberAvailableForOrder ?? false;

    const onDuplicateOrder = async () => {
        const { localOrderId } =
            (await duplicateOrder(Number(orderIdFromQuery))) ?? {};
        if (!localOrderId) {
            return;
        }

        // Closing the modal avoids the text updating after creating the draft
        closeConfirmDuplicateModal();

        fetchAndUpdateProducts();

        const orderToDisplay = await getEditableOrderById(localOrderId);

        if (!orderToDisplay) {
            return;
        }

        push({
            pathname: getOrderPath(orderToDisplay, 'preparation'),
            query: { duplicated: true },
        });
    };

    const onSubmitOrder = async () => {
        if (!orderToDisplay) {
            return;
        }
        setIsSubmittingInProgress(true);

        const rfq = prepareOrderForSubmit(orderToDisplay);

        if (!rfq) {
            // [refactor notes] should be handled
            setIsSubmittingInProgress(false);
            return;
        }

        const returnToDetailsTab = () => {
            setActiveTab(Tab.details);
            push(
                `/order/${activeOrderType.toLowerCase()}/${orderIdFromQuery}/${encodeURIComponent(
                    'details',
                )}`,
                undefined,
                { shallow: true },
            );
        };

        if (!rfq.lineItems?.length) {
            returnToDetailsTab();
            onSubmittingStopped();
            setActiveTab(Tab.products);
            return;
        } else {
            const { result, unavailableItemNumbers } = await submitOrder(
                orderToDisplay,
                rfq,
            );
            switch (result) {
                case OrderSubmissionResult.success:
                    await onOrderSubmitted();
                    return;
                case OrderSubmissionResult.apiOffline:
                    await saveEditableOrderDraft({
                        ...orderToDisplay,
                        isSending: true,
                    });
                    openApiDowntimeErrorModal();
                    onSubmittingStopped();
                    return;
                case OrderSubmissionResult.activeOrderExists:
                    openUnhandledErrorModal();
                    onSubmittingStopped();
                    return;
                case OrderSubmissionResult.requestFailed:
                    onSubmittingStopped();
                    openUnhandledErrorModal();
                    putFailedRequestModalMetadata({
                        orderToDisplay,
                    });
                    return;
                case OrderSubmissionResult.unavailableProducts:
                    onSubmittingStopped();
                    fetchAndUpdateProducts();
                    openUnavailableProductsModal(unavailableItemNumbers ?? []);
                    return;
            }
        }
    };

    const isBudgetExceeded = () => {
        const maxBudgetOvershoot = 1.25;

        const { orderTotal, orderBudget } = orderSummary ?? {
            orderTotal: 1,
            orderBudget: 1,
        };

        return maxBudgetOvershoot < orderTotal / orderBudget;
    };

    const orderSummary = useMemo(() => {
        if (orderToDisplay && products) {
            return calculateSummary(products, orderToDisplay, vesselMetadata);
        }
    }, [products, orderToDisplay, vesselMetadata]);

    const productsListIncludingItemsRemovedDuringRfqFlow = useMemo(
        () =>
            getProductsListIncludingItemsRemovedDuringRfqFlow({
                order: orderWithChangelog,
                orderToDisplay,
                changelog,
                products,
            }),
        [orderToDisplay, orderWithChangelog, products, changelog],
    );

    const hasUnavailableProducts = useMemo(() => {
        const unavailableProducts =
            productsListIncludingItemsRemovedDuringRfqFlow.filter(
                isUnavailableProduct,
            );

        return unavailableProducts.some(
            (product) =>
                (orderToDisplay?.rfq.lineItems.find(
                    (lineItem) => lineItem.itemNumber === product.itemNumber,
                )?.quantity ?? 0) > 0,
        );
    }, [productsListIncludingItemsRemovedDuringRfqFlow]);

    const onSubmitButtonClick = async () => {
        closeSummaryHalfStepModal();

        if (!orderToDisplay) {
            return;
        }

        const { tabWithError } = validate(orderToDisplay.rfq);

        if (tabWithError) {
            switch (tabWithError) {
                case Tab.details:
                    setActiveTab(Tab.details);
                    break;
                case Tab.products:
                    setActiveTab(Tab.products);
                    break;
                case Tab.remarks:
                    setActiveTab(Tab.remarks);
                    break;
            }
            focusFirstValidationError();
            return;
        }

        if (hasUnavailableProducts) {
            openUnavailableProductsModal([]);
            return;
        }

        if (
            doesOrderTypeHaveBudget(orderToDisplay.type) &&
            isBudgetExceeded()
        ) {
            openBudgetExceededAlertModal();
        } else {
            openConfirmSubmitModal();
        }
    };

    const setInitialTabFromQuery = () => {
        if (activeTab && query?.orderSummaryTab) {
            const queryString = query.orderSummaryTab.toString();
            const isQueryStringOkWithTabEnum = Object.values(Tab).includes(
                queryString as Tab,
            );

            if (isQueryStringOkWithTabEnum) {
                setActiveTab(queryString as Tab);
            }
        }
    };

    useEffect(() => {
        setInitialTabFromQuery();
    }, [query]);

    useEffect(() => {
        revalidateNoLineItemsError(orderToDisplay?.rfq);
    }, [orderToDisplay?.rfq.lineItems]);

    const onOrderSubmitted = async () => {
        if (!orderToDisplay) {
            return;
        }
        showToast({
            type: ToastType.success,
            text: 'Order submitted',
        });
        await resetEditableOrderById(orderToDisplay.localOrderId);
        await push(`/order?tab=${OverviewTabType.history}`);
    };

    const onSubmittingStopped = () => {
        setIsSubmittingInProgress(false);
        closeConfirmSubmitModal();
    };

    const onUnavailableProductsReview = async (
        unavailableProducts: Product[],
    ) => {
        if (!orderToDisplay) {
            closeUnavailableProductsModal();
            return;
        }

        setActiveTab(Tab.products);

        for (const product of unavailableProducts) {
            await updateLineItemQuantity(
                orderToDisplay,
                product,
                0,
                false, // don't remove these 0 quantity items, so user can review them
            );
        }

        closeUnavailableProductsModal();
    };

    const onRedirect = () => {
        if (
            unsavedDraftModalMetadata.isOpen &&
            !popupsState.apiDowntimeErrorModal
        ) {
            openUnsavedDraftModal();
        } else {
            closeUnsavedDraftModal();
        }
    };

    useEffect(() => {
        onRedirect();
    }, [unsavedDraftModalMetadata.isOpen]);

    if (!orderToDisplay) {
        return <Layout>{null}</Layout>;
    }

    return (
        <Layout>
            <OrderSummaryPopups
                popupsState={popupsState}
                popupsHandlers={popupsHandlers}
                submitOrder={onSubmitOrder}
                isSubmittingInProgress={isSubmittingInProgress}
                changelog={changelog}
                apiOnDowntimeConfirm={() =>
                    push(`/order?tab=${OverviewTabType.history}`)
                }
                orderToDisplay={orderToDisplay}
                onDuplicateOrder={onDuplicateOrder}
                onUnavailableProductsReview={onUnavailableProductsReview}
                onConfirmStopSending={() =>
                    changeEditableOrderItemStatus(orderToDisplay, false)
                }
                unsavedDraftModalPopupsMetadata={{
                    editableOrder: orderToDisplay,
                    setUnsavedDraftModalMetadata: setUnsavedDraftModalMetadata,
                    unsavedDraftModalMetadata: unsavedDraftModalMetadata,
                }}
            />
            <GoToEditModeModal orderToDisplay={orderToDisplay} />
            <OrderPageTopbar
                orderToDisplay={orderToDisplay}
                popupsHandlers={popupsHandlers}
                isInOrderSummaryPage
                numChanges={changelog.numberOfChangedProductsInModal}
                onSubmitButtonClick={onSubmitButtonClick}
                isSsPoNumberAvailableForOrder={isSsPoNumberAvailableForOrder}
                isOrderValidating={isOrderValidating}
            />
            {!popupsState.addItemsModal && (
                // We will unmount it for performance reasons.
                <>
                    <OrderSummaryCardsSection
                        orderSummary={orderSummary}
                        orderToDisplay={orderToDisplay}
                    />
                    <OrderSummary
                        orderToDisplay={orderToDisplay}
                        activeTab={activeTab}
                        changelog={changelog}
                        productsListIncludingItemsRemovedDuringRfqFlow={
                            productsListIncludingItemsRemovedDuringRfqFlow
                        }
                        setActiveTab={setActiveTab}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                    />
                </>
            )}
        </Layout>
    );
};
export default withAuthenticationRequired(OrderSummaryPage);
