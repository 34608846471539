import { Portal } from '@radix-ui/react-portal';
import { useLiveQuery } from 'dexie-react-hooks';
import { useEffect, useRef, useState } from 'react';
import { updateEditableOrder } from '../../../db/editableOrders';
import { getAvailableProducts } from '../../../db/products';
import useHiddenProducts from '../../../hooks/useHiddenProducts';
import { useProductSearch } from '../../../hooks/useProductSearch';
import { OrderToDisplay, Product } from '../../../types/order.types';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import modalStyles from '../../Modal/Modal.module.css';
import ProductsList from '../../ProductsList/ProductsList';
import SearchBox from '../../SearchBox/SearchBox';
import { SmallConfirmationModal } from '../../SmallConfirmationModal/SmallConfirmationModal';
import styles from './AddItemsModal.module.css';

interface AddItemsModalProps {
    isOpen: boolean;
    orderToDisplay: OrderToDisplay;
    closeAddItemsModal: () => void;
}

export const AddItemsModal: React.FC<AddItemsModalProps> = ({
    isOpen,
    orderToDisplay,
    closeAddItemsModal,
}) => {
    const { hiddenProducts } = useHiddenProducts(orderToDisplay);

    const originOrderCloned = useRef(orderToDisplay);
    useEffect(() => {
        originOrderCloned.current = orderToDisplay;
    }, [isOpen]);

    const products =
        useLiveQuery(async () => {
            const products = await getAvailableProducts(
                orderToDisplay.type,
                hiddenProducts,
            );

            const itemsIncludedInRfq =
                originOrderCloned.current.rfq.lineItems.map(
                    (item) => item.itemNumber,
                );

            const filteredProducts = products.filter(
                (item) => !itemsIncludedInRfq.includes(item.itemNumber),
            );

            return filteredProducts;
        }, [orderToDisplay.type, hiddenProducts, originOrderCloned.current]) ??
        []; // todo: Thomas is working on removing useLiveQueries when it's done, this useLiveQuery should disabpear as well.: https://dev.azure.com/wristohoi-setapp/Wrist%20Generate/_workitems/edit/1924

    const revertChanges = async () => {
        await updateEditableOrder(orderToDisplay, originOrderCloned.current);
    };

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const { search } = useProductSearch(products);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [expandAllCategories, setExpandAllCategories] = useState(false);

    const getFilteredProducts = async () => {
        const filteredProducts = await search(searchQuery);
        setFilteredProducts(filteredProducts);
    };

    useEffect(() => {
        if (searchQuery.length > 0) {
            setExpandAllCategories(true);
            getFilteredProducts();
        } else {
            setExpandAllCategories(false);
        }
    }, [searchQuery]);

    return (
        <>
            <Portal>
                <SmallConfirmationModal
                    isOpen={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    onAbort={() => setShowConfirmationModal(false)}
                    onConfirm={() => {
                        revertChanges();
                        setShowConfirmationModal(false);
                        closeAddItemsModal();
                    }}
                    title={'Do you want to discard your changes?'}
                    additionalSubtitle="The changes you made in this window will not be saved."
                    confirmBtnText="Discard"
                    cancelBtnText="Keep editing"
                />
            </Portal>
            <Modal
                isOpen={isOpen}
                noHeader
                large
                onClose={() => {
                    if (
                        originOrderCloned.current.rfq.lineItems.length !==
                        orderToDisplay.rfq.lineItems.length
                    ) {
                        setShowConfirmationModal(true);
                    } else {
                        revertChanges();
                        closeAddItemsModal();
                    }
                }}
            >
                <div className={styles.searchBoxContainer}>
                    <SearchBox
                        searchQuery={searchQuery}
                        handleChangeQuery={setSearchQuery}
                    />
                </div>
                <h2 className={styles.modalHeader}>Add more items to order</h2>
                <div className={modalStyles.modalContent}>
                    <ProductsList
                        orderToDisplay={orderToDisplay}
                        products={
                            searchQuery.length === 0
                                ? products
                                : filteredProducts
                        }
                        expandAllCategories={expandAllCategories}
                    />
                </div>
                <div className={modalStyles.squareActionButton}>
                    <div className={modalStyles.squareActionButtonChild}>
                        <Button
                            text={'Add Items'}
                            onClick={closeAddItemsModal}
                            primary
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};
