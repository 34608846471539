/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Blank
 *
 * 1 = Created
 *
 * 2 = RfqSent
 *
 * 3 = OrderForReview
 *
 * 4 = FinalOrder
 *
 * 5 = OrderForConfirmation
 *
 * 6 = ReceiptInProgress
 *
 * 7 = Receipt
 *
 * 8 = CancelledByVessel
 *
 * 9 = CancelledByGarrets
 */
export enum OrderStatus {
    Blank = 0,
    Created = 1,
    RfqSent = 2,
    OrderForReview = 3,
    FinalOrder = 4,
    OrderForConfirmation = 5,
    ReceiptInProgress = 6,
    Receipt = 7,
    CancelledByVessel = 8,
    CancelledByGarrets = 9,
}