import { CreateReceivalCommand } from './../../../apiClient/generated/models/CreateReceivalCommand';
import {
    ReceivedLineItem,
    ReceivedOrder,
} from './../../../types/receivedOrder.types';
import { LineItem, Product } from '../../../types/order.types';
import { RatingKey } from '../../../types/receivedOrder.types';
import { PerformanceReportRequestDto } from '../../../apiClient/generated';
import { currencyFormatter } from '../../utils/numberFormat';

export const getProductsByCategory = (
    categoryId: number,
    productsList: Product[],
) => {
    return productsList.filter(
        (product) => product.categoryLevel3.number === categoryId,
    );
};

export const getCategoryName = (productsList: Product[]) => {
    return productsList[0]?.categoryLevel3.text;
};

export const getRatingKeyFriendlyName = (ratingKey: RatingKey): string => {
    if (ratingKey === RatingKey.qualityFresh) return 'Fresh Provision';
    if (ratingKey === RatingKey.qualityDry) return 'Dry Provision';
    if (ratingKey === RatingKey.qualityFrozen) return 'Frozen Provision';
    if (ratingKey === RatingKey.qualityPackingMarking) {
        return 'Packaging & Labels';
    }
    if (ratingKey === RatingKey.overAllPerformance) {
        return 'Overall Supplier Performance';
    }
    return '';
};

export const getPerformanceReportQuestion = (ratingKey: RatingKey): string => {
    return `Please rate the quality of the ${getRatingKeyFriendlyName(
        ratingKey,
    ).toLowerCase()}. Does it match your expectations? If not, please take the time to tell us how we can improve.`;
};

export const mapReceivalToRequest = (
    receivedOrder: ReceivedOrder,
): CreateReceivalCommand => {
    let performanceReport: PerformanceReportRequestDto | undefined = undefined;

    if (
        receivedOrder.performanceReport?.experience &&
        receivedOrder.performanceReport?.ratings
    ) {
        const { experience, ratings } = receivedOrder.performanceReport;

        performanceReport = {
            blockFrozen: experience.blockFrozen ?? false,
            labelsInEnglish: experience.labelsInEnglish ?? false,
            supplierRepresentative: experience.supplierRepresentative ?? false,
            supplyOnTime: experience.supplyOnTime ?? false,
            plasticReturned: experience.plasticReturned ?? false,
            plasticReturnedQty: 0,
            ratings: {
                qualityFrozen: {
                    question: getPerformanceReportQuestion(
                        RatingKey.qualityFrozen,
                    ),
                    value: ratings.qualityFrozen!.rating,
                    comment: ratings.qualityFresh!.comment,
                },
                qualityDry: {
                    question: getPerformanceReportQuestion(
                        RatingKey.qualityDry,
                    ),
                    value: ratings.qualityDry!.rating,
                    comment: ratings.qualityDry!.comment,
                },
                qualityFresh: {
                    question: getPerformanceReportQuestion(
                        RatingKey.qualityFresh,
                    ),
                    value: ratings.qualityFresh!.rating,
                    comment: ratings.qualityFresh!.comment,
                },
                qualityPackingMarking: {
                    question: getPerformanceReportQuestion(
                        RatingKey.qualityPackingMarking,
                    ),
                    value: ratings.qualityPackingMarking!.rating,
                    comment: ratings.qualityPackingMarking!.comment,
                },
                overAllPerformance: {
                    question: getPerformanceReportQuestion(
                        RatingKey.overAllPerformance,
                    ),
                    value: ratings.overAllPerformance!.rating,
                    comment: ratings.overAllPerformance!.comment,
                },
            },
            remarks: receivedOrder.remarks,
        };
    }

    return {
        receival: {
            orderId: receivedOrder.orderId,
            deliveryDate: receivedOrder.rfqUpdates.deliveryDate?.toJSON() ?? '',
            items: Object.entries(receivedOrder.receivedLineItems)
                .filter(
                    //eslint-disable-next-line
                    ([key, value]) =>
                        // filter out new items with quanity = 0
                        value.isFromOrder ||
                        (!value.isFromOrder && value.receivedQuantity > 0),
                )
                //eslint-disable-next-line
                .map(([key, value]) => ({
                    productItemNumber: value.itemNumber,
                    comment: value.comment,
                    amountReceived: value.receivedQuantity,
                })),
            localId: receivedOrder.orderId,
        },
        localId: String(receivedOrder.orderId),
        performanceReport: performanceReport,
    };
};

export const getReceivedProductsWithPrices = (
    receivedProducts:
        | Record<LineItem['itemNumber'], ReceivedLineItem>
        | undefined,
    orderedProducts: LineItem[],
) => {
    if (!receivedProducts) {
        return [];
    }

    return Object.values(receivedProducts).map((value) => {
        const product = orderedProducts.find(
            (item) => item.itemNumber === value.itemNumber,
        );

        return {
            itemNumber: value.itemNumber,
            price: product?.confirmedPrice,
            receivedQuantity: value.receivedQuantity,
        };
    });
};

export const getReceivedProductsTotalValue = (
    receivedProducts:
        | Record<LineItem['itemNumber'], ReceivedLineItem>
        | undefined,
    orderedProducts: LineItem[],
    currency?: string,
) => {
    const receivedProductsWithPrices = getReceivedProductsWithPrices(
        receivedProducts,
        orderedProducts,
    );

    const totalProductsValue = receivedProductsWithPrices.reduce(
        (acc, curr) => acc + curr.receivedQuantity * Number(curr.price ?? 0),
        0,
    );

    return currencyFormatter(totalProductsValue, currency);
};

export const getReceivedProductsWithoutPricesQuantity = (
    receivedProducts:
        | Record<LineItem['itemNumber'], ReceivedLineItem>
        | undefined,
    orderedProducts: LineItem[],
) => {
    const receivedProductsWithPrices = getReceivedProductsWithPrices(
        receivedProducts,
        orderedProducts,
    );

    return receivedProductsWithPrices.filter((item) => !item.price).length;
};
