import Modal from '../Modal/Modal';
import Heading from './Heading';
import { OrderPreparationModalStep, RFQ } from '../../types/order.types';
import 'react-datepicker/dist/react-datepicker.css';
import OrderTypeStepContent from './Steps/OrderTypeStepContent';
import OrderDetailsStepContent from './Steps/OrderDetailsStepContent';
import StartingPointStepContent from './Steps/StartingPointStepContent';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { db } from '../../db/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { adjustOrderToDisplay } from '../utils/adjustOrderToDisplay';
import { createEditableOrderOfTypeWithRfqDetails } from '../../db/editableOrders';
import { OrderTypes } from '../../context/OrderTypes';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { LineItemWithProductDetails } from '../../db/utils/products';
import { getOpenOrdersData } from './utils';
import { useStepsNavigator } from './useStepsNavigator';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

export type OrderPreparationModalDisplayData = Partial<
    Pick<
        RFQ,
        'deliveryDate' | 'deliveryPort' | 'coveringDays' | 'manning' | 'agent'
    >
>;

interface OrderPreparationModalProps {
    orderType?: OrderTypes;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openRemovedLineItemsChangelogModal: (
        removedLineItemsWithProductDetails: LineItemWithProductDetails[],
    ) => void;
}

export const OrderPreparationModal: React.FC<OrderPreparationModalProps> = ({
    orderType,
    isOpen,
    setIsOpen,
    openRemovedLineItemsChangelogModal,
}) => {
    const { push } = useRouter();

    const [displayData, setDisplayData] =
        useState<OrderPreparationModalDisplayData>({
            coveringDays: db.defaults.coveringDays,
            manning: db.defaults.manning,
        });

    const [orderTypeSelectedInModal, setOrderTypeSelectedInModal] =
        useState<OrderTypes>(orderType ?? OrderTypes.provision);

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const onComplete = () => {
        setIsOpen(false);
    };

    const completedOrdersList = useLiveQuery(() => {
        return db.orders
            .where('type')
            .equalsIgnoreCase(orderTypeSelectedInModal)
            .and(
                (item) =>
                    parseOrderStatus(adjustOrderToDisplay(item))
                        .isRfqFlowCompleted,
            )
            .toArray();
    }, [orderTypeSelectedInModal]);

    const availableStartingPointsNumber = completedOrdersList?.length ?? 0;

    const shouldShowStartingPointStep = availableStartingPointsNumber
        ? availableStartingPointsNumber > 0
        : false;

    const { nextStep, previousStep, currentIndex, length, step } =
        useStepsNavigator({
            onComplete,
            shouldShowStartingPointStep,
            setIsOpenPreparationModal: setIsOpen,
            orderType,
        });

    /**
     * Suggest port, delivery date and agent info based on open orders (if any exist)
     */
    const preFillOrderDetailsBasedOnPreviousOrders = () => {
        setDisplayData((prev) => ({
            ...prev,
            ...getOpenOrdersData(orderTypeSelectedInModal),
        }));
    };

    useEffect(() => {
        preFillOrderDetailsBasedOnPreviousOrders();
    }, [orderTypeSelectedInModal]);

    const handleSelectOrderType = async (selectedOrderType: OrderTypes) => {
        setOrderTypeSelectedInModal(selectedOrderType);
    };

    const handleCreateOrder = async () => {
        if (orderTypeSelectedInModal) {
            // todo: add some loading state when creating order
            const localOrderId = await createEditableOrderOfTypeWithRfqDetails(
                orderTypeSelectedInModal,
                {
                    coveringDays:
                        displayData.coveringDays ?? db.defaults.coveringDays,
                    manning: displayData.manning ?? db.defaults.manning,
                    deliveryDate: displayData.deliveryDate ?? new Date(),
                    deliveryPort: displayData.deliveryPort,
                    agent:
                        displayData.agent ??
                        db.createInitialRfq(
                            vesselCurrency ?? db.defaults.currency,
                        ).agent,
                },
            );

            // create utils to create these paths
            push(
                `/order/${orderTypeSelectedInModal.toLowerCase()}/${localOrderId}/preparation`,
            );

            return localOrderId;
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case OrderPreparationModalStep.orderType:
                return (
                    <OrderTypeStepContent
                        onAction={nextStep}
                        actionName="Continue"
                        selectedOrderType={orderTypeSelectedInModal}
                        handleSelectOrderType={handleSelectOrderType}
                        onBack={previousStep}
                    />
                );
            case OrderPreparationModalStep.orderDetails:
                return (
                    <OrderDetailsStepContent
                        onAction={nextStep}
                        actionName="Continue"
                        orderTypeSelectedInModal={orderTypeSelectedInModal}
                        handleCreateOrder={
                            shouldShowStartingPointStep
                                ? undefined
                                : handleCreateOrder
                        }
                        editOrderDisplayData={displayData}
                        setEditOrderDisplayData={setDisplayData}
                        onBack={previousStep}
                    />
                );
            case OrderPreparationModalStep.startingPoint:
                return (
                    <StartingPointStepContent
                        onAction={nextStep}
                        actionName="Continue"
                        orderTypeSelectedInModal={orderTypeSelectedInModal}
                        handleCreateOrder={handleCreateOrder}
                        completedOrdersList={completedOrdersList}
                        onBack={previousStep}
                        openRemovedLineItemsChangelogModal={
                            openRemovedLineItemsChangelogModal
                        }
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal isOpen={isOpen} onNavigateBack={() => previousStep()}>
            <Heading currentIndex={currentIndex} length={length} step={step} />
            {renderStepContent()}
        </Modal>
    );
};
