import debounce from 'debounce';
import mixpanel from 'mixpanel-browser';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { OrderRfqOrigin, OrderToDisplay } from '../../types/order.types';
import { trackingEvents } from '../../context/Tracking';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { putCommentToWholeEditableOrder } from '../../db/editableOrders';
import Button from '../Button/Button';
import ChatBubble from '../ChatBubble/ChatBubble';
import styles from './OrderSummaryRemarksTab.module.css';
import { OrderStatus } from '../../apiClient/generated';
import { GarretsContactTooltip } from '../Tooltips/GarretsContactTooltip';
import { useOrder } from '../../hooks/useOrder';

interface ChatMessage {
    sender: {
        displayName: string;
        avatarUrl: string;
    };
    createdAt?: Date;
    message: string;
    position?: 'start' | 'end';
}

interface OrderSummaryRemarksTabProps {
    orderToDisplay: OrderToDisplay | undefined;
}

const OrderSummaryRemarksTab: React.FC<OrderSummaryRemarksTabProps> = ({
    orderToDisplay,
}) => {
    const { isLocked } = parseOrderStatus(orderToDisplay);

    const [chatMessage, setChatMessage] = useState('');

    const [messages, setMessages] = useState<ChatMessage[]>([]);

    const [wrapperMinHeight, setWrapperMinHeight] = useState(400);

    const wrapperRef = useRef<HTMLElement>(null);

    const listRef = useRef<HTMLElement>(null);

    const { data: order } = useOrder(orderToDisplay?.orderId);

    const isRemarksTabLocked = orderToDisplay?.isSending ?? isLocked;

    useEffect(() => {
        const messages: ChatMessage[] =
            order?.rfqs
                ?.filter((rfq) => rfq.comment)
                ?.map((rfq) => ({
                    createdAt: rfq?.created,
                    message: rfq?.comment ?? '',
                    sender: {
                        displayName:
                            rfq?.origin === OrderRfqOrigin.WristIntegration
                                ? 'Garrets'
                                : 'You',
                        avatarUrl:
                            rfq?.origin === OrderRfqOrigin.WristIntegration
                                ? '/gfx/generateLogo.svg'
                                : '',
                    },
                    position:
                        rfq?.origin === OrderRfqOrigin.WristIntegration
                            ? 'end'
                            : 'start',
                })) ?? [];

        if (!isRemarksTabLocked && orderToDisplay?.rfq?.comment?.length) {
            messages.push({
                createdAt: orderToDisplay?.rfq.created,
                message: orderToDisplay?.rfq.comment ?? '',
                sender: {
                    displayName: 'You',
                    avatarUrl: '',
                },
                position: 'start',
            });
        }

        setMessages(messages);
    }, [order, orderToDisplay?.rfq?.comment?.length]);

    useEffect(() => {
        // Makes sure the chat container has the right size
        // There doesn't seem to be a preferrable solution available via CSS
        if (wrapperRef?.current) {
            const onResize = () => {
                const offsetTop =
                    wrapperRef?.current?.getBoundingClientRect?.()?.top;
                if (
                    offsetTop &&
                    typeof window !== 'undefined' &&
                    !isNaN(window.innerHeight)
                ) {
                    setWrapperMinHeight(window.innerHeight - offsetTop);
                }
            };
            onResize();

            window.addEventListener('resize', debounce(onResize, 100));

            return () => window.removeEventListener('resize', onResize);
        }
    }, [wrapperRef]);

    const scrollToLatestMessage = (smooth?: boolean) => {
        if (listRef?.current) {
            // Scroll chat to bottom on initial load
            listRef.current.scroll({
                top:
                    listRef.current.scrollHeight - listRef.current.clientHeight,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    };

    useEffect(scrollToLatestMessage, [listRef]);
    useEffect(() => scrollToLatestMessage(true), [messages?.length]);

    const addComment = async (e: FormEvent<HTMLFormElement>) => {
        if (!orderToDisplay) return;

        e.preventDefault();
        const message = {
            sender: {
                displayName: 'You',
                avatarUrl: '',
            },
            message: chatMessage,
        };

        setChatMessage('');

        putCommentToWholeEditableOrder(orderToDisplay, message.message);

        mixpanel.track(trackingEvents.remarkOrderAdded, {
            orderId: orderToDisplay?.orderId,
        });
    };
    return (
        <section
            ref={wrapperRef}
            className={styles.remarksWrapper}
            style={{ height: wrapperMinHeight }}
        >
            {messages.length > 0 && (
                <section
                    className={`${
                        !isRemarksTabLocked
                            ? styles.chatMessagesListWithCommentSection
                            : ''
                    } ${styles.chatMessagesList} ${styles.scroll}`}
                    ref={listRef}
                >
                    {messages.map((message, index) => (
                        <ChatBubble
                            key={index}
                            sender={message.sender}
                            createdAt={message.createdAt}
                            message={message.message}
                            position={message.position}
                        />
                    ))}
                </section>
            )}
            {messages.length > 0 && order?.status === OrderStatus.Receipt && (
                <div className={styles.noRemarksContainer}>
                    <p className={styles.noRemarksText}>Order was completed</p>
                    <p
                        className={`${styles.noRemarksText} ${styles.noRemarksTextFaded}`}
                    >
                        If you have a question about this order or need help,
                        <br /> please reach out to{' '}
                        <GarretsContactTooltip>
                            <span className={styles.noRemarksTextBlue}>
                                {' '}
                                your Garrets contact
                            </span>
                        </GarretsContactTooltip>
                    </p>
                </div>
            )}
            {messages.length === 0 && order?.status === OrderStatus.Receipt && (
                <div className={styles.noRemarksContainer}>
                    <p className={styles.noRemarksText}>Order was completed</p>
                    <p
                        className={`${styles.noRemarksText} ${styles.noRemarksTextFaded} ${styles.remarksMessageExtraPadding}`}
                    >
                        If you have a question about this order or need help,
                        <br /> please reach out to{' '}
                        <GarretsContactTooltip>
                            <span className={styles.noRemarksTextBlue}>
                                {' '}
                                your Garrets contact
                            </span>
                        </GarretsContactTooltip>
                    </p>
                </div>
            )}
            {!orderToDisplay?.rfq?.comment && !isRemarksTabLocked && (
                <div>
                    <div className={styles.noRemarksContainer}>
                        {messages.length === 0 && (
                            <p className={styles.noRemarksText}>
                                This order has no remarks yet
                            </p>
                        )}
                        <p
                            className={`${styles.noRemarksText} ${styles.noRemarksTextFaded}`}
                        >
                            Use the input field below to attach remarks to your
                            order, before submitting it to Garrets
                        </p>
                    </div>
                    <section className={styles.editor}>
                        <form onSubmit={addComment}>
                            <textarea
                                className={styles.messageInput}
                                rows={1}
                                name="message"
                                placeholder="Type your message..."
                                onChange={(e) =>
                                    setChatMessage(e.target?.value)
                                }
                                value={chatMessage}
                            ></textarea>
                            <span className={styles.sendButtonWrapper}>
                                <Button
                                    text="Add comment"
                                    smallRounded
                                    disabled={!chatMessage.length}
                                />
                            </span>
                        </form>
                    </section>
                </div>
            )}
            {!orderToDisplay?.rfq?.comment &&
                isRemarksTabLocked &&
                !(order?.status === OrderStatus.Receipt) && (
                    <div className={styles.noRemarksContainer}>
                        {messages.length === 0 && (
                            <p className={styles.noRemarksText}>
                                This order has no remarks
                            </p>
                        )}
                        <div
                            className={`${styles.noRemarksText} ${styles.noRemarksTextFaded} ${styles.remarksMessageExtraPadding}`}
                        >
                            If you have a question about this order or need
                            help,
                            <br /> please reach out to{' '}
                            <GarretsContactTooltip>
                                <span className={styles.noRemarksTextBlue}>
                                    {' '}
                                    your Garrets contact
                                </span>
                            </GarretsContactTooltip>
                        </div>
                    </div>
                )}
            {orderToDisplay?.rfq?.comment &&
                isRemarksTabLocked &&
                !(order?.status === OrderStatus.Receipt) && (
                    <div className={styles.noRemarksContainer}>
                        <div
                            className={`${styles.noRemarksText} ${styles.noRemarksTextFaded} ${styles.remarksMessageExtraPadding}`}
                        >
                            If you have a question about this order or need
                            help,
                            <br /> please reach out to{' '}
                            <GarretsContactTooltip>
                                <span className={styles.noRemarksTextBlue}>
                                    {' '}
                                    your Garrets contact
                                </span>
                            </GarretsContactTooltip>
                        </div>
                    </div>
                )}
        </section>
    );
};

export default OrderSummaryRemarksTab;
