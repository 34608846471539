import EditIcon from '../../public/gfx/uiIcons/edit.svg';
import DeleteBinIcon from '../../public/gfx/uiIcons/deleteBin.svg';
import EditPenIcon from '../../public/gfx/uiIcons/editPen.svg';
import styles from './OrderOptionsDropdown.module.css';
import { useState } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { Portal } from '@radix-ui/react-portal';
import { Dropdown } from '../Dropdown/Dropdown';
import { useRouter } from 'next/router';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import { resetEditableOrderById } from '../../db/editableOrders';
import { ModalType, useModalContext } from '../../context/ModalContext';
import { DeleteDraftModal } from '../DeleteDraftModal/DeleteDraftModal';
import useOrderType from '../../hooks/useOrderType';

const OrderOptionsDropdown = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
    const [resetting, setResetting] = useState(false);

    //TODO: add real logic

    const { openGlobalModal } = useModalContext();

    const { push, replace } = useRouter();
    // todo: we should utilize it somehow, maybe we can export function like 'getEditableOrderBasedOnQuery() and it could change key? Or we can have something like orderToDisplay in [summaryPage].. tbd '
    const { orderIdFromQuery } = useOrderType();
    const { data: editableOrder, isEditableOrderSavedAsDraftAlready } =
        useEditableOrder(orderIdFromQuery);

    const handleResetOrder = async () => {
        if (!editableOrder) {
            return;
        }

        setResetting(true);
        await resetEditableOrderById(editableOrder.localOrderId);
        setResetting(false);
        push('/order');
    };

    const handleDeleteDraft = async () => {
        await resetEditableOrderById(
            editableOrder?.localOrderId ?? editableOrder?.orderId ?? '',
        );
        setIsDeleteDraftModalOpen(false);
        await replace('/order');
    };

    const items = editableOrder
        ? [
              {
                  id: 'edit',
                  onClick: () => {
                      openGlobalModal({
                          modalType: ModalType.orderDetails,
                          orderId: editableOrder.localOrderId,
                          shouldRecalculateAmount: false,
                      });
                  },

                  content: (
                      <>
                          Edit order <EditIcon height={24} />
                      </>
                  ),
              },
          ]
        : [];

    if (editableOrder?.status === 0) {
        items.push({
            id: 'delete',
            onClick: () => {
                setIsDeleteDraftModalOpen(true);
            },
            content: (
                <>
                    Delete draft <DeleteBinIcon height={24} />
                </>
            ),
        });
    }
    if (isEditableOrderSavedAsDraftAlready && editableOrder?.status === 0) {
        items.push({
            id: 'editName',
            onClick: () =>
                openGlobalModal({
                    modalType: ModalType.saveDraft,
                    orderId: editableOrder.localOrderId,
                }),
            content: (
                <>
                    Edit name <EditPenIcon height={24} />
                </>
            ),
        });
    }

    return (
        <>
            <Portal>
                <ConfirmationModal
                    isOpen={showConfirmation}
                    onClose={() => setShowConfirmation(false)}
                    onComplete={handleResetOrder}
                    processing={resetting}
                    title={`Do you want to reset your ${editableOrder?.type} draft order?`}
                    description="Selected products and order details will be reset
                and you can start a new order."
                    confirmOptionText="Reset draft"
                    cancelOptionText="Keep draft"
                    type="Confirmation"
                />
            </Portal>
            <Portal>
                <DeleteDraftModal
                    isOpen={isDeleteDraftModalOpen}
                    onConfirm={handleDeleteDraft}
                    onCancel={() => setIsDeleteDraftModalOpen(false)}
                />
            </Portal>
            {items.length > 0 && (
                <div className={styles.dropdown}>
                    <Dropdown items={items} biggerDropdownWithTriggerButton />
                </div>
            )}
        </>
    );
};

export default OrderOptionsDropdown;
