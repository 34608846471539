import React, { useEffect, useMemo } from 'react';
import modalStyles from '../../Modal/Modal.module.css';
import modalHeaderStyles from '../../ModalHeader/ModalHeader.module.css';
import styles from './Receival.module.css';
import {
    getLineItemsFromOrderToPutToReceived,
    useReceivedOrder,
} from '../../../hooks/useReceivedOrder';
import { OrderToDisplay } from '../../../types/order.types';
import Button from '../../Button/Button';
import CountryFlag from '../../Flags/CountryFlag';
import moment from 'moment';
import { getShortWbPono } from '../../utils/wbPono';
import {
    portCodeToCountryDisplayName,
    portCountryCodeFromFullCode,
} from '../../../db/utils/ports';
import { initialReceivedOrder } from '../../../types/receivedOrder.types';
import { getOrderTotalFromCompletedOrder } from '../../utils/orderSummary';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import DetailsDataRow from '../../DetailsDataTable/DetailsDataRow';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import StepsProgressBar from '../../StepsProgressBar/StepsProgressBar';
import { isRedoReceivalOrder } from '../../../hooks/useOrders';
import { useVesselMetadata } from '../../../hooks/useVesselMetadata';

interface Props {
    orderToDisplay?: OrderToDisplay;
    onContinue: () => void;
    onClose: () => void;
    steps: number;
}

const ReceivalDetailsModal: React.FC<Props> = ({
    onContinue,
    onClose,
    orderToDisplay,
    steps,
}) => {
    const {
        data: receivedOrder,
        upsertReceivedOrder,
        updateOrderDeliveryDate,
        isReceivedOrderValidating,
    } = useReceivedOrder(orderToDisplay?.orderId);

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const orderTotal = useMemo(
        () =>
            orderToDisplay?.rfq
                ? getOrderTotalFromCompletedOrder(
                      orderToDisplay.rfq,
                      vesselCurrency,
                  )
                : 0,
        [orderToDisplay],
    );

    const handleChangeDeliveryDate = async (
        selectedDeliveryDate: Date | null,
    ) => {
        if (!selectedDeliveryDate) {
            return;
        }
        await updateOrderDeliveryDate(selectedDeliveryDate);
    };

    const insertInitialReceivedOrder = async (
        orderToDisplay: OrderToDisplay,
    ) => {
        if (!orderToDisplay.orderId) {
            return;
        }

        const itemsFromOrder =
            getLineItemsFromOrderToPutToReceived(orderToDisplay);

        if (!isReceivedOrderValidating && !receivedOrder) {
            await upsertReceivedOrder({
                ...initialReceivedOrder,
                receivedLineItems: itemsFromOrder,
                orderId: orderToDisplay.orderId,
                rfqUpdates: {
                    deliveryDate: orderToDisplay.rfq.deliveryDate,
                },
            });
        }
    };

    useEffect(() => {
        if (orderToDisplay) insertInitialReceivedOrder(orderToDisplay);
    }, [isReceivedOrderValidating]);

    const isDeliveryDateProper = () => {
        if (!moment.isDate(receivedOrder?.rfqUpdates.deliveryDate)) {
            return false;
        }

        return moment(receivedOrder?.rfqUpdates.deliveryDate).isSameOrBefore(
            moment().add(7, 'days').startOf('day'),
        );
    };

    const redoReceival = orderToDisplay && isRedoReceivalOrder(orderToDisplay);

    const orderWbPono = (
        <p className={styles.orderWbPono}>
            {orderToDisplay?.type} #
            {getShortWbPono(orderToDisplay?.rfq?.wbPono)}
        </p>
    );

    return (
        <>
            <ModalHeader
                title={
                    <div className={styles.stepsProgressBar}>
                        <StepsProgressBar
                            currentStep={1}
                            totalStepsLength={steps}
                            parentRemounts
                        />
                    </div>
                }
                subTitle={
                    redoReceival
                        ? 'Order changed'
                        : 'Have you received this order?'
                }
                description={
                    redoReceival ? (
                        <>
                            {orderWbPono}
                            <p className={modalHeaderStyles.description}>
                                Garrets has updated your order with changes.
                                Please continue and confirm that all the details
                                are correct.
                            </p>
                            <p className={modalHeaderStyles.description}>
                                For more information about the changes, please
                                refer to your e-mail.
                            </p>
                        </>
                    ) : (
                        orderWbPono
                    )
                }
            />
            <DetailsDataTable>
                <DetailsDataRow title="Order details" uppercaseTitle />
                <DetailsDataRow
                    title="Delivery port"
                    value={
                        <p className={styles.detailsText}>
                            <span className={styles.cell}>
                                <CountryFlag
                                    portCode={
                                        orderToDisplay?.rfq.deliveryPort
                                            ?.portCode
                                    }
                                />
                                &nbsp;
                                {orderToDisplay?.rfq.deliveryPort?.portName}
                                {', '}
                                {orderToDisplay?.rfq.deliveryPort?.portCode
                                    ? portCodeToCountryDisplayName(
                                          portCountryCodeFromFullCode(
                                              orderToDisplay?.rfq.deliveryPort
                                                  ?.portCode,
                                          ),
                                      )
                                    : ''}
                            </span>
                        </p>
                    }
                />
                <DetailsDataRow
                    title="Date delivered"
                    boldValue
                    value={
                        <div className={styles.dateInput}>
                            <CustomDatePicker
                                selectedDate={
                                    receivedOrder?.rfqUpdates.deliveryDate
                                }
                                setSelectedDate={handleChangeDeliveryDate}
                                pastDates
                                maxDate={moment()
                                    .add('7', 'days')
                                    .startOf('day')
                                    .toDate()}
                                error={
                                    !isDeliveryDateProper()
                                        ? 'Date cannot be later than 7 days from today'
                                        : undefined
                                }
                            />
                        </div>
                    }
                />
                <DetailsDataRow
                    title="Supplier"
                    boldValue
                    value={orderToDisplay?.rfq?.supplier?.name ?? 'unknown'}
                />
                <DetailsDataRow
                    title="Products ordered"
                    boldValue
                    value={orderToDisplay?.rfq?.lineItems.length}
                />
                <DetailsDataRow
                    title="Order total"
                    boldValue
                    value={orderTotal}
                />
            </DetailsDataTable>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Close" onClick={onClose} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onContinue}
                        primary
                        disabled={!isDeliveryDateProper()}
                    />
                </div>
            </div>
        </>
    );
};

export default ReceivalDetailsModal;
