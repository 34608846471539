import styles from './Checkbox.module.css';
import CheckmarkBlueIcon from '../../public/gfx/uiIcons/checkmarkBlue.svg';
import CheckmarkGreenIcon from '../../public/gfx/uiIcons/checkmarkGreen.svg';

interface CheckboxProps {
    onClick: () => void;
    text?: string;
    isChecked: boolean;
    emptyWhenUnchecked?: boolean;
}

const CheckmarkEmpty = () => {
    return <div className={styles.emptyCheckmark} />;
};

export const Checkbox: React.FC<CheckboxProps> = ({
    onClick,
    text,
    isChecked,
    emptyWhenUnchecked,
}) => {
    const uncheckedComponent = Boolean(emptyWhenUnchecked) ? (
        <CheckmarkEmpty />
    ) : (
        <CheckmarkBlueIcon />
    );

    return (
        <button
            className={styles.button}
            onClick={onClick}
            aria-label={`${isChecked ? 'Uncheck' : 'Check'} this item`}
            data-testid="checkbox"
        >
            {isChecked ? <CheckmarkGreenIcon /> : uncheckedComponent}
            {Boolean(text) && <p className={styles.label}>{text}</p>}
        </button>
    );
};
