import {
    CashPurchaseOrigin,
    CashPurchaseStatus,
    Category,
    NutritionGroup,
    OrderPort,
    OrderStatus,
    ReceivalDto,
} from '../apiClient/generated';
import { OrderTypes } from '../context/OrderTypes';
import { LastCommentFromRfqFlow } from '../db/editableOrders';

export interface Port extends OrderPort {
    id: number;
    portNameWithCode: string;
    portNameWithCodeAndCountry: string;
}

export interface RFQ {
    id?: number;
    wbPono?: string;
    coveringDays: number;
    contractCurrency?: string;
    manning: number;
    comment?: string;
    lineItems: LineItem[];
    deliveryPort?: OrderPort;
    nextPort: OrderPort;
    deliveryDate: Date;
    departureDate?: Date;
    agent?: Agent;
    supplier?: Supplier;
    created?: Date;
    origin?: OrderRfqOrigin;
    isSsPoNumberAvailableForOrder: boolean;
}

export interface Order {
    orderId: number;
    systemRfqId: string;
    vesselImo: number;
    type: OrderTypes;
    created: Date;
    rfqs: RFQ[];
    lastModified: Date;
    status: OrderStatus;
    receival?: ReceivalDto;
    isSsPoNumberAvailableForOrder: boolean;
    isPerformanceReportCreated: boolean;
}

export interface OrderToDisplay extends EditableOrder {
    lastModified?: Date;
    isSsPoNumberAvailableForOrder?: boolean;
}

export interface OrderToDisplayInOrdersList {
    localOrderId: string;
    orderId?: number;
    type: OrderTypes;
    deliveryPort: OrderPort;
    deliveryDate: Date;
    lineItems: LineItem[];
    status?: OrderStatus | CashPurchaseStatus;
    lastUpdated: Date;
    isSending?: boolean;
    draftName?: string;
    wbPono?: string;
    receival?: ReceivalDto;
    currency?: string;
    cashDiscount?: number;
    origin?: CashPurchaseOrigin;
}

export interface EditableOrder {
    /**
     * It's a primary key in IndexedDb. We use uuid library to generate these identifiers.
     */
    localOrderId: string;
    type: OrderTypes;
    orderId?: number;
    status: OrderStatus;
    rfq: RFQ;
    savedRfq?: RFQ;
    receival?: ReceivalDto;
    isSending?: boolean;
    draftName?: string;
}

// This is a reduced response from list API endpoint at `/orders`
export type OrderSummary = Pick<
    Order,
    | 'orderId'
    | 'type'
    | 'status'
    | 'created'
    | 'lastModified'
    | 'isSsPoNumberAvailableForOrder'
>;

export interface LineItem {
    id?: number;
    itemNumber: string;
    quantity: number;
    comment?: string | null;
    unitOfMeasure?: string;
    confirmedPackSize?: number;
    confirmedPrice?: number;
    estimatedPrice?: number;
    estimatedPackSize?: number;
    lastCommentFromRfqFlow?: LastCommentFromRfqFlow;
    itemName?: string | null;
    price?: number;
}

export interface Agent {
    name?: string;
    city?: string;
    phone?: string;
    email?: string;
}

export interface Supplier extends Agent {}

export enum OrderRfqOrigin {
    App,
    WristIntegration,
}

export enum OrderPreparationModalStep {
    orderType = 'orderType',
    orderDetails = 'orderDetails',
    startingPoint = 'startingPoint',
}

export interface Product {
    itemName: string;
    itemNumber: string;
    area: string;
    categoryLevel1: Category;
    categoryLevel2: Category;
    categoryLevel3: Category;
    nutritionGroup: NutritionGroup;
    unitOfMeasure: string;
    estimatedPrice: number;
    estimatedPackSize: number;
    orderedQuantity?: number;
}
