import { GenerateDexieClass } from '../db';

export const onUpgradeTo16 = async (db: GenerateDexieClass) => {
    enum OrderTypes {
        provision = 'Provision',
        bonded = 'Bonded',
    }

    interface RFQ {
        id?: number;
        coveringDays: number;
        contractCurrency?: string;
        manning: number;
        comment?: string;
        lineItems: LineItem[];
        deliveryPort?: RFQPort;
        nextPort: RFQPort;
        deliveryDate: Date;
        departureDate: Date;
        agent?: Agent;
        supplier?: Supplier;
        created?: Date;
        origin?: OrderRfqOrigin;
    }

    interface LineItem {
        id?: number;
        itemNumber: number;
        quantity: number;
        comment?: string;
        unitOfMeasure?: string;
        packSize?: number;
        estimatedPrice?: number;
        price?: number;
    }

    interface Agent {
        name?: string;
        city?: string;
        phone?: string;
        email?: string;
    }

    interface Supplier extends Agent {}

    interface RFQPort {
        portName: string;
        portCode: string;
    }

    enum OrderRfqOrigin {
        App,
        WristIntegration,
    }

    interface CachedOrder16 {
        orderId: number;
        type: OrderTypes;
        status: number;
        totalOrderValue: number;
        created: Date;
        lastModified?: Date;
        latestRfq?: RFQ;
        details: {
            orderId: number;
            systemRfqId: string;
            status: number;
            vesselImo: number;
            type: OrderTypes;
            created: Date;
            rfqs: RFQ[];
        };
    }

    // MIGRATIONS
    const draftOrders = await db.table<CachedOrder16>('draftOrders').toArray();
    for (const draftOrder of draftOrders) {
        // Next port cannot be null
        if (!draftOrder.latestRfq?.nextPort) {
            await db.table<CachedOrder16>('draftOrders').update(draftOrder, {
                latestRfq: {
                    ...(draftOrder.latestRfq ?? {}),
                    nextPort: { portName: '', portCode: '' },
                },
            });
        }
        // Departure date is a new property
        if (!draftOrder.latestRfq?.departureDate) {
            await db.table<CachedOrder16>('draftOrders').update(draftOrder, {
                latestRfq: {
                    ...(draftOrder.latestRfq ?? {}),
                    departureDate: new Date(),
                },
            });
        }
    }
    const offlineOrders = await db
        .table<CachedOrder16>('offlineOrders')
        .toArray();
    for (const offlineOrder of offlineOrders) {
        // Next port cannot be null
        if (!offlineOrder.latestRfq?.nextPort) {
            await db
                .table<CachedOrder16>('offlineOrders')
                .update(offlineOrder, {
                    latestRfq: {
                        ...(offlineOrder.latestRfq ?? {}),
                        nextPort: { portName: '', portCode: '' },
                    },
                });
        }
        // Departure date is a new property
        if (!offlineOrder.latestRfq?.departureDate) {
            await db
                .table<CachedOrder16>('offlineOrders')
                .update(offlineOrder, {
                    latestRfq: {
                        ...(offlineOrder.latestRfq ?? {}),
                        departureDate: new Date(),
                    },
                });
        }
    }
};
