import { useAuth0 } from '@auth0/auth0-react';
import useSWR, { mutate } from 'swr';
import { apiClient } from '../apiClient/apiClient';
import { mapToManningReport } from '../apiClient/apiClientMappers';
import { db } from '../db/db';
import { updateManningMetadata } from '../db/manningMetadata';
import {
    getDayLogsForEachDayOfMonth,
    getManningOptions,
    getManningReport,
} from '../db/manningReports';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { getCurrentMonthForManningFlow } from '../utils/getCurrentMonthForManningFlow';
import { ItemStatus } from '../types/itemStatuses.types';
import { getLocalId, ItemType } from '../db/utils/getLocalId';
import { ManningReport } from '../types/manning.types';
import { ReOpenSource } from '../apiClient/generated';
import { useMemo } from 'react';

export const getFetcher = (withReopen?: boolean) => async () => {
    const response = await apiClient.getManningReports();
    const reports = response.map(mapToManningReport);

    const reOpenRequests = withReopen ? await apiClient.getReopens() : [];

    const manningReopenRequests =
        reOpenRequests?.filter(
            (request) => request.source === ReOpenSource.Manning,
        ) ?? [];

    for (const report of reports) {
        const localReport = await getManningReport(report.monthAndYear);

        const reopenRequest = manningReopenRequests?.find(
            (reOpenRequest) =>
                reOpenRequest.reportMonth === report.monthAndYear,
        );

        if (reopenRequest) {
            report.state = ItemStatus.awaitingAction;
            // A localId is needed for offline submission support
            report.localId = getLocalId({
                itemType: ItemType.stocktaking,
                reportMonth: report.monthAndYear,
            });

            const skipableStates = [
                ItemStatus.awaitingAction,
                ItemStatus.sending,
            ];
            const skipUpdating =
                localReport && skipableStates.includes(localReport.state);

            if (skipUpdating) {
                // Don't update reports that are currently on the re-open list
                continue;
            }
        }

        await db.manningReports.put(report);
    }

    return db.manningReports.orderBy('monthAndYear').toArray();
};

/**
 * @param config We need to pass in the config from the parent instead of calling `useFeatureFlags` as it would create a dependency loop, because this hook is used inside `useFeatureFlags`
 * @returns
 */
export const useManningReports = (config?: { withReopen?: boolean }) => {
    const { user } = useAuth0();

    const fetchAndUpdateManningMetadata = async () => {
        const response = await apiClient.getManningMetadata();
        await updateManningMetadata(response);

        return await db.manningMetadata.toArray();
    };

    const fetchAndUpdateManningReports = useMemo(
        () => getFetcher(config?.withReopen),
        [getFetcher, config?.withReopen],
    );

    const {
        data: manningMetadata,
        isValidating: isValidatingManningMetadata,
        isLoading: isLoadingManningMetadata,
    } = useSWR(
        SWRCacheKeys.manningMetadata,
        fetchAndUpdateManningMetadata,
        swrDefaultConfig,
    );

    const {
        data: manningReports,
        mutate: refreshManningReports,
        isValidating: isValidatingManningReports,
        isLoading: isLoadingManningReports,
    } = useSWR(SWRCacheKeys.manningReports, fetchAndUpdateManningReports, {
        ...swrDefaultConfig,
        revalidateIfStale: true,
    });

    const hasDraftManningReport = (manningReports ?? []).some(
        (report) => report.state === ItemStatus.draft,
    );

    const createNewManningReportForMonth = async (monthAndYear: string) => {
        if (!manningMetadata) {
            return;
        }
        const manningOptions = getManningOptions(manningMetadata[0].options);
        const initialManningReport: ManningReport = {
            localId: getLocalId({
                itemType: ItemType.manning,
                monthAndYear,
            }),
            monthAndYear,
            state: ItemStatus.draft,
            days: getDayLogsForEachDayOfMonth(monthAndYear, manningOptions),
            manningOptions: manningOptions,
            lastModified: new Date(),
        };

        db.manningReports.add(initialManningReport);
        mutate([...(manningReports ?? []), initialManningReport], {
            revalidate: false,
        });
    };

    const changeReportStatusOptimistically = (
        monthAndYear: string,
        newStatus: ItemStatus,
    ) => {
        const clonedManningReports = [...(manningReports ?? [])];

        const reportToChange = clonedManningReports.find(
            (item) => item.monthAndYear === monthAndYear,
        );

        if (!reportToChange) {
            return;
        }

        reportToChange.state = newStatus;

        db.manningReports.put(reportToChange);
        mutate(clonedManningReports, {
            revalidate: true,
        });
    };

    const isAnyManningReportInAwaitingAction = manningReports?.some(
        (report) => {
            return report.state === ItemStatus.awaitingAction;
        },
    );

    return {
        currentMonth: getCurrentMonthForManningFlow(user, manningReports ?? []),
        hasDraftManningReport,
        manningReports,
        refreshManningReports,
        createNewManningReportForMonth,
        isValidating: isValidatingManningMetadata || isValidatingManningReports,
        isLoading: isLoadingManningMetadata || isLoadingManningReports,
        changeReportStatusOptimistically,
        isAnyManningReportInAwaitingAction,
    };
};
