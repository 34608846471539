import useSWR, { mutate } from 'swr';
import { db } from '../db/db';
import { OrderTypes } from '../context/OrderTypes';
import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';

const fetchDbData = async () => {
    const orderTypes: OrderTypes[] = [];
    const assortments = await db.assortments.toCollection().first();

    if (assortments?.provision?.length) {
        orderTypes.push(OrderTypes.provision);
    }
    if (assortments?.bonded?.length) {
        orderTypes.push(OrderTypes.bonded);
    }
    if (assortments?.nonFood?.length) {
        orderTypes.push(OrderTypes.nonFood);
    }

    return orderTypes;
};

dataFlowEventHub.on('assortmentChanged', () =>
    mutate(SWRCacheKeys.vesselOptions, undefined, { revalidate: true }),
);
dataFlowEventHub.on('preloadData', () =>
    mutate(SWRCacheKeys.vesselOptions, undefined, { revalidate: true }),
);

interface VesselOptions {
    availableOrderTypes: OrderTypes[];
    isLoading: boolean;
}

export const useVesselOptions = (): VesselOptions => {
    const { data, error } = useSWR(
        SWRCacheKeys.vesselOptions,
        fetchDbData,
        swrDefaultConfig,
    );

    return {
        availableOrderTypes: data ?? [],
        isLoading: !error && !data,
    };
};
