import { ModalHeader } from '../../ModalHeader/ModalHeader';
import styles from './PerformanceReport.module.css';
import receivalStyles from '../ReceivalModals/Receival.module.css';
import modalStyles from '../../Modal/Modal.module.css';
import StepsProgressBar from '../../StepsProgressBar/StepsProgressBar';
import React, { useEffect, useState } from 'react';
import StarRating from '../../StarRatingComponent/StarRating';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import Button from '../../Button/Button';
import DetailsDataTable from '../../DetailsDataTable/DetailsDataTable';
import {
    PerformanceRating,
    RatingKey,
} from '../../../types/receivedOrder.types';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import useOrderType from '../../../hooks/useOrderType';
import {
    getPerformanceReportQuestion,
    getRatingKeyFriendlyName,
} from '../ReceivalModals/utils';

interface Props {
    ratingKey: RatingKey;
    onContinue: () => void;
    onBack: () => void;
}

const PerformanceReportStarRatingModal: React.FC<Props> = ({
    onBack,
    onContinue,
    ratingKey,
}) => {
    const { orderIdFromQuery } = useOrderType();
    const {
        data: receivedOrder,
        updatePerformanceReportComment,
        isReceivedOrderValidating,
    } = useReceivedOrder(Number(orderIdFromQuery));

    const [automaticNextPage, setAutomaticNextPage] = useState<boolean>(false);
    const setInitialData = () => {
        if (!isReceivedOrderValidating) {
            setSelectedRating(
                receivedOrder?.performanceReport?.ratings?.[ratingKey]?.rating,
            );
        }
    };

    useEffect(() => {
        setInitialData();
    }, [isReceivedOrderValidating]);

    const [selectedRating, setSelectedRating] = useState<
        PerformanceRating | undefined
    >(receivedOrder?.performanceReport?.ratings?.[ratingKey]?.rating);

    const getStepNumber = () => {
        if (ratingKey === RatingKey.qualityFresh) return 3;
        if (ratingKey === RatingKey.qualityDry) return 4;
        if (ratingKey === RatingKey.qualityFrozen) return 5;
        if (ratingKey === RatingKey.qualityPackingMarking) return 6;
        if (ratingKey === RatingKey.overAllPerformance) return 7;
        return 0;
    };

    return (
        <>
            <ModalHeader
                title={
                    <div className={receivalStyles.stepsProgressBar}>
                        <StepsProgressBar
                            currentStep={getStepNumber()}
                            totalStepsLength={10}
                            parentRemounts
                        />
                    </div>
                }
                subTitle={getRatingKeyFriendlyName(ratingKey)}
                description={
                    <div
                        className={`${
                            automaticNextPage
                                ? styles.fadeOutAnimation
                                : styles.fadeInAnimation
                        }`}
                    >
                        {getPerformanceReportQuestion(ratingKey)}
                    </div>
                }
            />
            <div
                className={`${
                    selectedRating && Boolean(selectedRating <= 2)
                        ? styles.smallStarRatingContainer
                        : styles.starRatingContainer
                }
                ${
                    automaticNextPage
                        ? styles.fadeOutAnimation
                        : styles.fadeInAnimation
                }`}
            >
                <StarRating
                    setSelected={setSelectedRating}
                    selected={selectedRating}
                    smaller={
                        selectedRating ? Boolean(selectedRating <= 2) : false
                    }
                    ratingKey={ratingKey}
                    onContinue={onContinue}
                    setAutomaticNextPage={setAutomaticNextPage}
                />
            </div>
            {selectedRating && Boolean(selectedRating <= 2) && (
                <div className={styles.fadeInAnimation}>
                    <DetailsDataTable>
                        <label className={modalStyles.commentInputLabel}>
                            Issue Description
                            <textarea
                                name="performanceReportFreshProvisionCommentInput"
                                className={modalStyles.commentInput}
                                placeholder="We're sorry to hear that it was unsatisfactory. Please help us do better by describing the issue."
                                value={
                                    receivedOrder?.performanceReport?.ratings?.[
                                        ratingKey
                                    ]?.comment
                                }
                                onChange={async (e) => {
                                    await updatePerformanceReportComment(
                                        ratingKey,
                                        e.target.value.toString(),
                                    );
                                }}
                            />
                        </label>
                    </DetailsDataTable>
                </div>
            )}
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onContinue}
                        primary={Boolean(selectedRating)}
                        disabled={Boolean(!selectedRating)}
                    />
                </div>
            </div>
        </>
    );
};

export default PerformanceReportStarRatingModal;
