import { OrderRfqOrigin, OrderStatus } from '../apiClient/generated';
import {
    EditableOrder,
    Order,
    OrderToDisplayInOrdersList,
    Product,
    RFQ,
} from '../types/order.types';
import { OrderTypes } from '../context/OrderTypes';
import { db } from './db';
import { createNewLineItemFromProduct } from './utils/products';
import { getLatestRfq } from './utils/getLatestRfq';
import { dataFlowEventHub } from '../events/dataFlowEvents';
import { v4, validate as uuidValidate } from 'uuid';
import { getVesselMetadata } from '../hooks/useVesselMetadata';
import { adjustEditableOrderToDisplayInOrdersList } from '../components/utils/adjustEditableOrderToDisplayInOrdersList';
import { adjustOrderToDisplayInOrdersList } from '../components/utils/adjustOrderToDisplayInOrdersList';
import { adjustOrderForReviewToEditableOrder } from '../components/utils/adjustOrderForReviewToEditableOrder';

export const resetEditableOrderById = async (orderId: string | number) => {
    if (typeof orderId === 'string' && uuidValidate(orderId)) {
        const editableOrderCollection = await db.editableOrders
            .where('localOrderId')
            .equalsIgnoreCase(orderId);

        const editableOrder = await editableOrderCollection.first();

        await editableOrderCollection.delete();

        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: orderId,
            orderId: editableOrder?.orderId ?? 0,
        });

        if (editableOrder?.orderId) {
            dataFlowEventHub.emit('orderChanged', editableOrder.orderId);
        }
    } else {
        const editableOrderCollection = await db.editableOrders
            .where('orderId')
            .equals(orderId);

        const editableOrder = await editableOrderCollection.first();

        await editableOrderCollection.delete();

        dataFlowEventHub.emit('orderChanged', Number(orderId));
        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: editableOrder?.localOrderId ?? '',
        });
    }
};

export const updateLineItemQuantity = async (
    order: EditableOrder,
    product: Product,
    newAmount: number,
    shouldRemoveZeroQuantityProduct = true,
) => {
    if (!product || isNaN(Number(newAmount))) {
        return;
    }

    const updatedOrder = JSON.parse(JSON.stringify(order)) as EditableOrder;

    const lineItemToUpdate = updatedOrder.rfq.lineItems.find(
        (item) => item.itemNumber === product.itemNumber,
    );

    const addNewLineItemToRfq = () => {
        const newLineItem = createNewLineItemFromProduct(
            product,
            Number(newAmount),
        );
        updatedOrder.rfq.lineItems.push(newLineItem);
    };

    const removeEmptyLineItemFromUpdatedRfq = () => {
        updatedOrder.rfq.lineItems = updatedOrder.rfq.lineItems.filter(
            (item) => item.itemNumber !== product.itemNumber,
        );
    };

    if (newAmount > 0) {
        if (!lineItemToUpdate) {
            addNewLineItemToRfq();
        } else {
            lineItemToUpdate.quantity = newAmount;
        }
    } else {
        const isLineItemConfirmed =
            lineItemToUpdate?.confirmedPrice !== undefined;
        if (shouldRemoveZeroQuantityProduct && !isLineItemConfirmed) {
            removeEmptyLineItemFromUpdatedRfq();
        } else if (lineItemToUpdate) {
            lineItemToUpdate.quantity = 0;
        }
    }

    await db.editableOrders.put(updatedOrder);
    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: order.localOrderId,
        updatedOrder,
        orderId: order.orderId,
    });
};

export const putCommentToLineItemInEditableOrder = async (
    editableOrder: EditableOrder | undefined,
    itemNumber: string,
    comment: string,
) => {
    if (!editableOrder?.rfq) {
        return;
    }

    const updatedOrder = { ...editableOrder };

    const lineItemToUpdate = updatedOrder.rfq.lineItems.find(
        (item) => item.itemNumber === itemNumber,
    );

    if (!lineItemToUpdate) {
        return;
    }

    lineItemToUpdate.comment = comment;

    await db.editableOrders.put(updatedOrder);
    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: updatedOrder.localOrderId,
        updatedOrder: updatedOrder,
        orderId: updatedOrder.orderId,
    });
};

export const putCommentToWholeEditableOrder = async (
    editableOrder: EditableOrder,
    comment: string,
) => {
    if (!editableOrder?.rfq) {
        return;
    }
    const updatedOrder = { ...editableOrder };

    updatedOrder.rfq.comment = comment;

    await db.editableOrders.put(updatedOrder);
    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: updatedOrder.localOrderId,
        updatedOrder: updatedOrder,
        orderId: updatedOrder.orderId,
    });
};

export const recalculateAmountsInEditableOrderByRatio = async (
    type: OrderTypes,
    ratio: number,
    emitDataFlowEvent: boolean,
) => {
    const editableOrder = await getEditableOrderByType(type);
    if (!editableOrder) return;

    const lineItems = [...(editableOrder?.rfq?.lineItems || [])];

    if (lineItems?.length) {
        for (const lineItem of lineItems) {
            lineItem.quantity = lineItem.quantity * ratio;
        }

        await updateEditableOrder(
            editableOrder,
            {
                ...editableOrder,
                rfq: { ...editableOrder.rfq, lineItems },
            },
            emitDataFlowEvent,
        );
    }
};

export const getEditableOrderByType = (
    type: OrderTypes,
): Promise<EditableOrder | undefined> => {
    alert('Using getEditableOrderByType!!');
    return db.editableOrders.where('type').equalsIgnoreCase(type).first();
};

export const getEditableOrderById = async (
    orderIdOrLocalOrderId: string | number,
) => {
    if (uuidValidate(orderIdOrLocalOrderId.toString())) {
        const editableOrderByLocalOrderId = await db.editableOrders
            .where('localOrderId')
            .equalsIgnoreCase(orderIdOrLocalOrderId.toString())
            .first();

        return editableOrderByLocalOrderId;
    } else {
        const editableOrderByOrderId = await db.editableOrders
            .where('orderId')
            .equals(Number(orderIdOrLocalOrderId))
            .first();

        return editableOrderByOrderId;
    }
};

export const getAndUpdateEditableOrders = async (): Promise<
    EditableOrder[] | undefined
> => {
    const orders = await db.orders.toArray();
    const editableOrders = await db.editableOrders.toArray();

    const awaitingActionOrders = orders.filter(
        (order) => order.status === OrderStatus.OrderForReview,
    );

    for (const awaitingOrder of awaitingActionOrders) {
        const hasEditableVersion = editableOrders.find(
            (editableOrder) => editableOrder.orderId === awaitingOrder.orderId,
        );

        if (!hasEditableVersion) {
            await db.editableOrders.put(
                adjustOrderForReviewToEditableOrder(awaitingOrder),
            );
        }
    }

    return db.editableOrders.toArray();
};

export interface LastCommentFromRfqFlow {
    comment: string | undefined | null;
    origin: OrderRfqOrigin | undefined;
    createdAt: Date | undefined;
}

/**
 * Adjusting awaiting action order to display in order list where we need there data from 2 sources - editableOrders table and orders table.
 * @param awaitingActionOrder Awaiting action order from orders table to provide required in Orders List data (f.e. wbPoNo)
 * @param awaitingActionEditableOrder Awaiting action order from editableOrders data to provide proper dynamic data (f.e. orderTotal - user can edit products quantities so data must go from editableOrder).
 */
export const adjustAwaitingActionOrderToDisplayInOrdersList = (
    awaitingActionOrder: Order,
    awaitingActionEditableOrder: EditableOrder,
): OrderToDisplayInOrdersList => {
    const isSame =
        awaitingActionOrder?.orderId === awaitingActionEditableOrder?.orderId;

    if (isSame) {
        return {
            ...adjustEditableOrderToDisplayInOrdersList(
                awaitingActionEditableOrder,
            ),
            wbPono: getLatestRfq(awaitingActionOrder)?.wbPono,
            orderId: awaitingActionOrder?.orderId,
        };
    } else {
        return {
            ...adjustOrderToDisplayInOrdersList(awaitingActionOrder),
            isSending: awaitingActionEditableOrder?.isSending,
        };
    }
};

/**
 * Updates an editable order in IndexedDb and emits a change event
 * @param editableOrder The previous editable order to update
 * @param updated The updated editable order
 * @param emitDataFlowEvent Set this to false, if you know another method in your code is sending the event
 */
export const updateEditableOrder = async (
    editableOrder: EditableOrder,
    updated: Partial<EditableOrder>,
    emitDataFlowEvent = true,
) => {
    await db.editableOrders.update(editableOrder, updated);
    if (emitDataFlowEvent) {
        dataFlowEventHub.emit('editableOrderChanged', {
            localOrderId: editableOrder.localOrderId,
            updatedOrder: editableOrder,
            orderId: editableOrder.orderId,
        });
    }
};

const createInitialEditableOrder = (
    orderType: OrderTypes,
    currency?: string,
) => {
    const editableOrder: EditableOrder = {
        localOrderId: v4(),
        type: orderType,
        status: OrderStatus.Blank,
        rfq: JSON.parse(
            JSON.stringify(
                db.createInitialRfq(currency ?? db.defaults.currency),
            ),
        ),
    };

    return editableOrder;
};

export const createEditableOrderOfTypeWithRfqDetails = async (
    type: OrderTypes,
    initialRfqDetails?: Partial<RFQ>,
) => {
    const { vesselCurrency } = (await getVesselMetadata()) ?? {};
    const initialEditableOrder = createInitialEditableOrder(
        type,
        vesselCurrency ?? undefined,
    );

    const newEditableOrder = {
        ...initialEditableOrder,
        rfq: {
            ...initialEditableOrder.rfq,
            ...initialRfqDetails,
        },
    };

    await db.editableOrders.add(newEditableOrder);

    dataFlowEventHub.emit('editableOrderChanged', {
        localOrderId: newEditableOrder.localOrderId,
        updatedOrder: newEditableOrder,
        orderId: newEditableOrder.orderId,
    });

    return newEditableOrder.localOrderId;
};
