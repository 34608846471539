import styles from './StatusBadge.module.css';

interface StatusBadgeProps {
    backgroundColor?: string;
    color?: string;
    withPadding?: boolean;
}

const StatusBadge: React.FC<React.PropsWithChildren<StatusBadgeProps>> = ({
    children,
    backgroundColor,
    color,
    withPadding,
}) => (
    <div
        className={[styles.chip, withPadding ? styles.withPadding : ''].join(
            ' ',
        )}
        style={{ backgroundColor, color }}
    >
        {children}
    </div>
);

export default StatusBadge;
