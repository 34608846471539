import { dataFlowEventHub } from '../events/dataFlowEvents';
import { apiClient } from '../apiClient/apiClient';
import Dexie, { Table } from 'dexie';
import { EditableOrder, Order, Port, Product, RFQ } from '../types/order.types';
import { onUpgradeTo16 } from './migrations/16';
import { onUpgradeTo17 } from './migrations/17';
import parsePort from './utils/ports';
import { fetchAndUpdateProducts } from './products';
import {
    OrderRfqOrigin,
    AssortmentDto,
    VesselMetadataResponse,
    ManningMetadataDto,
    CashPurchaseDto,
    VesselVrRateDto,
    AvailableClaimItemDto,
    CondemnReportDto,
} from '../apiClient/generated';
import { onUpgradeTo18 } from './migrations/18';
import { onUpgradeTo19 } from './migrations/19';
import { onUpgradeTo22 } from './migrations/22';
import { ReceivedOrder } from '../types/receivedOrder.types';
import { DataVersions } from './dataVersions';
import { fetchAndUpdateAssortments } from './assortments';
import { IExtendedItemIssueDto, ISendingCondemnReport } from './overrideTypes';
import { ManningReport } from '../types/manning.types';
import { StocktakingReport } from '../types/stocktaking.types';
import { fetchInitialStocktakingReports } from '../hooks/useStocktakingReports';
import { onUpgradeTo28 } from './migrations/28';
import { onUpgradeTo29 } from './migrations/29';
import { FeatureAnnouncement } from '../types/featureAnnouncement.types';
import { PendingCashPurchaseOrder } from '../types/cashPurchaseOrders.types';
import { ExternalCurrencyWithRate } from '../types/externalCurrencies.types';
import { NotificationToDisplay } from '../context/NotificationContext';
import { ItemIssueDetails } from '../components/ClaimOrCondemnPreparationModal/ClaimOrCondemnPreparationModal';

export class GenerateDexieClass extends Dexie {
    public products!: Table<Product>;
    public editableOrders!: Table<EditableOrder, number>;
    public orders!: Table<Order, number>;
    public offlineOrders!: Table<EditableOrder, number>;
    public ports!: Table<Port, number>;
    public assortments!: Table<AssortmentDto, number>;
    public notifications!: Table<NotificationToDisplay>;
    public claims!: Table<IExtendedItemIssueDto>;
    public pendingItemIssues!: Table<ItemIssueDetails>;
    public receivedOrders!: Table<ReceivedOrder>;
    public dataVersions!: Table<DataVersions>;
    public vesselMetadata!: Table<VesselMetadataResponse>;
    public manningMetadata!: Table<ManningMetadataDto>;
    public manningReports!: Table<ManningReport>;
    public stocktakingReports!: Table<StocktakingReport>;
    public featureAnnouncements!: Table<FeatureAnnouncement>;
    public pendingCashPurchaseOrders!: Table<PendingCashPurchaseOrder>;
    public cashPurchaseOrders!: Table<CashPurchaseDto>;
    public externalCurrenciesWithRates!: Table<ExternalCurrencyWithRate>;
    public sendingCashPurchaseOrders!: Table<PendingCashPurchaseOrder>;
    public vesselVrRates!: Table<VesselVrRateDto>;
    public itemsAvailableToClaim!: Table<AvailableClaimItemDto>;
    public condemnReports!: Table<CondemnReportDto>;
    public sendingCondemnReports!: Table<ISendingCondemnReport>;
    public claimsInReceival!: Table<IExtendedItemIssueDto>;

    public defaults = {
        manning: 40,
        coveringDays: 30,
        currency: 'USD',
        axOrderLineStringFieldLimit: 254,
        axJournalLineStringFieldLimit: 50,
    };

    constructor() {
        super('db');

        /**
         * # Important note about migrations in dexie.
         * TLDR: version().stores().upgrade() are methods that updates the dexie database schema.
         * you can easily introduce critical (hard to debug) bugs if you don't understand how it works..
         *
         * PLEASE READ THESE DOCS BEFORE YOU ADD OR CHANGE ANYTHING IN CODE BELOW
         * https://dexie.org/docs/Version/Version.stores() - read everything, it's short, well written and turbo important.
         *
         * If you changed anything in db schema, the WAY TO TEST IT is to:
         * 1. checkout to the previous version of the app
         * 2. clear cache and use app (it will create db with the previous schema)
         * 3. checkout to the current version and see if the changes are applied. (clearing cache shouldnt be necessary) (it should be done automatically by dexie upgrade method)
         */
        this.version(16)
            .stores({
                products: 'itemNumber,type,itemName',
                draftOrders: '++localOrderId,orderId,type',
                orders: 'orderId,type,status,totalOrderValue,created',
                offlineOrders: '++localOrderId,orderId,type',
                ports: 'id',
                assortments: '++id',
                notifications: 'notificationId',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(16);
                await onUpgradeTo16(this);
            });
        this.version(17)
            .stores({
                editableOrders: '++localOrderId,orderId,type',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(17);
                await onUpgradeTo17(this);
            });
        this.version(18).upgrade(async () => {
            await this.onAllUpgrades(18);
            await onUpgradeTo18(this);
        });
        this.version(19).upgrade(async () => {
            await this.onAllUpgrades(19);
            await onUpgradeTo19(this);
        });
        this.version(20)
            .stores({
                claims: '++id',
                pendingClaims: '++localId',
            })
            .upgrade(async () => await this.onAllUpgrades(20));
        this.version(21)
            .stores({
                receivedOrders: 'orderId',
            })
            .upgrade(async () => await this.onAllUpgrades(21));
        this.version(22)
            .stores({
                pendingClaims: '++localId,images,syncId',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(22);
                await onUpgradeTo22(this);
            });
        this.version(23)
            .stores({
                dataVersions: '&area',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(23);
            });
        this.version(24)
            .stores({
                vesselMetadata: '++id',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(24);
            });
        this.version(25)
            .stores({
                orders: 'orderId,type,status,totalOrderValue,created,lastModified',
            })
            .upgrade(async () => {
                await this.onAllUpgrades(25);
            });
        this.version(26).stores({
            manningMetadata: '++id',
            manningReports: 'monthAndYear,localId',
        });
        this.version(27).stores({
            stocktakingReports: 'reportMonth,localId',
        });
        this.version(28)
            .stores({
                stocktakingReports: 'reportMonth,localId,state',
            })
            .upgrade(async () => {
                //Changed the order of execution because migration to version 28 requires products loaded in DB
                await onUpgradeTo28(this);
                await this.onAllUpgrades(28);
            });
        this.version(29).upgrade(async () => {
            await onUpgradeTo29(this);
        });
        this.version(30).stores({
            featureAnnouncements: 'id,dismissed',
        });
        this.version(31).stores({
            pendingCashPurchaseOrders: '++localId',
            cashPurchaseOrders: 'id',
        });
        this.version(32).stores({
            externalCurrenciesWithRates: 'code',
        });
        this.version(33).stores({
            sendingCashPurchaseOrders: 'localId',
        });
        this.version(34).stores({
            vesselVrRates: '++id',
        });
        this.version(35).stores({
            itemsAvailableToClaim: 'itemNumber',
        });
        this.version(36).stores({
            pendingItemIssues: '++localId,images,syncId',
            condemnReports: 'id',
        });
        this.version(37).stores({
            sendingCondemnReports: '++id',
            claims: '++id, localId',
        });
        this.version(38).stores({
            claimsInReceival: '++id,localId',
        });

        this.on('ready', this.populate);
    }

    protected populate = async () => {
        const hasPortsInDb = await this.ports.count((count) => count > 0);

        await fetchAndUpdateProducts();

        if (!hasPortsInDb) {
            try {
                const portsResponse = await apiClient.getPorts();

                const ports: Port[] = portsResponse.map((port) => {
                    const { portNameWithCode, portNameWithCodeAndCountry } =
                        parsePort(port.name, port.code);

                    return {
                        id: port.id,
                        portName: port.name,
                        portCode: port.code,
                        portNameWithCode,
                        portNameWithCodeAndCountry,
                    };
                });

                await db.ports.bulkAdd(ports);
                dataFlowEventHub.emit('portsChanged');
            } catch (error) {
                console.error(error);
            }
        }

        await fetchAndUpdateAssortments();
        await fetchInitialStocktakingReports();
    };

    protected onAllUpgrades = async (version: number) => {
        await this.products.clear();
        await this.ports.clear();
        await this.assortments.clear();
        await this.orders.clear();
        await this.notifications.clear();
        if (version >= 20) {
            await this.claims.clear();
        }
        if (version >= 23) {
            await this.dataVersions.clear();
        }
    };

    public setDefaultsForVesselBasedOnMetadata = ({
        standardManning,
    }: {
        standardManning: number;
    }) => {
        this.defaults.manning = standardManning;
    };

    public createInitialRfq = (currency: string) => {
        const rfq: RFQ = {
            deliveryPort: {
                portName: '',
                portCode: '',
            },
            nextPort: {
                portName: '',
                portCode: '',
            },
            coveringDays: this.defaults.coveringDays,
            manning: this.defaults.manning,
            deliveryDate: new Date(),
            id: 0,
            contractCurrency: currency,
            lineItems: [],
            origin: OrderRfqOrigin.App,
            created: new Date(),
            isSsPoNumberAvailableForOrder: false,
        };
        return rfq;
    };
}

export const db = new GenerateDexieClass();
