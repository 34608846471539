import React, {
    createContext,
    ReactElement,
    useContext,
    useState,
} from 'react';
import { OrderPreparationModal } from '../components/OrderPreparationModal/OrderPreparationModal';
import { RemovedLineItemsChangelogModal } from '../components/RemovedLineItemsChangelogModal/RemovedLineItemsChangelogModal';
import { useRemovedLineItemsChangelogModal } from '../hooks/useRemovedLineItemsChangelogModal';
import { OrderTypes } from './OrderTypes';
import OrderOptionsModal from '../components/OrderOptionsModal/OrderOptionsModal';
import { CashOrderPreparationModal } from '../components/CashOrderOptionsModal/CashOrderPreparationModal';
import { SaveDraftModal } from '../components/SaveDraftModal/SaveDraftModal';
import { CashPurchasePreparationModalStep } from '../components/CashOrderOptionsModal/useStepsNavigator';
import {
    ErrorInfoModal,
    ErrorInfoModalDetails,
} from '../components/ErrorComponents/ErrorInfoModal';

export enum ModalType {
    orderPreparation = 'order-preparation',
    orderDetails = 'order-details',
    cashOrderPreparation = 'cash-order-preparation',
    saveDraft = 'save-draft',
    claimValidationModal = 'claim-validation-modal',
}

interface OpenModalProps {
    modalType: ModalType;
    orderType?: OrderTypes;
    orderId?: string;
    shouldRecalculateAmount?: boolean;
    cashOrderPreparationDetailsEditMode?: boolean;
    cashOrderPreparationStep?: CashPurchasePreparationModalStep;
    claimValidationModalDetails?: ErrorInfoModalDetails;
}

interface ModalContextValues {
    openGlobalModal: (modalProps: OpenModalProps) => void;
    closeGlobalModal: () => void;
}

const ModalContext = createContext<ModalContextValues>({
    openGlobalModal: () => {},
    closeGlobalModal: () => {},
});

type ModalProviderProps = {
    children: React.ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modal, setModal] = useState<ReactElement | undefined>();

    const closeGlobalModal = () => {
        setIsModalOpen(false);
        setModal(undefined);
    };

    const {
        removedLineItemsChangelogModalMetadata,
        handleCloseRemovedLineItemsChangelogModal,
        openRemovedLineItemsChangelogModal,
    } = useRemovedLineItemsChangelogModal();

    const openGlobalModal = (modalProps: OpenModalProps) => {
        const modal = () => {
            switch (modalProps.modalType) {
                case ModalType.orderPreparation:
                    return (
                        <OrderPreparationModal
                            orderType={modalProps.orderType}
                            isOpen
                            setIsOpen={setIsModalOpen}
                            openRemovedLineItemsChangelogModal={
                                openRemovedLineItemsChangelogModal
                            }
                        />
                    );
                case ModalType.orderDetails:
                    return (
                        <OrderOptionsModal
                            modal={{
                                isOpen: true,
                                shouldRecalculateAmount:
                                    modalProps.shouldRecalculateAmount,
                                orderIdToEdit: modalProps.orderId,
                            }}
                            closeModal={closeGlobalModal}
                        />
                    );
                case ModalType.cashOrderPreparation:
                    return (
                        <CashOrderPreparationModal
                            isOpen
                            setIsOpen={setIsModalOpen}
                            openRemovedLineItemsChangelogModal={
                                openRemovedLineItemsChangelogModal
                            }
                            detailsEditMode={
                                modalProps.cashOrderPreparationDetailsEditMode
                            }
                            startingPoint={modalProps.cashOrderPreparationStep}
                        />
                    );
                case ModalType.saveDraft:
                    return (
                        <SaveDraftModal
                            isOpen
                            setIsOpen={setIsModalOpen}
                            orderToSaveId={modalProps.orderId ?? ''}
                        />
                    );
                case ModalType.claimValidationModal:
                    if (modalProps.claimValidationModalDetails)
                        return (
                            <ErrorInfoModal
                                isOpen
                                setIsOpen={setIsModalOpen}
                                modalDetails={
                                    modalProps.claimValidationModalDetails
                                }
                            />
                        );
            }
        };

        setModal(modal());
        setIsModalOpen(true);
    };

    return (
        <ModalContext.Provider
            value={{
                openGlobalModal,
                closeGlobalModal,
            }}
        >
            {isModalOpen ? modal : <></>}
            {removedLineItemsChangelogModalMetadata && (
                <>
                    <div>
                        <RemovedLineItemsChangelogModal
                            removedLineItemsChangelogModalMetadata={
                                removedLineItemsChangelogModalMetadata
                            }
                            handleCloseRemovedLineItemsChangelogModal={
                                handleCloseRemovedLineItemsChangelogModal
                            }
                        />
                    </div>
                    <div>
                        {/* todo: add <RemovedLineItemsChangelogModal/> */}
                    </div>
                </>
            )}
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    return useContext(ModalContext);
};
