import styles from './SaveDraftModal.module.css';
import Modal from '../Modal/Modal';
import React, { SetStateAction, useState } from 'react';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import Input from '../Input/Input';
import { useEditableOrder } from '../../hooks/useEditableOrder';
import modalStyles from '../Modal/Modal.module.css';
import Button from '../Button/Button';
import { FlexSpacer } from '../FlexSpacer/FlexSpacer';
import { useRouter } from 'next/router';
import { OverviewTabType } from '../OrdersOverviewComponents/OrdersOverviewTabs/OverviewTab';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    orderToSaveId: string;
}

export const SaveDraftModal: React.FC<Props> = ({
    orderToSaveId,
    isOpen,
    setIsOpen,
}) => {
    const { data: order, saveDraft } = useEditableOrder(orderToSaveId);
    const [draftName, setDraftName] = useState<string>(
        order?.draftName ?? `${order?.rfq?.deliveryPort?.portName} #DRAFT`,
    );

    const { push } = useRouter();

    const changeDraftNameHandler = (value: string) => {
        setDraftName(value);
    };

    const onSaveDraft = async () => {
        if (!order) {
            return;
        }

        try {
            await saveDraft(order, draftName);
        } catch (err) {
            console.log(err);
        }

        setIsOpen(false);
        await push(`/order?tab=${OverviewTabType.drafts}`);
    };

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} noHeader>
            <ModalHeader
                subTitle="Name this draft order"
                description="Make it easy to resume your draft with a name that makes sense to you, or use the one we’ve provided."
            ></ModalHeader>
            <div className={styles.container}>
                <h3>Order Name</h3>
                <Input
                    label=""
                    value={draftName}
                    onChangeValue={changeDraftNameHandler}
                    maxLength={150}
                />
                <p>{draftName.length}/150 characters</p>
            </div>
            <FlexSpacer />
            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Cancel"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        light
                    />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Save draft" onClick={onSaveDraft} primary />
                </div>
            </div>
        </Modal>
    );
};
