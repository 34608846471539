import { getLineItemsFilteredByAssortment } from '../../components/utils/lineItemUtils';
import { OrderTypes, getAssortment } from '../../context/OrderTypes';
import { LineItem, RFQ } from '../../types/order.types';
import { db } from '../db';
import {
    createEditableOrderOfTypeWithRfqDetails,
    updateEditableOrder,
} from '../editableOrders';
import { getOrder } from '../orders';
import { getEditableOrderById } from '../editableOrders';
import { getVesselMetadata } from '../../hooks/useVesselMetadata';

/**
 * Filters out all products that are not currently in the vessel's assortment.
 * @returns LineItems that are in the assortment.
 */
const getLineItemsAdjustedToVesselsAssortment = async (
    rfq: RFQ,
    orderType: OrderTypes,
): Promise<LineItem[]> => {
    const assortments = await db.assortments.toCollection().first();
    if (!assortments) {
        return [];
    }

    const assortment = getAssortment(assortments, orderType);

    return getLineItemsFilteredByAssortment(rfq.lineItems, assortment);
};

/**
 * When creating a new order, the user specifies manning, then can select the order from which he wants to copy products.
 * In this case, we need to recalculate the number of products to be proportional to the current manning.
 */

export const duplicateOrder = async (
    orderId?: number,
    options?: {
        /**
         * onlyLineItems is used in a case of using completed order as a starting point in createOrderModal,
         * then we don't want to override order details selected during order creation flow.
         */
        onlyLineItems?: boolean;
        localOrderId: string;
    },
) => {
    let localOrderId: string;

    if (!orderId) return;

    const order = await getOrder(orderId);
    if (!order) return;

    const firstRfq = order.rfqs[0];

    if (!firstRfq) {
        return;
    }

    const products = await db.products.toArray();

    const { vesselCurrency } = (await getVesselMetadata()) ?? {};

    // create rfq based on order we are duplicating
    const updatedRfq: RFQ = {
        ...firstRfq,
        id: 0,
        comment: undefined,
        contractCurrency: vesselCurrency ?? 'USD',
        lineItems: [
            ...firstRfq.lineItems.map((lineItem) => ({
                ...lineItem,
                quantity: Number(lineItem.quantity.toFixed(2)),
                estimatedPrice: products.find(
                    (item) => item.itemNumber === lineItem.itemNumber,
                )?.estimatedPrice,
                estimatedPackSize: products.find(
                    (item) => item.itemNumber === lineItem.itemNumber,
                )?.estimatedPackSize,
                comment: '',
                lastCommentFromRfqFlow: undefined,
                confirmedPackSize: undefined,
                confirmedPrice: undefined,
            })),
        ],
    };

    const filteredLineItems = await getLineItemsAdjustedToVesselsAssortment(
        updatedRfq,
        order.type,
    );

    if (options?.onlyLineItems) {
        const editableOrder = await getEditableOrderById(options.localOrderId);

        if (!editableOrder) {
            return;
        }

        localOrderId = editableOrder.localOrderId;

        await updateEditableOrder(editableOrder, {
            ...editableOrder,
            rfq: {
                ...editableOrder.rfq,
                departureDate: undefined,
                lineItems: filteredLineItems,
            },
        });
    } else {
        localOrderId = await createEditableOrderOfTypeWithRfqDetails(
            order.type,
            {
                ...updatedRfq,
                departureDate: undefined,
                lineItems: filteredLineItems,
            },
        );
    }

    const originalLineItems = firstRfq.lineItems;
    const filteredLineItemsNumbers = filteredLineItems.map(
        (item) => item.itemNumber,
    );

    const lineItemsOutOfAssortment = originalLineItems.filter(
        (item) => !filteredLineItemsNumbers.includes(item.itemNumber),
    );

    return {
        lineItemsOutOfAssortment,
        localOrderId,
    };
};
