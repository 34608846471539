import { useMemo, useState } from 'react';
import styles from './OrderChangelog.module.css';
import ArrowIcon from '../../public/gfx/uiIcons/collapsedArrow.svg';
import StatusBadge from '../StatusBadge/StatusBadge';
import BackButton from '../BackButton/BackButton';
import { currencyFormatter } from '../utils/numberFormat';
import ChatBubble from '../ChatBubble/ChatBubble';
import { OrderRfqOrigin } from '../../types/order.types';
import { RFQChangelog, RFQLineItemChangelog } from '../../hooks/useChangelog';
import Image from 'next/image';
import { useOrderByLocalOrderIdFromQuery } from '../../hooks/useOrderByLocalOrderId';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

interface Comment {
    origin: OrderRfqOrigin;
    comment: string;
    date: Date;
}

const getNumberOfPropertiesChanged = (change: RFQLineItemChangelog): number => {
    const numberOfChanges = [
        change.priceChange,
        change.amountChange || change.unitOfMeasureChange,
        change.packSizeChange,
    ].filter((item) => item?.isModified).length;

    return numberOfChanges;
};

const getUpdatedBadgeText = (
    lineItemChangelog: RFQLineItemChangelog,
): string => {
    const numberOfChanges = getNumberOfPropertiesChanged(lineItemChangelog);

    return `${numberOfChanges} ${numberOfChanges === 1 ? 'update' : 'updates'}`;
};

const getBadge = (lineItemChanelog: RFQLineItemChangelog) => {
    switch (lineItemChanelog.status) {
        case 'added':
            return <StatusBadge>Added</StatusBadge>;
        case 'updated':
            return (
                <StatusBadge>
                    {getUpdatedBadgeText(lineItemChanelog)}
                </StatusBadge>
            );
        case 'removed':
            return <StatusBadge>Not available</StatusBadge>;
        default:
            return null;
    }
};

const isRowClickable = (change: RFQLineItemChangelog) => {
    return change.status === 'added' || change.status === 'updated';
};

interface OrderChangelogSideModalProps {
    changelog: RFQChangelog;
}

const OrderChangelogSideModal: React.FC<OrderChangelogSideModalProps> = ({
    changelog,
}) => {
    const [page, setPage] = useState<'list' | 'detail' | 'messages'>('list');
    const [change, setChange] = useState<RFQLineItemChangelog>();

    const { order } = useOrderByLocalOrderIdFromQuery();
    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const comments = useMemo(() => {
        const lineComments: Comment[] = [];

        order?.rfqs?.forEach((rfq) => {
            const rfqLineItem = rfq.lineItems.find(
                (item) => item.itemNumber === change?.itemNumber,
            );

            if (rfqLineItem && rfqLineItem.comment?.length) {
                lineComments.push({
                    origin: rfq.origin ?? OrderRfqOrigin.App,
                    comment: rfqLineItem.comment,
                    date: rfq.created ?? new Date(),
                });
            }
        });

        return lineComments.sort(
            (c1, c2) =>
                new Date(c1.date).getTime() - new Date(c2.date).getTime(),
        );
    }, [change]);

    const handleRowClick = (change: RFQLineItemChangelog) => {
        if (change.status === 'updated' || change.status == 'added') {
            setChange(change);
            setPage('detail');
            // @TODO: Scroll modal content to top
        }
    };

    return (
        <>
            {page === 'list' && (
                <>
                    <h2 className={styles.modalTitle}>Change log</h2>
                    {changelog.filteredChangelogForModal.length > 0 ? (
                        <section className={styles.changesList}>
                            <ul>
                                {changelog.filteredChangelogForModal?.map(
                                    (change, index) => (
                                        <li key={index}>
                                            <button
                                                onClick={() =>
                                                    handleRowClick(change)
                                                }
                                                className={
                                                    isRowClickable(change)
                                                        ? styles.clickable
                                                        : ''
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.changeItem
                                                    }
                                                >
                                                    <span>
                                                        {change.itemName}
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.itemNumber
                                                        }
                                                    >
                                                        {change.itemNumber}
                                                    </span>
                                                </div>
                                                {getBadge(change)}
                                                {isRowClickable(change) && (
                                                    <span
                                                        className={
                                                            styles.arrowWrapper
                                                        }
                                                    >
                                                        <ArrowIcon
                                                            className="transitionRegular rotated180"
                                                            width="28"
                                                            height="28"
                                                            stroke="#000"
                                                        />
                                                    </span>
                                                )}
                                            </button>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </section>
                    ) : (
                        <div className={styles.noChangesContainer}>
                            <Image
                                src="/gfx/noChangesPic.svg"
                                width="225"
                                height="148"
                                alt="Fancy image."
                            />
                            <p className={styles.noChangesText}>
                                There are no changes on the order.
                            </p>
                        </div>
                    )}
                </>
            )}
            {page === 'detail' && (
                <>
                    <BackButton
                        onGoBack={() => {
                            setPage('list');
                            // @TODO: Scroll modal content to top
                        }}
                        caption="All"
                    />
                    <h2 className={styles.modalTitle}>{change?.itemName}</h2>
                    <div className={styles.propertyRow}>
                        <p className={styles.label}>Quantity</p>
                        <p className={styles.value}>
                            {change?.amountChange?.currentValue}&thinsp;
                            {change?.unitOfMeasureChange?.currentValue}
                        </p>
                        {change?.status === 'updated' &&
                            change.amountChange?.isModified && (
                                <p
                                    className={`${styles.value} ${styles.changed}`}
                                >
                                    {change?.amountChange?.previousValue}
                                    &thinsp;
                                    {change?.unitOfMeasureChange?.previousValue}
                                </p>
                            )}
                    </div>
                    <div className={styles.propertyRow}>
                        <p className={styles.label}>Pack size</p>
                        <p className={styles.value}>
                            {change?.packSizeChange?.currentValue}
                            &thinsp;
                            {change?.unitOfMeasureChange?.currentValue}
                        </p>
                        {change?.status === 'updated' &&
                            change?.packSizeChange?.isModified && (
                                <p
                                    className={`${styles.value} ${styles.changed}`}
                                >
                                    {change?.packSizeChange?.previousValue}
                                    &thinsp;
                                    {change?.unitOfMeasureChange?.previousValue}
                                </p>
                            )}
                    </div>
                    <div className={styles.propertyRow}>
                        <p className={styles.label}>Price</p>
                        <p className={styles.value}>
                            {currencyFormatter(
                                change?.priceChange?.currentValue ?? 0,
                                vesselCurrency,
                            )}
                        </p>
                        {change?.status === 'updated' &&
                            change?.priceChange?.isModified && (
                                <p
                                    className={`${styles.value} ${styles.changed}`}
                                >
                                    {currencyFormatter(
                                        change?.priceChange?.previousValue ?? 0,
                                        vesselCurrency,
                                    )}
                                </p>
                            )}
                    </div>
                    {comments.length > 0 && (
                        <div className={styles.messages}>
                            <h3>
                                Message thread
                                <button
                                    className={styles.seeAllBtn}
                                    onClick={() => {
                                        setPage('messages');
                                        // @TODO: Scroll modal content to top
                                    }}
                                >
                                    See all ({comments.length})
                                </button>
                            </h3>
                            {comments.length && (
                                <ChatBubble
                                    createdAt={
                                        comments[comments.length - 1].date
                                    }
                                    message={
                                        comments[comments.length - 1].comment
                                    }
                                    sender={{
                                        displayName:
                                            comments[comments.length - 1]
                                                .origin === OrderRfqOrigin.App
                                                ? 'You'
                                                : 'Garrets',
                                        avatarUrl:
                                            comments[comments.length - 1]
                                                .origin ===
                                            OrderRfqOrigin.WristIntegration
                                                ? '/gfx/generateLogo.svg'
                                                : '',
                                    }}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
            {page === 'messages' && (
                <>
                    <BackButton
                        onGoBack={() => {
                            setPage('detail');
                            // @TODO: Scroll modal content to top
                        }}
                    />
                    <h2 className={styles.modalTitle}>Message thread</h2>
                    {comments.map((item) => (
                        <ChatBubble
                            key={item.date.toString()}
                            createdAt={item.date}
                            message={item.comment}
                            sender={{
                                displayName:
                                    item.origin === OrderRfqOrigin.App
                                        ? 'You'
                                        : 'Garrets',
                                avatarUrl:
                                    item.origin ===
                                    OrderRfqOrigin.WristIntegration
                                        ? '/gfx/generateLogo.svg'
                                        : '',
                            }}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default OrderChangelogSideModal;
