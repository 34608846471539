import styles from '../OrdersOverviewTabs/OrdersOverviewTabs.module.css';
import { CreateNewOrderButton } from './CreateNewOrderButton';
import React from 'react';
import { ModalType, useModalContext } from '../../../context/ModalContext';
import { useVesselOptions } from '../../../hooks/useVesselOptions';
import { OrderTypes } from '../../../context/OrderTypes';
import { createInitialCashPurchaseOrder } from '../../../db/pendingCashPurchaseOrders';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import { useRouter } from 'next/router';
import { useFeatureFlags } from '../../../context/useFeatureFlags';

export const CreateNewOrderButtons = () => {
    const { openGlobalModal } = useModalContext();
    const { revalidatePendingCashPurchaseOrder } =
        usePendingCashPurchaseOrder();
    const { availableOrderTypes } = useVesselOptions();
    const { push } = useRouter();
    const { featureFlags } = useFeatureFlags();

    const createNewCashPurchaseOrder = async () => {
        await createInitialCashPurchaseOrder();
        await revalidatePendingCashPurchaseOrder();
        await push('/order/cash/preparation');
        openGlobalModal({
            modalType: ModalType.cashOrderPreparation,
        });
    };

    const availableOrderTypesList = featureFlags?.cashPurchase
        ? [...availableOrderTypes, OrderTypes.cash]
        : [...availableOrderTypes];

    return (
        <div className={styles.createNewOrderButtonsContainer}>
            {availableOrderTypesList.map((type, index) => {
                return (
                    <CreateNewOrderButton
                        key={index}
                        onClick={() => {
                            if (type === OrderTypes.cash) {
                                createNewCashPurchaseOrder();
                            } else {
                                openGlobalModal({
                                    modalType: ModalType.orderPreparation,
                                    orderType: type,
                                });
                            }
                        }}
                        orderType={type}
                    />
                );
            })}
        </div>
    );
};
