import React from 'react';
import styles from './MultipleOrdersIntroModal.module.css';
import Modal from '../Modal/Modal';
import Image from 'next/image';
import StatusBadge from '../StatusBadge/StatusBadge';
import Button from '../Button/Button';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onClick: () => void;
}

export const MultipleOrdersIntroModal: React.FC<Props> = ({
    isOpen,
    onClose,
    onClick,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            introModal
            noHeader
            onOutsideOfModalClick={onClose}
        >
            <div className={styles.picContainer}>
                <Image
                    src="/gfx/dashboard/multipleOrdersIntro.svg"
                    layout="fill"
                    objectFit="fill"
                    objectPosition="center"
                    alt=""
                />
            </div>
            <div className={styles.textContainer}>
                <div className={styles.status}>
                    <StatusBadge color="#3B6CE9" backgroundColor="#EAF0FE">
                        New Update!
                    </StatusBadge>
                </div>
                <h2>Introducing: Multiple orders!</h2>
                <p>
                    You can now work on and submit multiple orders at once with
                    the new and improved Orders section.
                </p>
            </div>
            <Button
                data-testid="featureAnnouncement"
                onClick={onClick}
                text="Show me!"
                primary
            />
        </Modal>
    );
};
