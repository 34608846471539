import React from 'react';
import { ModalType, useModalContext } from '../../context/ModalContext';
import AddIcon from '../../public/gfx/uiIcons/add.svg';
import Button from '../Button/Button';

interface Props {
    blue?: boolean;
    transparent?: boolean;
}

export const NewOrderButton: React.FC<Props> = ({ blue, transparent }) => {
    const { openGlobalModal } = useModalContext();

    const iconColor = [blue ? '#fff' : '', transparent ? 'black' : ''].join(
        ' ',
    );

    return (
        <Button
            onClick={() => {
                openGlobalModal({
                    modalType: ModalType.orderPreparation,
                });
            }}
            text="New order"
            smallRounded
            blueCta={blue}
            transparentBlack={transparent}
            leftIcon={<AddIcon width="12" height="12" stroke={iconColor} />}
        />
    );
};
