import { CashPurchasePhotoDto } from '../../apiClient/generated';
import Modal from '../Modal/Modal';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { isNotCashPurchaseDtoPhoto, isNotPdfFile } from './FilesSection';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowRightIcon from '../../public/gfx/uiIcons/goBackArrowIconGrey.svg';
import styles from './FilesSection.module.css';
import { useState } from 'react';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import {
    compressedImageMaxHeightDefault,
    compressedImageMaxWidthDefault,
} from '../ClaimOrCondemnPreparationModal/utils/compressionHelper';

interface FilesModalProps {
    selectedFile: File | CashPurchasePhotoDto | null;
    onClose: () => void;
    pdfLoading: boolean;
}

interface PdfDocumentProps {
    pdfLoading: boolean;
    selectedFile: FilesModalProps['selectedFile'];
    getPdf: () => string | File | undefined;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const PdfDocument: React.FC<PdfDocumentProps> = ({
    pdfLoading,
    selectedFile,
    getPdf,
}) => {
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const onDocumentLoad = ({ numPages }: { numPages: number }): void => {
        setNumberOfPages(numPages);
        setPageNumber(1);
    };

    const handleNextPage = () => {
        setPageNumber((prev) => {
            return prev < numberOfPages ? prev + 1 : 1;
        });
    };

    const handlePrevPage = () => {
        setPageNumber((prev) => {
            return prev > 1 ? prev - 1 : numberOfPages;
        });
    };

    if (pdfLoading) {
        return <LoadingSpinner />;
    } else {
        return (
            selectedFile && (
                <Document
                    options={options}
                    file={getPdf()}
                    onLoadSuccess={onDocumentLoad}
                    loading={<LoadingSpinner />}
                    error={
                        'There was an error while loading the PDF, if you are offline please try again later.'
                    }
                >
                    <Page
                        loading={<LoadingSpinner />}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        pageNumber={pageNumber}
                        width={400}
                        canvasBackground="white"
                        height={500}
                    />
                    {numberOfPages > 1 && (
                        <div className={styles.pdfController}>
                            <div
                                onClick={handlePrevPage}
                                className={styles.pdfArrowsClickArea}
                            >
                                <ArrowRightIcon
                                    width={18}
                                    height={18}
                                    stroke="white"
                                />
                            </div>
                            Page {pageNumber} / {numberOfPages}
                            <div
                                className={styles.pdfArrowsClickArea}
                                onClick={handleNextPage}
                            >
                                <ArrowRightIcon
                                    width={18}
                                    height={18}
                                    className="rotated180"
                                    stroke="white"
                                />
                            </div>
                        </div>
                    )}
                </Document>
            )
        );
    }
};

const FilePreviewModal: React.FC<FilesModalProps> = ({
    selectedFile,
    onClose,
    pdfLoading,
}) => {
    const selectedIsFile =
        selectedFile && isNotCashPurchaseDtoPhoto(selectedFile);

    const getImg = () => {
        if (selectedIsFile) {
            return URL.createObjectURL(selectedFile);
        }
        return selectedFile?.url;
    };

    const getPdf = () => {
        if (selectedIsFile) {
            return selectedFile;
        }
        return selectedFile?.url;
    };

    return (
        selectedFile && (
            <Modal noHeader isOpen={Boolean(selectedFile)} onClose={onClose}>
                <ModalHeader
                    title={`${(selectedFile.size / 1024 / 1024)
                        .toFixed(2)
                        .toString()} MB`}
                    subTitle={selectedFile.name}
                />
                <div className={styles.fileContainer}>
                    {isNotPdfFile(selectedFile) ? (
                        <img
                            style={{
                                maxWidth: compressedImageMaxWidthDefault,
                                maxHeight: compressedImageMaxHeightDefault,
                            }}
                            src={getImg()}
                            alt=""
                        />
                    ) : (
                        <PdfDocument
                            selectedFile={selectedFile}
                            pdfLoading={pdfLoading}
                            getPdf={getPdf}
                        />
                    )}
                </div>
            </Modal>
        )
    );
};

export default FilePreviewModal;
