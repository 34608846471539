import Image from 'next/image';
import { useMemo, useState } from 'react';
import { OrderToDisplay, Product } from '../../types/order.types';
import { ProductCategory } from './ProductsList';
import styles from './ProductsList.module.css';
import SingleProductRow, {
    ProductRowChangelogStatus,
} from './SingleProductRow';
import ArrowIcon from '../../public/gfx/uiIcons/collapsedArrow.svg';
import { getCorrectIcon } from './utils/icons';
import { sumElements } from '../utils/arrayMethods';
import { getLineItemsWithProductDetails } from '../utils/orderSummary';
import { currencyFormatter } from '../utils/numberFormat';
import { useAppLoading } from '../../context/AppLoadingContext';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import ReceivedProductRow from './ReceivedProductRow';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';

interface ProductCategoryRowProps {
    orderToDisplay: OrderToDisplay;
    category: ProductCategory<Product>;
    compact?: boolean;
    getChangelogStatus?: (product: Product) => ProductRowChangelogStatus;
    withIcon?: boolean;
    withLegend?: boolean;
    expandAllCategories?: boolean;
}

const ProductCategoryRow: React.FC<ProductCategoryRowProps> = ({
    category,
    compact,
    getChangelogStatus,
    withIcon,
    orderToDisplay,
    withLegend,
    expandAllCategories,
}) => {
    const { categoryName, products } = category;
    const { loadingPrices } = useAppLoading();
    const { isReceived } = parseOrderStatus(orderToDisplay);

    const lineItemsWithProductDetails = getLineItemsWithProductDetails(
        orderToDisplay,
        products,
    );

    const {
        data: { vesselCurrency },
    } = useVesselMetadata();

    const sortedProducts = useMemo(
        () =>
            products.sort((a, b) =>
                a.itemName > b.itemName ? 1 : b.itemName > a.itemName ? -1 : 0,
            ),
        [products],
    );
    const lineItems = orderToDisplay.rfq.lineItems;

    const getTotalPrice = () => {
        let includesEstimates = false;

        const totalPrice = lineItemsWithProductDetails.map((item) => {
            if (!item.lineItem.confirmedPrice) {
                includesEstimates = true;
            }
            const correctPrice =
                item.lineItem.confirmedPrice ||
                item.lineItem.estimatedPrice ||
                item.product.estimatedPrice ||
                0;

            return correctPrice * item.lineItem.quantity;
        });

        const categoryTotalPrice = sumElements(totalPrice);

        if (!categoryTotalPrice) {
            return '-';
        }

        return `${includesEstimates ? '≈' : ''} ${currencyFormatter(
            categoryTotalPrice,
            vesselCurrency,
        )}`;
    };

    const getTotalAmount = (): number | string => {
        const unitOfMeasureToCount = 'kg';

        const amountPerProductArr: number[] = lineItemsWithProductDetails.map(
            (item) =>
                item.lineItem.unitOfMeasure?.toLowerCase() ===
                unitOfMeasureToCount
                    ? item.lineItem.quantity
                    : 0,
        );

        const totalAmount: number = sumElements(amountPerProductArr);
        if (!totalAmount) {
            return '-';
        }

        return `${totalAmount.toFixed(2)} Kg`;
    };

    const [isProductListExpanded, setIsProductsListExpanded] = useState(false);

    const handleToggleProductsVisibility = () => {
        setIsProductsListExpanded((prev: boolean) => !prev);
    };

    const expanded = isProductListExpanded || expandAllCategories;

    const icon = getCorrectIcon(categoryName, Boolean(withIcon));

    return (
        <>
            {compact && withLegend && (
                <h4 className={styles.categoryTitle}>
                    {categoryName}
                    <p className={styles.changedProductsInfo}>
                        <span className={styles.changedProductsInfoDot}></span>{' '}
                        Changed products
                    </p>
                </h4>
            )}
            {compact && !withLegend && (
                <h4 className={styles.categoryTitle}>{categoryName}</h4>
            )}
            {!compact && (
                <div
                    className={`${styles.productCategoryRow} ${
                        withIcon ? styles.withIcon : ''
                    }`}
                    onClick={handleToggleProductsVisibility}
                    data-testid="productsListCategoryRow"
                >
                    <div className={styles.tableCell}>
                        {icon ? (
                            <Image
                                src={icon}
                                width={30}
                                height={30}
                                alt={`${categoryName} icon`}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={styles.tableCell}>
                        <p>{categoryName}</p>
                    </div>
                    <div className={styles.tableCell} />
                    <div className={styles.tableCell} />
                    <div className={styles.tableCell}>
                        <p>{getTotalAmount()}</p>
                    </div>
                    <div className={styles.tableCell}>
                        {loadingPrices ? (
                            <SkeletonLoader loaderWidth={70} />
                        ) : (
                            getTotalPrice()
                        )}
                    </div>
                    <div className={styles.tableCell}>
                        <ArrowIcon
                            className={`transitionRegular ${
                                expanded ? 'rotated270' : 'rotated180'
                            }`}
                            width="24"
                            height="24"
                            stroke="#000"
                        />
                    </div>
                </div>
            )}
            <div
                className={
                    compact
                        ? `${styles.expandedProductsList} ${styles.compact}`
                        : expanded
                        ? styles.expandedProductsList
                        : ''
                }
            >
                {(expanded || compact) &&
                    sortedProducts.map((item, index) =>
                        isReceived ? (
                            <ReceivedProductRow
                                key={index}
                                orderToDisplay={orderToDisplay}
                                product={item}
                                lineItem={orderToDisplay.receival?.items.find(
                                    (lineItem) =>
                                        lineItem.itemNumber === item.itemNumber,
                                )}
                            />
                        ) : (
                            <SingleProductRow
                                key={index}
                                orderToDisplay={orderToDisplay}
                                product={item}
                                lineItem={lineItems.find(
                                    (lineItem) =>
                                        lineItem.itemNumber === item.itemNumber,
                                )}
                                status={getChangelogStatus?.(item)}
                                compact={compact}
                            />
                        ),
                    )}
            </div>
        </>
    );
};

export default ProductCategoryRow;
