/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssortmentDto } from '../models/AssortmentDto';
import type { AvailableClaimItemDto } from '../models/AvailableClaimItemDto';
import type { CashPurchaseDto } from '../models/CashPurchaseDto';
import type { CondemnReportDto } from '../models/CondemnReportDto';
import type { CreateCashPurchase } from '../models/CreateCashPurchase';
import type { CreateCondemnReportDto } from '../models/CreateCondemnReportDto';
import type { CreatedOrderDto } from '../models/CreatedOrderDto';
import type { CreateReceivalCommand } from '../models/CreateReceivalCommand';
import type { ExternalCurrency } from '../models/ExternalCurrency';
import type { ExternalCurrencyExchange } from '../models/ExternalCurrencyExchange';
import type { GetManningReportsQueryResponse } from '../models/GetManningReportsQueryResponse';
import type { GetProductItemsResponse } from '../models/GetProductItemsResponse';
import type { IncomingItemIssueDto } from '../models/IncomingItemIssueDto';
import type { IncomingOrderDto } from '../models/IncomingOrderDto';
import type { InitialStocktakingItemDto } from '../models/InitialStocktakingItemDto';
import type { ItemIssueDto } from '../models/ItemIssueDto';
import type { ManningMetadataDto } from '../models/ManningMetadataDto';
import type { ManningReportDto } from '../models/ManningReportDto';
import type { MarketDto } from '../models/MarketDto';
import type { NotificationDto } from '../models/NotificationDto';
import type { NotificationDtoV2 } from '../models/NotificationDtoV2';
import type { OrderDto } from '../models/OrderDto';
import type { OrderSummaryDto } from '../models/OrderSummaryDto';
import type { PortDto } from '../models/PortDto';
import type { ReOpenDto } from '../models/ReOpenDto';
import type { StocktakingDto } from '../models/StocktakingDto';
import type { StocktakingReportRequest } from '../models/StocktakingReportRequest';
import type { VesselMetadataResponse } from '../models/VesselMetadataResponse';
import type { VesselVrRateDto } from '../models/VesselVrRateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GenerateAppScope {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets user's vessel assortment for provision and bonded orders
     * Requires user privileges
     * @returns AssortmentDto Success
     * @throws ApiError
     */
    public getAssortments(): CancelablePromise<AssortmentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Assortments',
        });
    }

    /**
     * Gets user's all cash purchases
     * Requires user privileges
     * @returns CashPurchaseDto Success
     * @throws ApiError
     */
    public getCashPurchases(): CancelablePromise<Array<CashPurchaseDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/CashPurchase',
        });
    }

    /**
     * Creates new cash purchase
     * Requires user privileges
     * @param requestBody 
     * @returns CashPurchaseDto Success
     * @throws ApiError
     */
    public createCashPurchase(
requestBody?: CreateCashPurchase,
): CancelablePromise<CashPurchaseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/CashPurchase',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets specified cash purchase
     * Requires user privileges
     * @param cashPurchaseId 
     * @returns CashPurchaseDto Success
     * @throws ApiError
     */
    public getCashPurchase(
cashPurchaseId: number,
): CancelablePromise<CashPurchaseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/CashPurchase/{cashPurchaseId}',
            path: {
                'cashPurchaseId': cashPurchaseId,
            },
        });
    }

    /**
     * Fetches cash purchase photo jpeg
     * Requires user privileges
     * @param photoId 
     * @returns any Success
     * @throws ApiError
     */
    public getCashPurchasePhoto(
photoId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/CashPurchase/{photoId}/photo',
            path: {
                'photoId': photoId,
            },
        });
    }

    /**
     * Upload cash purchase for item issue
     * Requires user privileges
     * @param clientId 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public attachCashPurchasePhoto(
clientId: string,
formData?: {
file: Blob;
filename?: string;
},
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/CashPurchase/{clientId}/photo',
            path: {
                'clientId': clientId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Creates or updates condemn report. If report already exist, the items are appended to the report.
     * Requires user privileges
     * @param requestBody 
     * @returns CondemnReportDto Success
     * @throws ApiError
     */
    public createOrUpdateCondemnReport(
requestBody?: CreateCondemnReportDto,
): CancelablePromise<CondemnReportDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Condemn',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets user's condemn reports
     * Requires user privileges
     * @returns CondemnReportDto Success
     * @throws ApiError
     */
    public getCondemnReports(): CancelablePromise<Array<CondemnReportDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Condemn',
        });
    }

    /**
     * Gets supported currencies.
     * Requires user privileges
     * @returns ExternalCurrency Success
     * @throws ApiError
     */
    public getCurrencies(): CancelablePromise<Array<ExternalCurrency>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Currency',
        });
    }

    /**
     * Gets historical exchange rates from base currency to all supported currencies. If the date is today's date, the endpoint will return latest exchange rates.
     * Requires user privileges
     * @param date 
     * @param baseCurrencyCode 
     * @returns ExternalCurrencyExchange Success
     * @throws ApiError
     */
    public getExchangeRates(
date?: string,
baseCurrencyCode?: string,
): CancelablePromise<Array<ExternalCurrencyExchange>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Currency/rates',
            query: {
                'date': date,
                'baseCurrencyCode': baseCurrencyCode,
            },
        });
    }

    /**
     * Create new item issue
     * Requires user privileges
     * @param requestBody 
     * @returns ItemIssueDto Success
     * @throws ApiError
     */
    public createItemIssue(
requestBody?: IncomingItemIssueDto,
): CancelablePromise<ItemIssueDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ItemIssue',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Get item issues
     * Requires user privileges
     * @returns ItemIssueDto Success
     * @throws ApiError
     */
    public getItemIssues(): CancelablePromise<Array<ItemIssueDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ItemIssue',
        });
    }

    /**
     * Get item issue by id
     * Requires user privileges
     * @param itemIssueId 
     * @returns ItemIssueDto Success
     * @throws ApiError
     */
    public getItemIssuesById(
itemIssueId: number,
): CancelablePromise<ItemIssueDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ItemIssue/{itemIssueId}',
            path: {
                'itemIssueId': itemIssueId,
            },
        });
    }

    /**
     * Fetches item issue photo jpeg
     * Requires user privileges
     * @param photoId 
     * @returns any Success
     * @throws ApiError
     */
    public getItemIssuePhoto(
photoId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ItemIssue/{photoId}/photo',
            path: {
                'photoId': photoId,
            },
        });
    }

    /**
     * Upload photo for item issue
     * Requires user privileges
     * @param clientId 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public attachItemIssuePhoto(
clientId: string,
formData?: {
file: Blob;
filename?: string;
},
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ItemIssue/{clientId}/photo',
            path: {
                'clientId': clientId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get available claim items for the vessel
     * Requires user privileges
     * @param lastMonthsCount 
     * @returns AvailableClaimItemDto Success
     * @throws ApiError
     */
    public getAvailableClaimItems(
lastMonthsCount?: number,
): CancelablePromise<Array<AvailableClaimItemDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ItemIssue/available-claim-items',
            query: {
                'lastMonthsCount': lastMonthsCount,
            },
        });
    }

    /**
     * Clears claim image cookie
     * Requires user privileges
     * @returns any Success
     * @throws ApiError
     */
    public logout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Logout',
        });
    }

    /**
     * Gets manning metadata options
     * Requires user privileges
     * @returns ManningMetadataDto Success
     * @throws ApiError
     */
    public getManningMetadata(): CancelablePromise<ManningMetadataDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Manning/options',
        });
    }

    /**
     * Creates a new manning report and schedules creation in DIP/AX
     * Requires user privileges
     * @param requestBody 
     * @returns ManningReportDto Success
     * @throws ApiError
     */
    public createManningReport(
requestBody?: ManningReportDto,
): CancelablePromise<ManningReportDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Manning',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets all user's manning reports (created in Gateway)
     * Requires user privileges
     * @returns GetManningReportsQueryResponse Success
     * @throws ApiError
     */
    public getManningReports(): CancelablePromise<Array<GetManningReportsQueryResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Manning',
        });
    }

    /**
     * Gets market info by given port
     * Requires user privileges
     * @param portCode 
     * @param lastUpdated 
     * @returns MarketDto Success
     * @throws ApiError
     */
    public getMarketsByPortCode(
portCode: string,
lastUpdated?: string,
): CancelablePromise<Array<MarketDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Markets',
            query: {
                'portCode': portCode,
                'lastUpdated': lastUpdated,
            },
        });
    }

    /**
     * Gets user noitifications
     * Requires user privileges
     * @returns NotificationDto Success
     * @throws ApiError
     */
    public getNotifications(): CancelablePromise<Array<NotificationDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Notifications',
        });
    }

    /**
     * Marks given notification IDs as read
     * Requires user privileges
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public markNotificationsAsRead(
requestBody: Array<number>,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Notifications/mark-as-read',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets user noitifications
     * Requires user privileges
     * @returns NotificationDtoV2 Success
     * @throws ApiError
     */
    public getNotificationsV2(): CancelablePromise<Array<NotificationDtoV2>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/notifications',
        });
    }

    /**
     * Gets user orders
     * Requires user privileges
     * @returns OrderSummaryDto Success
     * @throws ApiError
     */
    public getOrders(): CancelablePromise<Array<OrderSummaryDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Orders',
        });
    }

    /**
     * Creates new order
     * Requires user privileges
     * @param requestBody 
     * @returns CreatedOrderDto Created
     * @throws ApiError
     */
    public createOrder(
requestBody: IncomingOrderDto,
): CancelablePromise<CreatedOrderDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Orders',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Updates existing order
     * Requires user privileges
     * @param orderId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public updateOrder(
orderId: number,
requestBody: IncomingOrderDto,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Orders',
            query: {
                'orderId': orderId,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets order details
     * Requires user privileges
     * @param orderId 
     * @returns OrderDto Success
     * @throws ApiError
     */
    public getOrderById(
orderId: number,
): CancelablePromise<OrderDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Orders/{orderId}',
            path: {
                'orderId': orderId,
            },
        });
    }

    /**
     * Gets available ports
     * Requires user privileges
     * @returns PortDto Success
     * @throws ApiError
     */
    public getPorts(): CancelablePromise<Array<PortDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Ports',
        });
    }

    /**
     * Gets initial or incremental product list based on dataVersion.
 * Look for assortments endpoint to filter products available to the user.
     * Requires user privileges
     * @param dataVersionBigInt 
     * @returns GetProductItemsResponse Success
     * @throws ApiError
     */
    public getProducts(
dataVersionBigInt?: string,
): CancelablePromise<GetProductItemsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ProductItems',
            query: {
                'dataVersionBigInt': dataVersionBigInt,
            },
        });
    }

    /**
     * Creates new receival
     * Requires user privileges
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createReceival(
requestBody?: CreateReceivalCommand,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Receival',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Gets reports to reopen
     * Requires user privileges
     * @returns ReOpenDto Success
     * @throws ApiError
     */
    public getReopens(): CancelablePromise<Array<ReOpenDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ReOpen',
        });
    }

    /**
     * Returns stocktaking reports
     * Requires user privileges
     * @returns StocktakingDto Success
     * @throws ApiError
     */
    public getStocktakingReports(): CancelablePromise<Array<StocktakingDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Stocktaking',
        });
    }

    /**
     * Creates a new stocktaking report and schedules creation in DIP/AX
     * Requires user privileges
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public createStocktakingReport(
requestBody?: StocktakingReportRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Stocktaking',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * Returns stocktaking initial data for report month
     * Requires user privileges
     * @param reportMonth 
     * @returns InitialStocktakingItemDto Success
     * @throws ApiError
     */
    public getInitialStocktakingData(
reportMonth: string,
): CancelablePromise<Array<InitialStocktakingItemDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Stocktaking/initial/{reportMonth}',
            path: {
                'reportMonth': reportMonth,
            },
        });
    }

    /**
     * Returns vessel metadata
     * Requires user privileges
     * @returns VesselMetadataResponse Success
     * @throws ApiError
     */
    public getVesselMetadata(): CancelablePromise<VesselMetadataResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/VesselMetadata',
        });
    }

    /**
     * Gets vessel vr rate trend data for provided month string and vessel company
     * Requires user privileges
     * @param vesselCompany 
     * @param reportMonth 
     * @returns VesselVrRateDto Success
     * @throws ApiError
     */
    public getVesselVr(
vesselCompany?: string,
reportMonth?: string,
): CancelablePromise<VesselVrRateDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/VesselVrRate',
            query: {
                'vesselCompany': vesselCompany,
                'reportMonth': reportMonth,
            },
        });
    }

}