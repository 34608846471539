import styles from './StarsRatingReadOnly.module.css';
import StarIcon from '../../public/gfx/uiIcons/starRating.svg';
import ChatBubble from '../../public/gfx/uiIcons/chatBubble.svg';
import Tooltip from '../Tooltips/Tooltip';

interface StarsRatingReadOnlyProps {
    rating: number;
    comment?: string;
}

export const StarsRatingReadOnly: React.FC<StarsRatingReadOnlyProps> = ({
    rating,
    comment,
}) => {
    return (
        <div
            className={styles.container}
            aria-label={`${rating} stars rated`}
            role="figure"
        >
            {[1, 2, 3, 4, 5].map((item) => (
                <StarIcon
                    key={item}
                    fill={item <= rating ? '#276ef1' : '#f4f5f6'}
                    width={26}
                    height={24}
                />
            ))}
            <div className={styles.commentBubbleContainer}>
                {comment && (
                    <Tooltip tooltipMessage={comment}>
                        <ChatBubble />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
