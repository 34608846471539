import { useRouter } from 'next/router';
import { OrderTypes } from '../context/OrderTypes';
import { validate as uuidValidate } from 'uuid';

const queryOrderTypeMap: Record<string, OrderTypes> = {
    ['provision']: OrderTypes.provision,
    ['bonded']: OrderTypes.bonded,
    ['nonfood']: OrderTypes.nonFood,
};

const useOrderType = () => {
    const { query } = useRouter();

    const isDraft = query?.orderId === 'draft';

    const queryOrderType =
        query?.orderType?.toString()?.toLowerCase() ??
        OrderTypes.provision.toLowerCase();
    const activeOrderType = queryOrderTypeMap[queryOrderType];

    const getOrderIdFromQuery = () => {
        if (!query.orderId) {
            return undefined;
        }
        if (uuidValidate(query.orderId.toString())) {
            return query.orderId.toString();
        }
        const isOrderIdNumber = !isNaN(Number(query?.orderId));

        if (!isDraft && isOrderIdNumber) {
            return Number(query.orderId);
        }
    };

    return {
        activeOrderType,
        isDraft,
        orderIdFromQuery: getOrderIdFromQuery(),
    };
};

export default useOrderType;
