import { useMemo, useState } from 'react';
import Button from '../Button/Button';
import SideModal from '../Modal/SideModal';
import OrderSummaryModalContent from './OrderSummaryModalContent';
import { useRouter } from 'next/router';
import { OrderToDisplay } from '../../types/order.types';
import { Portal } from '@radix-ui/react-portal';
import { PopupsHandlers } from '../../hooks/usePopupsInSummaryPage';
import { OrderStatus } from '../../apiClient/generated';
import { OrderTypes } from '../../context/OrderTypes';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import SummaryIcon from '../../public/gfx/buttonIcons/lifebuoy.svg';
import DuplicateIcon from '../../public/gfx/buttonIcons/duplicateIcon.svg';
import SubmitIcon from '../../public/gfx/buttonIcons/submitIcon.svg';
import { useFeatureFlags } from '../../context/useFeatureFlags';

interface CheckoutOrderProps {
    orderToDisplay: OrderToDisplay;
    page: 'summary' | 'preparation';
    popupsHandlers: PopupsHandlers | undefined;
    onSubmitButtonClick?: () => Promise<void>;
}

enum ctaBtnLabel {
    summary = 'Summary',
    submit = 'Submit',
    continue = 'Continue',
    loading = 'Loading...',
    duplicate = 'Duplicate',
    stopSending = 'Stop sending',
    default = '',
}

interface CtaBtnDynamicProps {
    label: ctaBtnLabel | null;
    action?: () => void;
    icon?: React.ReactElement;
}

const orderTypeToSummaryAvailableMap: Record<OrderTypes, boolean> = {
    [OrderTypes.provision]: true,
    [OrderTypes.bonded]: false,
    [OrderTypes.nonFood]: false,
    [OrderTypes.cash]: false,
};

const CheckoutOrder: React.FC<CheckoutOrderProps> = ({
    orderToDisplay,
    page,
    popupsHandlers,
    onSubmitButtonClick,
}) => {
    const router = useRouter();
    const { featureFlags } = useFeatureFlags();

    const isSummaryAvailable =
        orderTypeToSummaryAvailableMap[orderToDisplay.type];
    const isAwaitingForGarrets = orderToDisplay.status === OrderStatus.Created;
    const isDraft = !orderToDisplay.orderId;

    const isOrderCompleted =
        parseOrderStatus(orderToDisplay).isRfqFlowCompleted;

    const isSentToGarrets =
        orderToDisplay?.status === OrderStatus.FinalOrder ||
        orderToDisplay.status === OrderStatus.RfqSent;

    const [isCheckoutOpen, setCheckoutOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const orderAmountSum = useMemo(() => {
        return orderToDisplay.rfq.lineItems.reduce(
            (sum, item) => sum + item.quantity,
            0,
        );
    }, [orderToDisplay.rfq.lineItems]);

    const summaryButtonDisabled = isProcessing || orderAmountSum === 0;

    if (isSentToGarrets) return null;

    const { openConfirmDuplicateModal, openConfirmStopSendingModal } =
        popupsHandlers || {};

    const onContinueSummarySideModal = async () => {
        setIsProcessing(true);

        if (page === 'preparation') {
            router.push(
                `/order/${orderToDisplay.type}/${
                    orderToDisplay.localOrderId ?? orderToDisplay.orderId
                }/summary/details`,
            );
        }
        if (page === 'summary') {
            onSubmitButtonClick?.();
            setCheckoutOpen(false);
            setIsProcessing(false);
        }
    };

    const onSubmitBtn = () => {
        if (isDraft || !isSummaryAvailable) {
            onSubmitButtonClick?.();
        } else {
            setCheckoutOpen(true);
        }
    };

    const openSummarySideModal = () => {
        if (!isSummaryAvailable) {
            onContinueSummarySideModal();
        } else {
            setCheckoutOpen(true);
        }
    };

    const ctaBtnDynamicProps: () => CtaBtnDynamicProps = () => {
        if (isAwaitingForGarrets) {
            return { label: null, action: undefined };
        }

        if (isProcessing) {
            return { label: ctaBtnLabel.loading, action: undefined };
        }

        if (page === 'preparation') {
            return {
                label: ctaBtnLabel.summary,
                action: openSummarySideModal,
                icon: (
                    <SummaryIcon
                        width="16"
                        height="16"
                        stroke={summaryButtonDisabled ? '#ACB4BB' : '#fff'}
                    />
                ),
            };
        }

        if (page === 'summary') {
            if (isOrderCompleted) {
                if (!orderToDisplay.isSending) {
                    return {
                        label: ctaBtnLabel.duplicate,
                        action: openConfirmDuplicateModal,
                        icon: <DuplicateIcon width="12" height="12" />,
                    };
                } else {
                    return { label: null, action: undefined };
                }
            }

            if (orderToDisplay.isSending) {
                if (!featureFlags?.offlineExperienceV2) {
                    return { label: null, action: undefined };
                }

                return {
                    label: ctaBtnLabel.stopSending,
                    action: openConfirmStopSendingModal,
                };
            }

            return {
                label: ctaBtnLabel.submit,
                action: onSubmitBtn,
                icon: <SubmitIcon width="14" height="14" />,
            };
        }

        return { label: null, action: undefined };
    };

    const { label, action, icon } = ctaBtnDynamicProps();

    return (
        <>
            <Portal>
                <SideModal
                    isOpen={isCheckoutOpen}
                    onOutsideOfModalClick={() => setCheckoutOpen(false)}
                    confirmButtonText={
                        isProcessing
                            ? ctaBtnLabel.loading
                            : ctaBtnLabel.continue
                    }
                    onConfirm={onContinueSummarySideModal}
                >
                    {isCheckoutOpen && (
                        <OrderSummaryModalContent
                            orderToDisplay={orderToDisplay}
                        />
                    )}
                </SideModal>
            </Portal>

            {label && (
                <Button
                    smallRounded
                    leftIcon={icon}
                    greyCta={isOrderCompleted}
                    blueCta={!isOrderCompleted}
                    redCta={orderToDisplay.isSending}
                    text={label}
                    disabled={summaryButtonDisabled}
                    onClick={action}
                />
            )}
        </>
    );
};
export default CheckoutOrder;
